<template>
  <div class="card col-12 bestaetigung"
       :class="{'vgh-blau-bg': mandant === 1,
       'oesa-gruen-bg': mandant === 3,
       'oevo-blau-bg': mandant === 5}">
    <div v-if="!noCheck" class="card-body">
      <em class="fa-regular fa-circle-check"></em>
      <slot></slot>
    </div>
    <div v-else class="card-body-zentriert">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import mandant from '@/mandant';

export default {
  name: 'Bestaetigung',
  mixins: [mandant],
  props: ['noCheck'],
};
</script>

<style scoped>
.bestaetigung {
  border-radius: 2px;
  color: #FFFFFF;
  font-family: Sparkasse_web_Lt, sans-serif;
  font-size: 18px;
  min-height: 122px;
  margin-bottom: 60px;
  text-align: center;
  width: 100%;
}

.fa-circle-check {
  width: 50px;
  height: 50px;
}

.card-body-zentriert {
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 30px;
  padding-bottom: 14px;
  margin: auto;
}

@media (max-width: 576px) {
  .bestaetigung {
    margin-bottom: 30px;
    min-height: 146px;
  }
}
</style>
