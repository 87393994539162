<template>
  <!-- eslint-disable max-len -->
  <div v-if="false">
    <p
      id="btn" v-b-toggle.einstellungen>
      Einstellungen
      <em class="fa-solid fa-chevron-right side-icon"
         :class="{'vgh-blau-color': mandant === 1,
          'oesa-gruen-color': mandant === 3,
          'oevo-blau-color': mandant === 5}"></em>
    </p>
    <b-sidebar
      id="einstellungen"
      ref="einstellungen"
      aria-labelledby="sidebar-no-header-title"
      no-header
      width="515px"
      right
      lazy>
      <div class="side-content">
        <h3 class="side-ueberschrift">
          <button class="zurueck-btn" @click="zurueckNavigieren">
            <em class="fa-solid fa-chevron-left zurueck-icon"></em>
          </button>
          Einstellungen
          <button class="side-x" v-b-toggle.mehr>
            <em class="fa-solid fa-xmark"></em>
          </button>
        </h3>
        <h4 class="copy_16 side-card">Benachrichtigungen</h4>
        <h4 class="copy_16 side-card">Sicherheit</h4>
      </div>
    </b-sidebar>
  </div>
</template>

<script>
import mandant from '@/mandant';

export default {
  name: 'Einstellung',
  mixins: [mandant],
  methods: {
    async logout() {
      this.$store.commit('resetState');
      const logoutOptions = { redirectUri: window.location.origin };
      await this.$keycloak.logout(logoutOptions);
    },
    zurueckNavigieren() {
      this.$refs.einstellungen.hide();
    },
  },
};
</script>

<!--Globales Styling unter Mehr.vue-->
<style>
#einstellungen {
  margin-top: 0;
}
</style>

<style scoped>
#btn {
  margin-bottom: 0;
}

.b-sidebar {
  margin-top: 0;
}
</style>
