<template>
  <div>
    <div v-if="(vertragAuswahlSeite || zusammenfassungSeite)" class="card" id="rechteck">
      <div class="container">
        <a class="copy_16 pfeil-btn text-link abstand-unten-0"
           :class="{'text-link-vgh': mandant === 1,
              'text-link-oesa': mandant === 3,
              'text-link-oevo': mandant === 5}"
           @click="zurueckBehandlung">
          <em class="fa-solid fa-chevron-left"></em>Zurück
        </a>
      </div>
    </div>
    <app-header
      v-if="vertragAuswahlSeite"
      :text-line1="headerText">
    </app-header>
    <div class="container main-content">
      <slot name="benachrichtigung"></slot>
      <sicherheitsabfrage
        v-show="showAbfrage"
        typ="verwerfen"
        :titel="titelAbfrage"
        :text="textAbfrage"
        @zustimmung="verwerfenZurueck"
        @abbruch="closeAbfrage"
        @ablehnung="closeAbfrage">
      </sicherheitsabfrage>
      <sicherheitsabfrage
        v-if="verlasseSeiteAbfrage"
        typ="verlassen"
        @zustimmung="behandleSeiteVerlassen"
        @ablehnung="schliesseAbfrage"
        @abbruch="schliesseAbfrage">
      </sicherheitsabfrage>
    <div v-if="(!vertragAuswahlSeite && !zusammenfassungSeite)" class="row g-3">
      <div class="col-xl-8 mb-5">
        <h1 class="abstand-unten-20">Änderung meiner Bankverbindung</h1>
        <p class="copy_18 abstand-unten-60">Ihre Änderung ist nur tagesaktuell möglich.
          <br>Die Verarbeitung erfolgt in den nächsten Tagen.</p>
        <div>
          <h3>Meine neue Bankverbindung</h3>
          <h5 class="abstand-unten-10 copy_16">Wer ist Kontoinhaber?</h5>
            <div class="row">
            <div class="col-12 abstand-unten-60">
              <input
                type="radio"
                class="auswahl-button"
                :class="{'input-vgh': mandant === 1,
                  'input-oesa': mandant === 3,
                  'input-oevo': mandant === 5}"
                id="ich"
                value="ich"
                checked
                v-model="radioAuswahl">
              <label id="ich-label" for="ich"></label>
              <span class="ich-text aufschrift copy_16">Ich</span>
              <input
                type="radio"
                class="auswahl-button"
                :class="{'input-vgh': mandant === 1,
                  'input-oesa': mandant === 3,
                  'input-oevo': mandant === 5}"
                id="anderer"
                value="anderer"
                v-model="radioAuswahl">
              <label for="anderer"></label>
              <span class="aufschrift copy_16">eine andere Person</span>
            </div>
          </div>
          <div v-if="inhaberIch">
            <div class="row">
              <div class="col-md-9 col-12" v-if="inhaberIch">
                <label class="aufschrift copy_bold_12" for="iban">IBAN*</label>
              </div>
            </div>
            <div>
              <input
                class="eingabe-fake copy_16"
                readonly
                value="DE">
              <input
                @keypress="nurZahlen"
                @paste="behandleEinfuegen"
                @blur="pruefeEingabe"
                @click="eingabeFehler = false"
                @input="checkInput"
                v-model="ibanInput"
                class="eingabe copy_16"
                :class="{'fehler': eingabeFehler && mandant === 1,
                        'fehler-oesa': eingabeFehler && mandant === 3,
                        'vgh-eingabe': mandant === 1,
                        'oesa-eingabe': mandant === 3,
                        'oevo-eingabe': mandant === 5,
                        'fehler-oevo': eingabeFehler && mandant === 5}"
                maxlength="25"
                id="iban">
              <div>
                <label
                  v-if="eingabeFehler"
                  id="fehler-label"
                  class="fehler-text copy_bold_12"
                  :class="{'fehler-text-oesa': mandant === 3,
                    'fehler-text-oevo': mandant === 5}">
                  {{ eingabeFehlerText }}
                </label>
              </div>
              <label id="pflicht-label" class="copy_12">Pflichtfeld*</label>
            </div>
          </div>
          <div v-if="inhaberAnderer">
            <bestaetigung :no-check="true">
              Die Änderung der Bankverbindung ist in diesem Fall
              leider nicht über das Portal möglich.
              <a target="_blank" class="hinweis-link abstand-oben-20" :href="mandantenLink">Hier</a>
              können Sie die Änderung beantragen.
            </bestaetigung>
          </div>
          <div v-if="inhaberIch">
            <button
              @click="zuDenVertraegen"
              id="weiter-btn"
              class="button-ivv white"
              :class="{'button-vgh': mandant === 1,
                     'button-oesa': mandant === 3,
                     'button-oevo': mandant === 5}">
              Weiter
            </button>
            <input type="button"
                   id="verwerfen-btn"
                   class="button-ivv"
                   value="Verwerfen"
                   @click="showAbfrage = true"
            >
          </div>
        </div>
      </div>
      <div class="col-xl-4">
        <vertreter-info
          v-if="(vertragAuswahlSeite && zusammenfassungSeite) === false"
          :ueberschrift="vertreterUeberschrift"
          :mail-adresse="true"
          :nachricht="true"
          :vertrLink="true"
          :termin="true"
          :social-media="true"
          :vertreter="vertreter">
        </vertreter-info>
      </div>
    </div>
  </div>
    <BankverbindungVertraege
      v-if="vertragAuswahlSeite"
      ref="vertraege"
      :auswahl="ausgewaehlteVertraege"
      @vertragAuswahl="setzeVertragAuswahl"
      @zurZusammenfassung="zurZusammenfassung">
    </BankverbindungVertraege>
    <BankverbindungZusammenfassung
      v-if="zusammenfassungSeite"
      :iban="neueIban"
      :bankverbindung-loeschen="bankverbindungLoeschen"
      :vertraege="ausgewaehlteVertraege"
      @iban-senden="sendeNeueIban">
    </BankverbindungZusammenfassung>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import AppHeader from '@/components/Header.vue';
import VertreterInfo from '@/components/VertreterInfo.vue';
import Bestaetigung from '@/components/Bestaetigung.vue';
import Sicherheitsabfrage from '@/components/Sicherheitsabfrage.vue';
import BankverbindungVertraege from '@/change-personal-data/BankverbindungVertraege.vue';
import BankverbindungZusammenfassung from '@/change-personal-data/BankverbindungZusammenfassung.vue';
import sicherheitsabfrage from '@/sicherheitsabfrage';
import bestaetigungUtil from '@/bestaetigungUtil';
import mandant from '@/mandant';

export default {
  mixins: [sicherheitsabfrage, bestaetigungUtil, mandant],
  name: 'BankverbindungAendern',
  components: {
    AppHeader,
    BankverbindungVertraege,
    BankverbindungZusammenfassung,
    Bestaetigung,
    VertreterInfo,
    Sicherheitsabfrage,
  },

  data() {
    return {
      headerText: 'Änderung meiner Bankverbindung',

      ibanInput: '',
      eingabeFehler: false,
      eingabeFehlerText: '',
      ibanLaenge20: false,
      neueIban: '',

      inhaberAnderer: false,
      inhaberIch: true,

      radioAuswahl: 'ich',
      vertreterUeberschrift: 'Ich helfe Ihnen gerne bei der Änderung Ihrer Daten weiter.',
      ausgewaehlteVertraege: [],
      bankverbindungLoeschen: false,

      vertragAuswahlSeite: false,
      zusammenfassungSeite: false,
      showAbfrage: false,
      titelAbfrage: 'Möchten Sie die Änderung Ihrer Bankverbindung wirklich verwerfen?',
      textAbfrage: 'Ihr bisheriger Entwurf wird gelöscht.',
    };
  },

  async beforeMount() {
    await this.ladeVertraege();
    this.setRadioAuswahl();
    this.setZurueckFeld();
  },

  mounted() {
    this.$emit('view-geladen');
  },

  computed: {
    ...mapState(['vertreter']),
    mandantenLink() {
      return this.$store.state.weiterleitungen.bankdatenAendern;
    },
  },
  methods: {
    checkInput() {
      this.eingabeFehler = false;
      if (!(this.ibanInput.length === 3 && this.ibanInput
        .charAt(this.ibanInput.length - 1) === ' ')
        && !(this.ibanInput.length === 8 && this.ibanInput
          .charAt(this.ibanInput.length - 1) === ' ')
        && !(this.ibanInput.length === 13 && this.ibanInput
          .charAt(this.ibanInput.length - 1) === ' ')
        && !(this.ibanInput.length === 18 && this.ibanInput
          .charAt(this.ibanInput.length - 1) === ' ')
        && !(this.ibanInput.length === 23 && this.ibanInput
          .charAt(this.ibanInput.length - 1) === ' ')
      ) {
        const regEx = new RegExp(/\d$/);
        if (!regEx.test(this.ibanInput
          .charAt(this.ibanInput.length - 1))) {
          this.ibanInput = this.ibanInput
            .replace(this.ibanInput.substring(this.ibanInput.length - 1), '');
        }
        this.insertTrennzeichen();
      }
    },
    insertTrennzeichen() {
      this.ibanInput = this.ibanInput.replace(/\D/g, '');
      if (this.ibanInput.length > 2) {
        this.ibanInput = `${this.ibanInput.substring(0, 2)} ${this.ibanInput.substring(2)}`;
      }
      if (this.ibanInput.length > 7) {
        this.ibanInput = `${this.ibanInput.substring(0, 7)} ${this.ibanInput.substring(7)}`;
      }
      if (this.ibanInput.length > 12) {
        this.ibanInput = `${this.ibanInput.substring(0, 12)} ${this.ibanInput.substring(12)}`;
      }
      if (this.ibanInput.length > 17) {
        this.ibanInput = `${this.ibanInput.substring(0, 17)} ${this.ibanInput.substring(17)}`;
      }
      if (this.ibanInput.length > 22) {
        this.ibanInput = `${this.ibanInput.substring(0, 22)} ${this.ibanInput.substring(22)}`;
      }
    },
    ladeVertraege() {
      this.$store.dispatch('ladeVertraege');
    },
    setZurueckFeld() {
      this.$root.$refs.app.setZurueckFeld(true);
    },
    setRadioAuswahl() {
      this.radioAuswahl = this.$store.state.kontoinhaberRadioButton;
    },
    behandleEinfuegen(value) {
      value.preventDefault();
      let eingefuegtes = value.clipboardData.getData('text/plain');
      eingefuegtes = eingefuegtes.replace(/\D/g, '');
      this.ibanInput = eingefuegtes;
      this.checkInput();
    },
    closeAbfrage() {
      this.showAbfrage = false;
    },
    eingegeben() {
      return !!this.ibanInput;
    },
    setzeVertragAuswahl({ eingecheckteVertraege, bankverbindungLoeschen }) {
      this.ausgewaehlteVertraege = eingecheckteVertraege;
      this.bankverbindungLoeschen = bankverbindungLoeschen;
    },
    zurZusammenfassung() {
      this.vertragAuswahlSeite = false;
      this.zusammenfassungSeite = true;
    },
    nurZahlen(eingabe) {
      const regEx = new RegExp(/^\d*$/);
      if (!regEx.test(eingabe.key)) {
        eingabe.preventDefault();
      }
    },
    pruefeEingabe() {
      if (this.ibanInput && !this.pruefeIban()) {
        this.eingabeFehler = true;
        this.eingabeFehlerText = 'Bitte geben Sie eine gültige IBAN ein';
      } else {
        this.eingabeFehler = false;
      }
    },
    removeSpaces(string) {
      return string.replaceAll(/\s/g, '');
    },

    /* global BigInt */
    pruefeIban() {
      const iban = this.removeSpaces(this.ibanInput);
      const pruefZiffer = iban.substring(0, 2);
      let pruefString = iban.substring(2);
      pruefString += '131400';
      let bban = BigInt(pruefString);
      bban %= BigInt(97);
      bban = BigInt(98) - bban;

      if (bban < BigInt(10)) {
        bban = `'0'${bban.toString()}`;
      } else {
        bban = bban.toString();
      }

      return bban === pruefZiffer;
    },
    sendeNeueIban() {
      this.verlasseSeite = true;
      this.$router.push({
        name: 'Services',
        params: {
          bestaetigung: 'iban',
        },
      });
    },
    verwerfenZurueck() {
      this.verlasseSeite = true;
      this.$router.back();
    },
    zuDenVertraegen() {
      this.neueIban = `DE${this.ibanInput}`;

      if (this.pruefeIban()) {
        this.vertragAuswahlSeite = true;
        this.eingabeFehler = false;
      } else {
        this.eingabeFehler = true;
        this.eingabeFehlerText = 'Bitte geben Sie eine gültige IBAN ein';
      }
      document.getElementById('app').scrollTo(0, 0);
    },
    zurueck() {
      this.$router.go(-1);
    },
    zurueckBehandlung() {
      if (this.vertragAuswahlSeite) {
        this.$refs.vertraege.emitAuswahl();
        this.vertragAuswahlSeite = false;
        this.$root.$refs.app.setZurueckFeld(true);
      } else if (this.zusammenfassungSeite) {
        this.zusammenfassungSeite = false;
        this.vertragAuswahlSeite = true;
      }
      document.getElementById('app').scrollTo(0, 0);
    },
  },

  watch: {
    ibanInput(value) {
      this.eingabeFehler = false;
      if (this.removeSpaces(value).length === 20) {
        this.ibanLaenge20 = true;
      } else {
        this.ibanLaenge20 = false;
      }
    },
    radioAuswahl(value) {
      this.$store.state.kontoinhaberRadioButton = value;
      if (value === 'ich') {
        this.inhaberIch = true;
        this.inhaberAnderer = false;
      } else if (value === 'anderer') {
        this.inhaberIch = false;
        this.inhaberAnderer = true;
      } else {
        this.inhaberIch = false;
        this.inhaberAnderer = false;
      }
    },
  },
};
</script>

<style scoped>
#fehler-label {
  margin: 6px 0;
}

#ich-label {
  margin-left: -59px;
}

#pflicht-label{
  margin: 30px 0;
}

#rechteck {
  background-color: #EDF0F3;
  width: 100%;
  height: 50px;
  border-radius: 0;
  border-color: #EDF0F3;
  border-top: 1px solid #CBCFD3;
  padding-top: 12px;
}

#weiter-btn {
  margin-right: 15px;
}

.aufschrift {
  color: #6B7171;
  margin-bottom: 6px;
  white-space: nowrap;
}

.auswahl-button {
  margin: 3px 10px 0 7px;
  -webkit-transform: scale(1.7);
}

.auswahl-button:checked {
  background-color: white;
}

.auswahl-button:focus-visible {
  outline: none;
}

.button-ivv:disabled:hover {
  opacity: 0.5;
}

.eingabe {
  background: transparent;
  display: inline-block;
  width: 300px;
  padding-left: 30px;
}

.eingabe-fake {
  background: transparent;
  border: none;
  box-sizing: border-box;
  display: inline-block;
  height: 50px;
  padding-left: 10px;
  position: absolute;
  left: 15px;
  font-size: 16px;
  width: 31px;
}

.eingabe-fake:focus-visible {
  outline: none;
}

.ich-text{
  margin-left: 0px;
  margin-right: 20px;
}

/* Mobile View */
@supports (-webkit-touch-callout: none) {
  .eingabe {
    padding-left: 37px;
  }
  .eingabe-fake {
    width: 38px;
  }
}
</style>
