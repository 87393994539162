var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.verlasseSeiteAbfrage)?_c('sicherheitsabfrage',{attrs:{"typ":"verlassen"},on:{"zustimmung":_vm.behandleSeiteVerlassen,"ablehnung":_vm.schliesseAbfrage,"abbruch":_vm.schliesseAbfrage}}):_vm._e(),_c('div',{staticClass:"container mobile-container"},[_c('div',{staticClass:"row"},[_c('div',{attrs:{"id":"vertreterauswahl"}},[_vm._m(0),_c('div',{staticClass:"row"},_vm._l((_vm.vertreterliste),function(vertreter,index){return _c('div',{key:vertreter.vertreternummer,staticClass:"col",class:{ 'col-auto': (_vm.windowWidth > 769) }},[_c('div',[_c('label',{staticClass:"cursor-pointer kartenlabel"},[_c('span',{staticClass:"vertreter-karte row",class:{'ausgewaehlt-vgh': _vm.ausgewaehlterVertreter(vertreter) && _vm.mandant === 1,
                      'ausgewaehlt-oevo': _vm.ausgewaehlterVertreter(vertreter) && _vm.mandant === 5,
                      'ausgewaehlt-oesa': _vm.ausgewaehlterVertreter(vertreter) && _vm.mandant === 3}},[(_vm.ausgewaehlterVertreter(vertreter))?_c('span',{staticClass:"copy_16 ausgewaehlt-text"},[(_vm.windowWidth > 576)?_c('span',[_vm._v("Ihre aktuelle Portalvertretung")]):_c('span',[_vm._v("Ihre aktuelle Vertretung")])]):_c('br'),_c('span',{staticClass:"row radio-button-zeile",class:{'radio-button-zeile-vgh': _vm.mandant === 1}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.selectedVertreter),expression:"selectedVertreter"}],class:{'aktueller-radio': _vm.ausgewaehlterVertreter(vertreter),
                         'input-vgh': _vm.mandant === 1 && !_vm.ausgewaehlterVertreter(vertreter),
                         'aktueller-radio-vgh': _vm.mandant === 1
                            && _vm.ausgewaehlterVertreter(vertreter),
                          'input-oesa': _vm.mandant === 3 && !_vm.ausgewaehlterVertreter(vertreter),
                          'aktueller-radio-oesa': _vm.mandant === 3
                            && _vm.ausgewaehlterVertreter(vertreter),
                          'input-oevo': _vm.mandant === 5 && !_vm.ausgewaehlterVertreter(vertreter),
                          'aktueller-radio-oevo': _vm.mandant === 5
                            && _vm.ausgewaehlterVertreter(vertreter)},attrs:{"id":index,"type":"radio","name":vertreter.name},domProps:{"value":vertreter,"checked":_vm._q(_vm.selectedVertreter,vertreter)},on:{"change":function($event){_vm.selectedVertreter=vertreter}}}),_c('label',{staticClass:"copy_18 row",class:{ 'weiss': _vm.ausgewaehlterVertreter(vertreter) },attrs:{"for":index}},[_c('p',{staticClass:"vertreter-name"},[_vm._v(" "+_vm._s(vertreter.name)+" ")])])]),_c('span',{staticClass:"vertreter-bild-container"},[(_vm.vertreterbild(vertreter.vertreterBildUrl))?_c('img',{staticClass:"vertreter-bild",class:{'vertreter-bild-vgh': _vm.mandant === 1,
                          'vertreter-bild-oevo': _vm.mandant === 5},attrs:{"src":_vm.vertreterbild(vertreter.vertreterBildUrl),"alt":""},on:{"error":function($event){vertreter.vertreterBildUrl = ''}}}):_c('img',{staticClass:"vertreter-bild",class:{'mandanten-bild': _vm.mandant === 1},attrs:{"src":_vm.mandantenBild(),"alt":""}})])])])])])}),0),(_vm.fehler)?_c('label',{staticClass:"copy_16 fehler-text row",class:{'fehler-text-oesa': _vm.mandant === 3,
          'fehler-text-oevo': _vm.mandant === 5}},[_vm._v(" "+_vm._s(_vm.fehlerText)+" ")]):_vm._e(),_c('div',{staticClass:"row"},[_c('input',{staticClass:"button-ivv white",class:{'button-vgh': _vm.mandant === 1,
              'button-oesa': _vm.mandant === 3,
              'button-oevo': _vm.mandant === 5},attrs:{"type":"button","value":"Weiter","disabled":_vm.weiterButtonDisabled},on:{"click":_vm.setVertreter}})])])])])],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row"},[_c('span',{staticClass:"copy_16",attrs:{"id":"titel"}},[_vm._v(" Bitte suchen Sie sich nun eine Vertretung aus, welche Sie im Kundenportal begleitet. ")])])}]

export { render, staticRenderFns }