<template>
  <div id="nachrichtenbalke">
    <div class="nachricht-card"
         :class="{ungelesen: neuigkeit.status === 'ungelesen'}"
         @click="zuNachrichtDetails(neuigkeit)">
      <div class="nachricht-card-body">
          <i class="fa-solid fa-circle nachricht-status"
             :class="{'nachricht-status-ungelesen':
           (neuigkeit.status === 'ungelesen') && mandant === 1,
                   'nachricht-status-ungelesen-oesa':
                   (neuigkeit.status === 'ungelesen')
                   && mandant === 3,
                   'nachricht-status-ungelesen-oevo':
                   (neuigkeit.status === 'ungelesen')
                   && mandant === 5,
                    'nachricht-status-gelesen':
                    neuigkeit.status === 'gelesen'}"/>
        <div v-if="windowWidth > 576" class="nachricht-vorschau">
          <div class="copy_12 nachricht-betreff"
               :class="{'vgh-grau-color': mandant === 1,
               'oesa-gruen-sekundaer-color': mandant === 3,
               'oevo-grau-color': mandant === 5}">
            <span class="betreff-desktop"
                  :class="{'ungelesen-text':(neuigkeit.status === 'ungelesen')}"
                    v-html=neuigkeit.betreff></span>
            <span class="datum-desktop"
                  :class="{'ungelesen-text':(neuigkeit.status === 'ungelesen')}">
              {{ erzeugeEmpfaenger(neuigkeit) }} |
              {{ trimDatum(neuigkeit.erstellungsDatum) }}
            </span>
          </div>
          <span :class="{'fa-paperclip-gelesen' : neuigkeit.status !== 'ungelesen'}">
            <i v-if="neuigkeit.uploadList.length" class="fa-solid fa-paperclip"/>
          </span>
        </div>
        <!--Mobile von-->
        <div v-else class="nachricht-vorschau">
          <div class="copy_12 nachricht-senderinfo-mobile"
               :class="{'vgh-grau-color': mandant === 1,
               'oesa-gruen-sekundaer-color': mandant === 3,
               'oevo-grau-color': mandant === 5}">
            <span class="empfaenger-mobil"
                  :class="{'ungelesen-text':(neuigkeit.status === 'ungelesen')}">
              {{ erzeugeEmpfaenger(neuigkeit) }}
            </span>
            <span class="datum-mobil"
                  :class="{'ungelesen-text':(neuigkeit.status === 'ungelesen')}">
              {{ trimDatum(neuigkeit.erstellungsDatum) }}
            </span>
          </div>
          <div class="copy_12 nachricht-betreff"
               :class="{'vgh-grau-color': mandant === 1,
                 'oesa-gruen-sekundaer-color': mandant === 3,
                 'oevo-grau-color': mandant === 5}">
            <span class="betreff-mobil"
                  :class="{'ungelesen-text':(neuigkeit.status === 'ungelesen')}"
                  v-html=neuigkeit.betreff></span>
            <span class="datum-mobil"
                  :class="{'fa-paperclip-gelesen' :(neuigkeit.status !== 'ungelesen')}">
              <i v-if="neuigkeit.uploadList.length" class="fa-solid fa-paperclip"/>
            </span>
          </div>
        </div>
        <!--Mobile bis-->
      </div>
    </div>
  </div>
</template>

<script>
import mandant from '@/mandant';

export default {
  name: 'Nachrichtenbalke',
  mixins: [mandant],
  props: {
    neuigkeit: Object,
  },
  methods: {
    // wird später benötigt, wenn die Loeschfunktion fuer Desktop eingebaut wird
    async konversationAusloeschenBestaetigung(Konversationid) {
      await this.$emit('konversationAusloeschenBestaetigung', Konversationid);
    },
    async zuNachrichtDetails(nachricht) {
      this.$router.push({ path: `/konversation/${nachricht.konversationsId}` });
    },
    trimDatum(datum) {
      return datum.substring(0, 10);
    },
    erzeugeEmpfaenger(neuigkeit) {
      if (neuigkeit.typ === 'Dokumenteinreichung') {
        switch (this.mandant) {
          case 1:
            return 'VGH';
          case 3:
            return 'ÖSA';
          case 5:
            return 'Öffentliche Oldenburg';
          default:
            return '';
        }
      }
      return neuigkeit.gespraechsPartnerName;
    },
  },
};
</script>

<style scoped>
#nachrichtenbalke {
  cursor: pointer;
}

.nachricht-betreff, .nachricht-senderinfo-mobile {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 2px;
}

.nachricht-card {
  width: auto;
  height: 60px;
  margin-bottom: 2px;
  background-color: #EDF0F3;
  text-align: left;
  border-radius: 0;
  padding: 0;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-clip: border-box;
  border: 1px solid #EDF0F3;
}

.nachricht-card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding-left: 5px;
}

.fa-paperclip {
  float: right;
  padding-top: 4px;
}

.fa-paperclip-gelesen {
  color: gray;
}

.nachricht-vorschau {
  padding: 8px;
  padding-left: 15px;
}

.fa-circle, .nachricht-status-gelesen {
  color: #EDF0F3;
}

.nachricht-status {
  padding-top: 10px;
  float: left;
  height: 10px;
}

.nachricht-status-ungelesen {
  color: #0446BE;
}

.nachricht-status-ungelesen-oevo {
  color: #004494 !important;
}

.nachricht-status-ungelesen-oesa {
  color: #00935d !important;
}

.ungelesen {
  font-family: "Sparkasse_web_Rg", sans-serif;
  background-color: #cbcfd3;
}

.ungelesen-text {
  font-weight: bold;
}

.datum-desktop{
  display: inline-block;
  float: right;
  width: auto;
}

.betreff-desktop{
  display: inline-block;
  float: left;
  width: 50%;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 1.1em;
}

/* Phone View */
@media (max-width: 576px) {
  .nachricht-card{
    height: 75px;
  }
  .empfaenger-mobil{
    float: left;
    width: 70%;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .datum-mobil{
    float: right;
  }
  .betreff-mobil{
    float: left;
    width: 90%;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 1.1em;
  }
}
/* Tablet View */
@media (min-width: 576px) and (max-width: 768px) {
  .datum-desktop{
    display: inline-block;
    float: right;
    max-width: 45%;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .betreff-desktop{
    display: inline-block;
    float: left;
    width: 45%;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 1.1em;
  }
}
</style>
