import '@babel/polyfill';
import 'mutationobserver-shim';
import Vue from 'vue';
import './plugins/bootstrap-vue';
import VueWindowSize from 'vue-window-size';
import VueLogger from 'vuejs-logger';
import { BootstrapVue } from 'bootstrap-vue';
import fontawesome from '@fortawesome/fontawesome-free/js/all';
import keycloakPl, { keycloakPromise } from '@/plugins/keycloak-plugin';
import backendApi from '@/backend-api';
import App from './App.vue';
import router from './router';
import store from './store';

async function logIn() {
  let payload = {
    idToken: Vue.$keycloak.idToken,
    accessToken: Vue.$keycloak.token,
    tokenParsed: Vue.$keycloak.tokenParsed,
  };
  const keyclaokMandantengruppenId = parseInt(Vue.$keycloak.tokenParsed.ivv_mandant_id, 10);
  store.commit('login', payload);
  console.log('User has logged in: ');
  payload = {
    name: Vue.$keycloak.tokenParsed.preferred_username,
  };
  store.commit('SET_NAME', payload);
  payload = {
    mandantengruppenId: keyclaokMandantengruppenId,
  };
  store.commit('SET_MANDANT', payload);
}

async function logOut() {
  this.$store.commit('resetState');
  const logoutOptions = { redirectUri: window.VUE_APP_KEYCLOAK_URL };
  await this.$keycloak.logout(logoutOptions);
}

async function mountApp() {
  Vue.$log.info('Authenticated');

  if (Vue.$keycloak.tokenParsed.ivv_benutzerstatus === 'identifiziert'
      && !window.location.hash.toString().includes('nect-validierung-mobile')) {
    await backendApi.aktiviereBenutzerIvas(Vue.$keycloak.token).then(() => {
      Vue.$keycloak.tokenParsed.ivv_benutzerstatus = 'aktiv';
    }).catch(() => {
      console.log('Benutzerstatus nicht erfolgreich aktiviert!');
    });
  }

  if (Vue.$keycloak.token && Vue.$keycloak.idToken && Vue.$keycloak.token !== '' && Vue.$keycloak.idToken !== '') {
    await logIn();
  } else {
    await logOut();
  }

  console.log('mount vue app');
  /* eslint-disable no-new */
  new Vue({
    router,
    store,
    fontawesome,
    render: (h) => h(App),
  }).$mount('#app');
}

async function main() {
  // MVGH-1539: hack um die falsche weiterleitung von keycloak auf "#/state" zu umgehen
  if (window.location.href.includes('#/state=')) {
    let currentLocation = window.location.href;
    currentLocation = currentLocation.replace('#/', '&');
    // Simulate the redirect
    window.location.href = currentLocation;
  }

  Vue.use(BootstrapVue);
  Vue.use(VueWindowSize);
  Vue.use(VueLogger);
  Vue.config.productionTip = false;

  keycloakPromise.then((keycloak) => {
    Vue.use(keycloakPl);
    keycloak.init({ onLoad: 'login-required', pkceMethod: 'S256' }).then(async (auth) => {
      if (!auth) {
        window.location.reload();
      } else {
        await mountApp();
      }
      // Token Refresh
      setInterval(() => {
        Vue.$keycloak.updateToken(70).then((refreshed) => {
          if (refreshed) {
            Vue.$log.info(`Token refreshed${refreshed}`);
            store.state.user.accessToken = Vue.$keycloak.token;
          } else {
            Vue.$log.warn(`Token not refreshed, valid for ${
              Math.round(Vue.$keycloak.tokenParsed.exp + Vue.$keycloak.timeSkew - new Date().getTime() / 1000)} seconds`);
          }
        }).catch(() => {
          Vue.$log.error('Failed to refresh token');
        });
      }, 6000);
    }).catch(() => {
      Vue.$log.error('Authenticated Failed');
    });
  });
}

main();
