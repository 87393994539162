<template>
   <!-- eslint-disable max-len -->
  <div>
    <div v-if="loading && (keycloak.benutzerstatus !== 'angelegt')">
      <div class="spinner-border"
           :class="{'vgh-blau-color': mandant === 1,
              'oesa-gruen-color': mandant === 3,
              'oevo-blau-color': mandant === 5}" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
    <div v-else id="home">
      <div>
        <h3 v-if="fehler">Aufgrund einer technischen
          Störung kann Ihre Portalseite nicht angezeigt werden.
          Bitte versuchen Sie es später noch einmal</h3>
        <div>
          <app-header :textLine1="begruessung" :textLine2="erzeugePartnerName"></app-header>
          <div class="container main-content">
            <slot name="benachrichtigung"></slot>
            <div class="row g-3">
              <div class="col-xl-8">
                <div v-if="windowWidth>700" id="schnelleinstieg">
                  <h2 class="abstand-unten-30">Wie können wir Ihnen weiterhelfen?</h2>
                  <div class="row">
                    <div class="col-6" v-if="!(zugriffsStatus === 'GELOESCHT' || zugriffsStatus === 'KEIN_VERTRAG')">
                      <div class="custom-card">
                        <h3>Meine Verträge</h3>
                        <h6 class="copy_16 custom-card-text">Sie möchten einen Überblick über Ihre Verträge?</h6>
                        <pfeil-button class="stretched-link"
                          :path="'/vertrag'"
                          :button-text="'Zu meinen Verträgen'"
                          :disabled="zugriffsStatus === 'KEIN_VERTRAG' || zugriffsStatus === 'GELOESCHT'"
                          :class="{blau: true}">
                        </pfeil-button>
                      </div>
                    </div>
                    <div class="col-6" v-if="zugriffsStatus === 'eigeneOrga'">
                      <div class="custom-card">
                        <h3>Schaden melden</h3>
                        <h6 class="copy_16 custom-card-text">Sie möchten uns einen Schaden melden?</h6>
                        <pfeil-button class="stretched-link"
                          :path="'/schaden'"
                          :button-text="'Zum Schaden melden'"
                          :disabled="zugriffsStatus !== 'eigeneOrga'"
                          :class="{blau: true}">
                        </pfeil-button>
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="custom-card">
                        <h3>Mein Postfach</h3>
                        <h6 class="copy_16 custom-card-text">
                          {{zugriffsStatus === 'GELOESCHT' || zugriffsStatus === 'KEIN_VERTRAG' ? '' : 'Sie möchten Ihre Dokumente einsehen oder uns eine Nachricht schreiben?'}}
                        </h6>
                        <pfeil-button class="stretched-link"
                          :path="'/postfach'"
                          :button-text="'Zu meinem Postfach'"
                          :class="{blau: true}">
                        </pfeil-button>
                      </div>
                    </div>
                    <div class="col-6" v-if="!istServiceDisabled()">
                      <div class="custom-card">
                        <h3>Meine Services</h3>
                        <h6 class="copy_16 custom-card-text">Sie möchten einen unserer Services nutzen?</h6>
                        <pfeil-button class="stretched-link"
                          :path="'/services'"
                          :button-text="'Zu meinen Services'"
                          :disabled="zugriffsStatus !== 'eigeneOrga'"
                          :class="{blau: true}">
                        </pfeil-button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-xl-4" v-if="vertreter.length !== 0">
                <vertreter-info
                  :ueberschrift="vertreterUeberschrift"
                  :mail-adresse="true"
                  :nachricht="true"
                  :vertrLink="false"
                  :termin="true"
                  :social-media="true"
                  :vertreter="vertreter"
                >
                </vertreter-info>
                <oeffnungszeiten v-if="vertreter.oeffnungszeiten && vertreter.oeffnungszeiten.length"></oeffnungszeiten>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import AppHeader from '@/components/Header.vue';
import VertreterInfo from '@/components/VertreterInfo.vue';
import PfeilButton from '@/components/PfeilButton.vue';
import { mapGetters, mapState } from 'vuex';
import bestaetigungUtil from '@/bestaetigungUtil';

import mandant from '@/mandant';
import Vue from 'vue';
import Oeffnungszeiten from '@/components/Oeffnungszeiten.vue';

export default {
  name: 'Home',
  mixins: [bestaetigungUtil, mandant],
  data() {
    return {
      keycloak: Vue.$keycloak,
      headerText: 'This is the Homepage Header',
      begruessung: '',
      vertreterUeberschrift: 'Ihre Vertretung vor Ort',
      loading: true,
      globalesZurueck: false,
    };
  },
  props: ['bestaetigung', 'reaktivieren'],
  components: {
    Oeffnungszeiten,
    AppHeader,
    VertreterInfo,
    PfeilButton,
  },
  computed: {
    ...mapState(['partner', 'fehler', 'vertreter', 'zugriffsStatus', 'postfachStatus']),
    ...mapGetters(['erzeugePartnerName']),
  },
  mounted() {
    this.loading = false;
    this.$emit('view-geladen');
  },
  async beforeMount() {
    await this.$store.dispatch('ladePartner');
    this.erzeugeBegruessung();
    this.$root.$refs.app.setZurueckFeld(this.globalesZurueck);

    if (this.reaktivieren && this.$store.state.zugriffsStatus === 'GELOESCHT') {
      this.$root.$refs.app.benutzerReaktivieren();
    }
  },
  methods: {
    erzeugeBegruessung() {
      const aktuellerMoment = new Date();
      const stunde = aktuellerMoment.getHours();
      if (stunde < 11 && stunde > 3) {
        this.begruessung = 'Guten Morgen';
      } else if (stunde > 10 && stunde < 18) {
        this.begruessung = 'Guten Tag';
      } else {
        this.begruessung = 'Guten Abend';
      }
    },
    istServiceDisabled() {
      return this.$root.$refs.app.istServiceDisabled();
    },
  },
};
</script>

<style scoped>

.custom-card{
  color: #6B7171;
  background-color: #EDF0F3;
  text-align: center;
  height: 218px;
  padding: 30px 15px;
  border-radius: 2px;
  margin-bottom: 30px;
  position: relative;
}

.custom-card-text {
  min-height: 85px;
}

</style>
