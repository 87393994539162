var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[((_vm.vertragAuswahlSeite || _vm.zusammenfassungSeite))?_c('div',{staticClass:"card",attrs:{"id":"rechteck"}},[_c('div',{staticClass:"container"},[_c('a',{staticClass:"copy_16 pfeil-btn text-link abstand-unten-0",class:{'text-link-vgh': _vm.mandant === 1,
            'text-link-oesa': _vm.mandant === 3,
            'text-link-oevo': _vm.mandant === 5},on:{"click":_vm.zurueckBehandlung}},[_c('em',{staticClass:"fa-solid fa-chevron-left"}),_vm._v("Zurück ")])])]):_vm._e(),(_vm.vertragAuswahlSeite)?_c('app-header',{attrs:{"text-line1":_vm.headerText}}):_vm._e(),_c('div',{staticClass:"container main-content"},[_vm._t("benachrichtigung"),_c('sicherheitsabfrage',{directives:[{name:"show",rawName:"v-show",value:(_vm.showAbfrage),expression:"showAbfrage"}],attrs:{"typ":"verwerfen","titel":_vm.titelAbfrage,"text":_vm.textAbfrage},on:{"zustimmung":_vm.verwerfenZurueck,"abbruch":_vm.closeAbfrage,"ablehnung":_vm.closeAbfrage}}),(_vm.verlasseSeiteAbfrage)?_c('sicherheitsabfrage',{attrs:{"typ":"verlassen"},on:{"zustimmung":_vm.behandleSeiteVerlassen,"ablehnung":_vm.schliesseAbfrage,"abbruch":_vm.schliesseAbfrage}}):_vm._e(),((!_vm.vertragAuswahlSeite && !_vm.zusammenfassungSeite))?_c('div',{staticClass:"row g-3"},[_c('div',{staticClass:"col-xl-8 mb-5"},[_c('h1',{staticClass:"abstand-unten-20"},[_vm._v("Änderung meiner Bankverbindung")]),_vm._m(0),_c('div',[_c('h3',[_vm._v("Meine neue Bankverbindung")]),_c('h5',{staticClass:"abstand-unten-10 copy_16"},[_vm._v("Wer ist Kontoinhaber?")]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 abstand-unten-60"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.radioAuswahl),expression:"radioAuswahl"}],staticClass:"auswahl-button",class:{'input-vgh': _vm.mandant === 1,
                'input-oesa': _vm.mandant === 3,
                'input-oevo': _vm.mandant === 5},attrs:{"type":"radio","id":"ich","value":"ich","checked":""},domProps:{"checked":_vm._q(_vm.radioAuswahl,"ich")},on:{"change":function($event){_vm.radioAuswahl="ich"}}}),_c('label',{attrs:{"id":"ich-label","for":"ich"}}),_c('span',{staticClass:"ich-text aufschrift copy_16"},[_vm._v("Ich")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.radioAuswahl),expression:"radioAuswahl"}],staticClass:"auswahl-button",class:{'input-vgh': _vm.mandant === 1,
                'input-oesa': _vm.mandant === 3,
                'input-oevo': _vm.mandant === 5},attrs:{"type":"radio","id":"anderer","value":"anderer"},domProps:{"checked":_vm._q(_vm.radioAuswahl,"anderer")},on:{"change":function($event){_vm.radioAuswahl="anderer"}}}),_c('label',{attrs:{"for":"anderer"}}),_c('span',{staticClass:"aufschrift copy_16"},[_vm._v("eine andere Person")])])]),(_vm.inhaberIch)?_c('div',[_c('div',{staticClass:"row"},[(_vm.inhaberIch)?_c('div',{staticClass:"col-md-9 col-12"},[_c('label',{staticClass:"aufschrift copy_bold_12",attrs:{"for":"iban"}},[_vm._v("IBAN*")])]):_vm._e()]),_c('div',[_c('input',{staticClass:"eingabe-fake copy_16",attrs:{"readonly":"","value":"DE"}}),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.ibanInput),expression:"ibanInput"}],staticClass:"eingabe copy_16",class:{'fehler': _vm.eingabeFehler && _vm.mandant === 1,
                      'fehler-oesa': _vm.eingabeFehler && _vm.mandant === 3,
                      'vgh-eingabe': _vm.mandant === 1,
                      'oesa-eingabe': _vm.mandant === 3,
                      'oevo-eingabe': _vm.mandant === 5,
                      'fehler-oevo': _vm.eingabeFehler && _vm.mandant === 5},attrs:{"maxlength":"25","id":"iban"},domProps:{"value":(_vm.ibanInput)},on:{"keypress":_vm.nurZahlen,"paste":_vm.behandleEinfuegen,"blur":_vm.pruefeEingabe,"click":function($event){_vm.eingabeFehler = false},"input":[function($event){if($event.target.composing){ return; }_vm.ibanInput=$event.target.value},_vm.checkInput]}}),_c('div',[(_vm.eingabeFehler)?_c('label',{staticClass:"fehler-text copy_bold_12",class:{'fehler-text-oesa': _vm.mandant === 3,
                  'fehler-text-oevo': _vm.mandant === 5},attrs:{"id":"fehler-label"}},[_vm._v(" "+_vm._s(_vm.eingabeFehlerText)+" ")]):_vm._e()]),_c('label',{staticClass:"copy_12",attrs:{"id":"pflicht-label"}},[_vm._v("Pflichtfeld*")])])]):_vm._e(),(_vm.inhaberAnderer)?_c('div',[_c('bestaetigung',{attrs:{"no-check":true}},[_vm._v(" Die Änderung der Bankverbindung ist in diesem Fall leider nicht über das Portal möglich. "),_c('a',{staticClass:"hinweis-link abstand-oben-20",attrs:{"target":"_blank","href":_vm.mandantenLink}},[_vm._v("Hier")]),_vm._v(" können Sie die Änderung beantragen. ")])],1):_vm._e(),(_vm.inhaberIch)?_c('div',[_c('button',{staticClass:"button-ivv white",class:{'button-vgh': _vm.mandant === 1,
                   'button-oesa': _vm.mandant === 3,
                   'button-oevo': _vm.mandant === 5},attrs:{"id":"weiter-btn"},on:{"click":_vm.zuDenVertraegen}},[_vm._v(" Weiter ")]),_c('input',{staticClass:"button-ivv",attrs:{"type":"button","id":"verwerfen-btn","value":"Verwerfen"},on:{"click":function($event){_vm.showAbfrage = true}}})]):_vm._e()])]),_c('div',{staticClass:"col-xl-4"},[((_vm.vertragAuswahlSeite && _vm.zusammenfassungSeite) === false)?_c('vertreter-info',{attrs:{"ueberschrift":_vm.vertreterUeberschrift,"mail-adresse":true,"nachricht":true,"vertrLink":true,"termin":true,"social-media":true,"vertreter":_vm.vertreter}}):_vm._e()],1)]):_vm._e()],2),(_vm.vertragAuswahlSeite)?_c('BankverbindungVertraege',{ref:"vertraege",attrs:{"auswahl":_vm.ausgewaehlteVertraege},on:{"vertragAuswahl":_vm.setzeVertragAuswahl,"zurZusammenfassung":_vm.zurZusammenfassung}}):_vm._e(),(_vm.zusammenfassungSeite)?_c('BankverbindungZusammenfassung',{attrs:{"iban":_vm.neueIban,"bankverbindung-loeschen":_vm.bankverbindungLoeschen,"vertraege":_vm.ausgewaehlteVertraege},on:{"iban-senden":_vm.sendeNeueIban}}):_vm._e()],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',{staticClass:"copy_18 abstand-unten-60"},[_vm._v("Ihre Änderung ist nur tagesaktuell möglich. "),_c('br'),_vm._v("Die Verarbeitung erfolgt in den nächsten Tagen.")])}]

export { render, staticRenderFns }