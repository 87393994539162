<template>
  <div class="row g-3" id="backend-fehler">
    <label v-if="apiFehler"
      class="fehler-text copy_bold_12 col-12"
      :class="{'fehler-text-oesa': mandant === 3,
                'fehler-text-oevo': mandant === 5}">
      {{ apiFehlerText }}
    </label>
    <label v-if="backendFehler"
      class="fehler-text copy_bold_12 col-12"
      :class="{'fehler-text-oesa': mandant === 3,
                'fehler-text-oevo': mandant === 5}">
      {{ backendFehlerText }}
    </label>
  </div>
</template>

<script>
import mandant from '@/mandant';

export default {
  name: 'BackendFehler',
  mixins: [mandant],

  data() {
    return {
      fehlerText: '',
      backendFehlerText: 'Es ist ein Fehler aufgetreten. Bitte versuchen Sie es später erneut.',
      apiFehlerText: 'Es ist ein Verbindungsfehler aufgetreten. Entschuldigen Sie bitte die Unannehmlichkeiten und versuchen Sie es zu einem späteren Zeitpunkt noch einmal.',
    };
  },

  props: {
    fehler: {
      type: Boolean,
      default: false,
    },
    apiFehler: {
      type: Boolean,
      default: false,
    },
    backendFehler: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped>
#backend-fehler {
  margin-top: 15px;
}
</style>
