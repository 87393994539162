<template>
  <div>
    <div class="container container-kalibrierung main-content">
      <div class="row g-3 abstand-unten-0">
        <div class="col-xl-8 mb-5">
          <h1 class="abstand-unten-60">Änderung meiner Bankverbindung</h1>
          <div>
            <h2 class="abstand-unten-30">Zusammenfassung Ihrer Änderung</h2>
            <h5 class="abstand-unten-10 copy_16">Bitte überprüfen Sie Ihre Eingaben.</h5>
            <h5 class="abstand-unten-10 copy_16">Neue IBAN</h5>
            <h5 class="abstand-unten-10 copy_16">{{ iban }}</h5>
          </div>
        </div>
      </div>
      <h5 class="abstand-unten-10 copy_16">Die neue IBAN gilt für folgende Verträge:</h5>
      <div class="row">
        <div
          v-for="(vertrag, index) in vertraege"
          :key="index"
          :class="{ 'col-auto': (windowWidth > 577) }"
          class="copy_16 col-xl-4 abstand-oben-30">
          <karte-vertrag-bank
            :versicherungsscheinnummer="vertrag.vsnr"
            :produktname="vertrag.produktname"
            :spezifisches-merkmal="vertrag.spezifischesMerkmal">
          </karte-vertrag-bank>
        </div>
      </div>
      <button
        class="absenden-button button-ivv white abstand-unten-15"
        :class="{'button-vgh': mandant === 1,
        'button-oesa': mandant === 3,
        'button-oevo': mandant === 5}"
        :disabled="sendenButtonDisabled"
        @click="sendeNeueIban">
        Absenden
      </button>
      <backend-fehler :api-fehler="apiFehler" :backend-fehler="backendFehler"/>
    </div>
  </div>
</template>

<script>
import api from '@/backend-api';
import KarteVertragBank from '@/components/KarteMitAuswahl.vue';
import mandant from '@/mandant';
import BackendFehler from '@/components/BackendFehler.vue';
import Vue from 'vue';

export default {
  name: 'BankverbindungZusammenfassung',
  mixins: [mandant],
  props: ['iban', 'vertraege', 'bankverbindungLoeschen'],
  components: {
    KarteVertragBank,
    BackendFehler,
  },

  data() {
    return {
      apiFehler: false,
      backendFehler: false,
      sendenButtonDisabled: false,
    };
  },

  beforeMount() {
    this.$root.$refs.app.setZurueckFeld(false);
  },

  methods: {
    async sendeNeueIban() {
      try {
        this.backendFehler = false;
        if (this.vertraege.length > 0) {
          this.sendenButtonDisabled = true;
          const formData = new FormData();
          this.vertraege.forEach((element) => {
            const vertragskennzeichen = {
              vsnr: element.vsnr,
              laufendenr: element.laufendenr,
              produktkey: element.produktkey,
            };
            formData.append('vertragskennzeichenListe', JSON.stringify(vertragskennzeichen));
          });
          formData.append('bankverbindungLoeschen', this.bankverbindungLoeschen);
          formData.append('iban', this.removeSpaces(this.iban));
          await api.sendeNeueIban(formData,
            Vue.$keycloak.token);
          this.$store.state.kontoinhaberRadioButton = null;
          this.$emit('iban-senden');
        }
      } catch (e) {
        this.sendenButtonDisabled = false;
        if (e.response.status === 429) {
          this.apiFehler = true;
        } else {
          this.backendFehler = true;
        }
        console.log(e);
      }
    },
    removeSpaces(string) {
      return string.replaceAll(/\s/g, '');
    },
  },
};
</script>

<style scoped>
.absenden-button {
  margin-top: 30px;
}

.container-kalibrierung {
  margin-top: -24px;
  padding-top: 0px;
}

@media (max-width: 567px) {
  .abstand-unten-10 {
    margin-bottom: 0px;
  }

  .abstand-unten-60 {
    margin-bottom: 30px;
  }

  .container-kalibrierung {
    margin-top: 0px;
  }
}
</style>
