<template>
  <div>
    <div id="overlay" :class="{'vgh-blau-bg': mandant === 1,
    'oesa-gruen-bg': mandant === 3,
    'oevo-blau-bg': mandant === 5}"></div>
    <div id="sicherheitsabfrage-container" class="container row">
      <div class="row">
        <div id="close-btn" @click="emitAbbruch"><em class="fa-solid fa-xmark"></em></div>
      </div>
      <div class="row">
        <div class="col-12">
          <div>
            <h3 class="abfrage-titel">{{compTitel}}</h3>
            <p class="copy_16 abfrage-text">{{compText}}</p>
          </div>
        </div>
      </div>
      <div class="row" id="btn-row">
        <div class="col-12">
          <div>
            <input type="button"
                   class="button-ivv white zustimmung-btn"
                   :class="{'button-vgh': mandant === 1,
                      'button-oesa': mandant === 3,
                      'button-oevo': mandant === 5}"
                   :value="compZustimmungsBtn"
                   @click="emitZustimmung"
            >
            <input type="button"
                   id="ablehnen-btn"
                   class="button-ivv ablehnen-btn"
                   :value="compAblehnungsBtn"
                   @click="emitAblehnung"
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import sicherheitsabfrage from '@/sicherheitsabfrage';
import mandant from '@/mandant';

export default {
  name: 'Sicherheitsabfrage',
  mixins: [sicherheitsabfrage, mandant],
  props: ['typ', 'titel', 'text', 'zustimmungsBtn', 'ablehnungsBtn'],
  methods: {
    emitZustimmung() {
      this.$emit('zustimmung');
    },
    emitAblehnung() {
      this.schliesseAbfrage();
      this.$emit('ablehnung');
    },
    emitAbbruch() {
      this.schliesseAbfrage();
      this.$emit('abbruch');
    },
  },
  computed: {
    compTitel() {
      if (this.typ === 'verlassen') {
        return 'Achtung';
      }
      return this.titel;
    },
    compText() {
      if (this.typ === 'verlassen') {
        return 'Möchten Sie diese Seite wirklich verlassen?'
          + ' Eingaben werden dadurch gelöscht.';
      }
      return this.text;
    },
    compZustimmungsBtn() {
      if (this.typ === 'verlassen') {
        return 'Seite verlassen';
      }
      if (this.typ === 'verwerfen') {
        return 'Verwerfen';
      }
      if (this.typ === 'ausloeschen') {
        return 'Ja';
      }
      return this.zustimmungsBtn;
    },
    compAblehnungsBtn() {
      if (this.typ === 'verlassen') {
        return 'Abbrechen';
      }
      if (this.typ === 'verwerfen') {
        return 'Bearbeitung fortsetzen';
      }
      if (this.typ === 'ausloeschen') {
        return 'Nein';
      }
      return this.ablehnungsBtn;
    },
  },
};
</script>

<style scoped>
#overlay {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 10;
  opacity: 0.3;
}

#btn-row{
  padding-left: 15px;
  margin-bottom: 58px;
}

#close-btn {
  position: absolute;
  top: 35px;
  right: 35px;
  z-index: 1;
}

#sicherheitsabfrage-container {
  display: inline-block;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  max-width: 730px;
  height: fit-content;
  border-radius: 2px;
  text-align: left;
  filter: drop-shadow(0px 10px 20px rgba(0, 0, 0, 0.15));
  margin: auto;
  background-color: #ffffff;
  z-index: 11; /* 1px higher than the overlay layer */
}

.abfrage-text {
  padding: 0 15px;
  margin-bottom: 30px;
}

.abfrage-titel {
  padding: 0 15px;
  margin-top: 62px;
  margin-bottom: 20px;
}

.zustimmung-btn {
  margin-right: 15px;
}

/* Mobile View */
@media (max-width: 750px) {
  #sicherheitsabfrage-container {
    margin: 40px 15px;
    width: auto;
  }
  #btn-row{
    margin-bottom: 25px;
  }
  #close-btn {
    top: 20px;
    right: 20px;
  }
  .ablehnen-btn, .zustimmung-btn {
    margin-bottom: 15px;
  }
  .abfrage-titel {
    margin-top: 40px;
    margin-bottom: 10px;
  }
  .abfrage-text {
    margin-bottom: 20px;
  }
}
</style>
