var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"vertreter"},[_c('h2',{attrs:{"id":"ueberschrift"}},[_vm._v(_vm._s(_vm.ueberschrift))]),(_vm.vertreterbild)?_c('div',{staticClass:"row"},[_c('b-img',{staticClass:"abstand-unten-20 col-12 bild",attrs:{"src":_vm.vertreterbild},on:{"error":function($event){$event.target.src = _vm.getDefaultMandantenBild()}}})],1):_vm._e(),_c('h3',{staticClass:"abstand-unten-20"},[_vm._v(_vm._s(_vm.vertreter.name))]),(_vm.vertreter.vertretertyp === 'Makler')?_c('p',{staticClass:"copy_12"},[_vm._v(" Dieser Vertrag wird von Ihrem Versicherungsmakler betreut. "),_c('br'),_vm._v(" Bei Fragen wenden Sie sich bitte an Ihren Versicherungsmakler oder an unser Kundendienstcenter unter folgender Kontaktmöglichkeit. ")]):_vm._e(),(!_vm.anschriftAusblenden && _vm.vertreter.anschrift!=null)?_c('p',{staticClass:"copy_16 abstand-unten-30",attrs:{"id":"anschrift"}},[_vm._v(" "+_vm._s(_vm.vertreter.anschrift.strasse + ' ' + _vm.vertreter.anschrift.hausnummer)+" "),_c('br'),_vm._v(" "+_vm._s(_vm.vertreter.anschrift.postleitzahl + ' ' + _vm.vertreter.anschrift.ort)+" ")]):_vm._e(),(_vm.vertreter.telefonnummer)?_c('p',{staticClass:"abstand-unten-20 copy_16",attrs:{"id":"telefon"}},[_c('em',{staticClass:"fa-solid fa-phone",class:{'vgh-blau-color': _vm.mandant === 1,
       'oesa-gruen-color': _vm.mandant === 3,
       'oevo-blau-color': _vm.mandant === 5},attrs:{"id":"telefon-icon"}}),_c('strong',{attrs:{"id":"telefon-nummer"}},[_vm._v(_vm._s(_vm.vertreter.telefonnummer))])]):_vm._e(),(_vm.vertreter.email)?_c('p',{staticClass:"abstand-unten-20 copy_16"},[_c('em',{staticClass:"fa-solid fa-envelope",class:{'vgh-blau-color': _vm.mandant === 1,
       'oesa-gruen-color': _vm.mandant === 3,
       'oevo-blau-color': _vm.mandant === 5},attrs:{"id":"brief-icon"}}),_c('strong',{attrs:{"id":"e-mail"}},[_vm._v(_vm._s(_vm.vertreter.email))])]):_vm._e(),(_vm.webseite)?_c('p',{staticClass:"abstand-unten-20"},[_c('em',{staticClass:"fa-solid fa-globe",class:{'vgh-blau-color': _vm.mandant === 1,
        'oesa-gruen-color': _vm.mandant === 3,
        'oevo-blau-color': _vm.mandant === 5},attrs:{"id":"web-icon"}}),_c('a',{staticClass:"text-link",class:{'vgh-blau-color': _vm.mandant === 1,
              'vgh-blau-text-decoration-hover': _vm.mandant === 1,
              'oesa-gruen-color': _vm.mandant === 3,
              'oesa-gruen-text-decoration-hover': _vm.mandant === 3,
              'oevo-blau-color': _vm.mandant === 5,
              'oevo-blau-text-decoration-hover': _vm.mandant === 5},attrs:{"id":"website","href":_vm.webseite,"target":"_blank"}},[_vm._v("Webseite ")])]):_vm._e(),_c('div',{staticClass:"abstand-unten-20",on:{"click":_vm.zurNachricht}},[(_vm.nachricht && _vm.zugriffsStatus === 'eigeneOrga')?_c('pfeil-button',{staticClass:"copy_16",class:{blau: true},attrs:{"button-text":_vm.nachrichtText}}):_vm._e()],1),_c('div',{staticClass:"abstand-unten-20",on:{"click":_vm.zumBeratungstermin}},[(_vm.termin && _vm.zugriffsStatus === 'eigeneOrga')?_c('pfeil-button',{staticClass:"copy_16",class:{blau: true},attrs:{"button-text":_vm.terminText}}):_vm._e()],1),_c('div',{staticClass:"abstand-unten-20"},[(_vm.vertrLink && (_vm.zugriffsStatus === 'eigeneOrga'))?_c('pfeil-button',{staticClass:"copy_16",class:{blau: true},attrs:{"button-text":_vm.vertreterText,"path":_vm.vertreterPath}}):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }