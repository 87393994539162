<template>
  <div v-if="!loading">
    <div>
      <sicherheitsabfrage
        v-show="showAbfrage"
        typ="verwerfen"
        :titel="titelAbfrage"
        :text="textAbfrage"
        @zustimmung="zurueck"
        @abbruch="closeAbfrage"
        @ablehnung="closeAbfrage">
      </sicherheitsabfrage>
      <sicherheitsabfrage
        v-if="verlasseSeiteAbfrage"
        typ="verlassen"
        @abbruch="schliesseAbfrage"
        @ablehnung="schliesseAbfrage"
        @zustimmung="behandleSeiteVerlassen">
      </sicherheitsabfrage>
      <div v-if="isKrankenversicherung(vsnr)" class="container main-content">
        <div class="row g-3">
          <div class="col-xl-8 abstand-unten-32">
            <h1 class="abstand-unten-30">
              Schadenmeldung zu Ihrer {{ vertrag.produktname }}-Versicherung
            </h1>
          </div>
        </div>
            <div class="row">
              <bestaetigung :no-check="true">
                Ihre Schadenmeldungen, Rechnungen, Rezepte sowie Heil- und Kostenpläne zur
                Krankenversicherung können Sie ganz einfach über die App
                <span v-if="mandant === 5 || (this.platform === 'desktop' && mandant !== 5)">
                  {{ setMandantAppName() }}</span>
                <a v-else-if="mandant === 1 || mandant === 3"
                   class="app-link"
                   :href="getAppStoreKranken()"
                   target="_blank">
                  {{ setMandantAppName() }}
                </a>
                einreichen.
              </bestaetigung>
        </div>
      </div>
      <div v-else class="container main-content">
        <slot name="benachrichtigung"></slot>
        <div class="row g-3">
          <div class="col-xl-8 abstand-unten-32">
            <h1 class="abstand-unten-30">
              Schadenmeldung zu Ihrer {{ vertrag.produktname }}-Versicherung
            </h1>
            <template v-if="mandant !== 3 && isRechtsschutz(vsnr, vertrag.produktkey)">
              <rechts-kompass></rechts-kompass>
            </template>
            <template v-else>
              <div class="row">
                <div class="col-12 abstand-unten-15">
                  <label class="aufschrift copy_bold_12">Wann ist der Schaden passiert?*</label>
                  <div id="schaden-zeitpunkt" class="row">
                    <div class="col-lg-6 col-sm-12">
                      <span class="aufschrift copy_bold_12">Datum:</span>
                      <b-form-datepicker
                        id="schaden-datepicker"
                        v-model="datumAnzeige"
                        class="eingabe copy_16"
                        :class="{'fehler': errors.datum,
                          'fehler-oesa': errors.datum && mandant === 3,
                          'vgh-eingabe': mandant === 1,
                          'oesa-eingabe': mandant === 3,
                          'oevo-eingabe': mandant === 5,
                          'fehler-oevo': errors.datum && mandant === 5}"
                        no-flip
                        v-bind="labels.de"
                        :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
                        locale="de"
                        placeholder="TT.MM.JJJJ"
                        @context="onContextDate"></b-form-datepicker>
                      <label
                        v-if="errors.datum"
                        class="fehler-text copy_bold_12">
                        {{ errors.datum }}
                      </label>
                    </div>
                    <div class="col-lg-6 col-sm-12">
                      <span class="aufschrift copy_bold_12">Uhrzeit (ungefähr):</span>
                      <input type="time"
                             class="eingabe copy_16"
                             :class="{'fehler': errors.uhrzeit,
                              'fehler-oesa': errors.uhrzeit && mandant === 3,
                              'vgh-eingabe': mandant === 1,
                              'oesa-eingabe': mandant === 3,
                              'oevo-eingabe': mandant === 5,
                              'fehler-oevo': errors.uhrzeit && mandant === 5}"
                             v-model="uhrzeit"
                             required
                             placeholder="Schadenuhrzeit"
                             pattern="[0-9]{2}:[0-9]{2}"
                             id="schaden-timepicker">
                      <label
                        v-if="errors.uhrzeit"
                        class="fehler-text copy_bold_12">
                        {{ errors.uhrzeit }}
                      </label>
                    </div>
                  </div>
                </div>
                <div class="col-12 abstand-unten-10">
                  <label class="aufschrift copy_bold_12">
                    Besteht ein Personenschaden?*</label><br/>
                  <div class="aufschrift copy_bold_12">
                    <input
                      type="radio"
                      class="auswahl-button"
                      :class="{'fehler': errors.personenschaden,
                      'input-vgh': mandant === 1,
                      'input-oesa': mandant === 3,
                      'input-oevo': mandant === 5}"
                      id="ja"
                      value="ja"
                      v-model="radioAuswahl">
                    <label id="ja-label" for="ja"></label>
                    <span>Ja</span>&emsp;
                    <input
                      type="radio"
                      class="auswahl-button"
                      :class="{'fehler': errors.personenschaden,
                      'input-vgh': mandant === 1,
                      'input-oesa': mandant === 3,
                      'input-oevo': mandant === 5}"
                      id="nein"
                      value="nein"
                      v-model="radioAuswahl">
                    <label for="nein"></label>
                    <span>Nein</span>&emsp;
                    <label
                      v-if="errors.personenschaden"
                      class="fehler-text copy_bold_12">
                      {{ errors.personenschaden }}
                    </label>
                  </div>
                  <div v-if="personenschadenHinweis">
                    <label class="copy_bold_12">
                      Der Inhalt Ihrer Schadenmeldung wird an den für Sie zuständigen
                      Vertriebspartner zur weiteren Schaden-/Leistungsfallbearbeitung
                      weitergeleitet - diese Verarbeitung besonderer personenbezogener Daten
                      (einschließlich Gesundheitsdaten) erfolgt auf gesetzlicher Grundlage
                      zur Geltendmachung, Verteidigung und Ausübung von
                      Rechtsansprüchen (Art. 9 (2) f) DS-GVO).</label>
                  </div>
                </div>
                <div class="col-12 abstand-unten-15">
                  <label class="aufschrift copy_bold_12">Was ist passiert?*</label>
                  <textarea
                    class="eingabe copy_16"
                    v-model="text"
                    maxlength="1500"
                    id="nachricht"
                    :class="{'fehler': errors.text,
                          'fehler-oesa': errors.text && mandant === 3,
                          'vgh-eingabe': mandant === 1,
                          'oesa-eingabe': mandant === 3,
                          'oevo-eingabe': mandant === 5,
                          'fehler-oevo': errors.text && mandant === 5}"
                    placeholder="Bitte beschreiben Sie kurz und knapp den Schaden"
                  ></textarea>
                  <label class="zaehler-label copy_12">{{ text.length }} / 1500 Zeichen</label>
                  <label
                    v-if="errors.text"
                    class="fehler-text textarea-fehler-text copy_bold_12">
                    {{ errors.text }}
                  </label>
                </div>
                <div class="col-12 abstand-unten-15">
                  <label class="aufschrift copy_bold_12">Erreichbarkeit*</label>
                  <textarea
                    class="eingabe copy_16"
                    v-model="erreichbarkeit"
                    maxlength="500"
                    id="erreichbarkeit"
                    :class="{'fehler': errors.erreichbarkeit,
                           'fehler-oesa': errors.erreichbarkeit && mandant === 3,
                           'vgh-eingabe': mandant === 1,
                           'oesa-eingabe': mandant === 3,
                           'oevo-eingabe': mandant === 5,
                           'fehler-oevo': errors.erreichbarkeit && mandant === 5}"
                    placeholder="Wie können wir Sie bei Rückfragen erreichen?"
                  ></textarea>
                  <label class="zaehler-label copy_12">
                    {{ erreichbarkeit.length }} / 500 Zeichen
                  </label>
                  <label
                    v-if="errors.erreichbarkeit"
                    class="fehler-text textarea-fehler-text copy_bold_12">
                    {{ errors.erreichbarkeit }}
                  </label>
                </div>
                <div class="col-12">
                  <div class="col-12" id="upload-hinweis">
                    <hinweis label="Schadenbilder und Dokumente" right="0">
                      Laden Sie bitte hier bereits vorhandene Angebote sowie Fotos hoch,
                      die den Schadenumfang und die Schadenursache dokumentieren. <br>
                      Zulässige Dateiformate sind PDF, JPG, JPEG und PNG. <br>
                      Die maximale Uploadgröße ist 12 MB.
                    </hinweis>
                  </div>
                  <upload
                    v-on:dateienSenden="dateienAnnehmen"
                    ref="upload"
                    upload-bezeichnung="Anhang"
                    columnSize="col-12"
                    @dateiGeloescht="errors.dateien = ''"
                    id="upload"
                  >
                  </upload>
                  <label
                    v-if="errors.dateien"
                    class="fehler-text copy_bold_12">
                    Bitte versuchen Sie andere Dateien hochzuladen.
                  </label>
                </div>
                <div class="col-12">
                  <label id="pflicht-label" class="copy_12">Pflichtfeld*</label>
                </div>
              </div>
              <div class="row g-3 abstand-unten-15">
                <div class="col-12">
                  <input type="button"
                         id="senden-btn"
                         class="button-ivv white"
                         :class="{'button-vgh': mandant === 1,
                            'button-oesa': mandant === 3,
                            'button-oevo': mandant === 5}"
                         value="Senden"
                         :disabled="weiterButtonDisabled"
                         @click="sendeSchaden"
                  >
                  <input type="button"
                         id="verwerfen-btn"
                         class="button-ivv"
                         value="Verwerfen"
                         @click="showAbfrage = true"
                  >
                </div>
              </div>
            </template>
            <backend-fehler :api-fehler="apiFehler" :backend-fehler="backendFehler"/>
          </div>
          <div class="col-xl-4">
            <vertreter-info
              ueberschrift="Wir helfen Ihnen gerne bei Ihrer Schadenmeldung."
              :mail-adresse="true"
              :nachricht="true"
              :termin="true"
              :vertrLink="vertreterLink"
              :social-media="true"
              :vertreter="vertrag.bestandsvertreter"
              :vsnr="vsnr"
              :laufendenr="laufendenr"
              :produktkey="produktkey"
            >
            </vertreter-info>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Sicherheitsabfrage from '@/components/Sicherheitsabfrage.vue';
import Upload from '@/components/Upload.vue';
import VertreterInfo from '@/components/VertreterInfo.vue';
import api from '@/backend-api';
import isoUtil from '@/isoUtil';
import sicherheitsabfrage from '@/sicherheitsabfrage';
import { mapState } from 'vuex';
import bestaetigungUtil from '@/bestaetigungUtil';
import mandant from '@/mandant';
import BackendFehler from '@/components/BackendFehler.vue';
import Hinweis from '@/components/Hinweis.vue';
import Vue from 'vue';
import Bestaetigung from '@/components/Bestaetigung.vue';
import RechtsKompass from '@/schaden-melden/RechtsKompass.vue';

export default {
  name: 'SchadenForm',
  mixins: [isoUtil, sicherheitsabfrage, bestaetigungUtil, mandant],
  props: {
    vsnr: {
      type: String,
      required: true,
    },
    laufendenr: {
      type: String,
      required: true,
    },
    produktkey: {
      type: String,
      required: true,
    },
  },
  components: {
    RechtsKompass,
    Bestaetigung,
    Hinweis,
    VertreterInfo,
    Sicherheitsabfrage,
    Upload,
    BackendFehler,
  },
  data() {
    return {
      weiterButtonDisabled: false,
      vertrag: {
        produktname: '',
        bestandsvertreter: {
          vertreternummer: '',
          name: '',
          anschrift: {
            strasse: '',
            hausnummer: '',
            postleitzahl: '',
            ort: '',
          },
          telefonnummer: '',
          email: '',
        },
      },
      labels: {
        de: {
          labelPrevDecade: 'Vorheriges Jahrzehnt',
          labelPrevYear: 'Vorheriges Jahr',
          labelPrevMonth: 'Vorheriger Monat',
          labelCurrentMonth: 'Aktueller Monat',
          labelNextMonth: 'Nächster Monat',
          labelNextYear: 'Nächstes Jahr',
          labelNextDecade: 'Nächstes Jahrzehnt',
          labelToday: 'Heute',
          labelSelected: 'Ausgewähltes Datum',
          labelNoDateSelected: 'Kein Datum gewählt',
          labelCalendar: 'Kalender',
          labelNav: 'Kalendernavigation',
          labelHelp: 'Mit den Pfeiltasten durch den Kalender navigieren',
        },
      },
      text: '',
      datumAnzeige: '',
      datum: null,
      uhrzeit: '',
      radioAuswahl: null,
      personenschadenHinweis: false,
      erreichbarkeit: '',
      errors: {
        text: '',
        erreichbarkeit: '',
        dateien: '',
        datum: '',
        uhrzeit: '',
        personenschaden: '',
      },
      erfolg: false,
      apiFehler: false,
      backendFehler: false,
      zurueckAbfrage: false,
      showAbfrage: false,
      titelAbfrage: 'Möchten Sie Ihre Schadenmeldung wirklich verwerfen?',
      textAbfrage: 'Ihr bisheriger Entwurf und Ihre hochgeladenen Anhänge werden gelöscht.',
      dateien: [],
      vertreterLink: false,
      loading: true,
      googlePlayVghKrankenPath: 'https://play.google.com/store/apps/details?id=net.aokv.gesundheit&hl=de&pli=1',
      googlePlayOesaKrankenPath: 'https://play.google.com/store/apps/details?id=de.ukv.mgm',
      appstoreVghKrankenPath: 'https://apps.apple.com/de/app/vgh-gesundheit/id1141639901',
      appstoreOesaKrankenPath: 'https://apps.apple.com/us/app/ukv-mein-gesundheitsmanager/id6445958679',
    };
  },
  computed: {
    ...mapState(['vertreter']),
  },
  methods: {
    async sendeSchaden() {
      try {
        this.backendFehler = false;
        this.validateMessage(true);
        this.validateErreichbarkeit(true);
        this.validateDatum();
        this.validateUhrzeit();
        this.validatePersonenschaden();

        if (this.isValid()) {
          this.weiterButtonDisabled = true;
          const formData = new FormData();
          this.dateien.forEach((datei) => {
            const currentFile = new File([datei], encodeURI(datei.name.normalize('NFC')), {
              type: datei.type,
            });
            formData.append('file', currentFile);
          });
          formData.append('versicherungsscheinnummer', this.vertrag.vsnr);
          formData.append('laufendenr', this.vertrag.laufendenr);
          formData.append('produktkey', this.vertrag.produktkey);
          formData.append('datum', this.datum.toJSON());
          formData.append('uhrzeit', this.uhrzeit);
          formData.append('schadenschilderung', this.text);
          formData.append('erreichbarkeit', this.erreichbarkeit);
          formData.append('vertreternummer', this.vertrag.bestandsvertreter.vertreternummer);
          formData.append('hauptvertreternummer', this.vertrag.bestandsvertreter.hauptvertreternummer);
          await api.sendeSchadenmeldung(
            formData,
            Vue.$keycloak.token,
          );
          await this.$store.dispatch('ladePostfachstatus');
          this.zumAbschluss(true);
        }
      } catch (e) {
        this.weiterButtonDisabled = false;
        if (e.response.status === 429) {
          this.apiFehler = true;
        } else {
          this.backendFehler = true;
        }
        console.log(e);
      }
    },
    closeAbfrage() {
      this.showAbfrage = false;
    },
    schliesseAbfrage() {
      this.verlasseSeiteAbfrage = false;
    },
    dateienAnnehmen(daten) {
      this.dateien = daten;
    },
    eingegeben() {
      return (this.datum || this.uhrzeit || this.text
        || this.erreichbarkeit || this.dateien.length > 0);
    },
    zurueck() {
      this.verlasseSeite = true;
      this.$router.back();
    },
    isValid() {
      return (this.errors.text.length === 0 && this.errors.erreichbarkeit.length === 0
        && this.errors.datum.length === 0 && this.errors.uhrzeit.length === 0
        && this.errors.personenschaden.length === 0);
    },
    onContextDate(ctx) {
      this.datum = ctx.selectedDate;
    },
    setZurueckFeld() {
      this.$root.$refs.app.setZurueckFeld(true);
    },
    validateDatum() {
      const today = new Date();
      if (!this.datum) {
        this.errors.datum = 'Bitte geben Sie das Schadendatum ein.';
      } else if (this.datum.setHours(0, 0, 0, 0) > today.setHours(0, 0, 0, 0)) {
        this.errors.datum = 'Bitte geben Sie einen Schadenzeitpunkt in der Vergangenheit ein.';
      } else {
        this.errors.datum = '';
      }
      if (this.uhrzeit) {
        this.validateUhrzeit();
      }
    },
    validatePersonenschaden() {
      if (!this.radioAuswahl) {
        this.errors.personenschaden = 'Bitte Auswählen';
      } else {
        this.errors.personenschaden = '';
      }
    },
    validateDatumWatcher() {
      if (this.eingegeben()) {
        this.validateDatum();
      }
    },
    validateUhrzeit() {
      let today = new Date();
      if (!this.uhrzeit) {
        this.errors.uhrzeit = 'Bitte geben Sie die ungefähre Schadenuhrzeit ein.';
      } else if (this.datum && this.datum.setHours(0, 0, 0, 0) === today.setHours(0, 0, 0, 0)) {
        today = new Date();
        if (parseInt(this.uhrzeit.substring(0, 2), 10) > today.getHours()) {
          this.errors.uhrzeit = 'Bitte geben Sie einen Schadenzeitpunkt in der Vergangenheit ein.';
        } else {
          this.errors.uhrzeit = '';
        }
      } else {
        this.errors.uhrzeit = '';
      }
    },
    validateUhrzeitWatcher() {
      if (this.eingegeben()) {
        this.validateUhrzeit();
      }
    },
    validateMessage(istLeerCheck) {
      if (this.text !== '') {
        if (this.checkISO(this.text)) {
          this.errors.text = '';
        } else {
          this.errors.text = 'Bitte achten Sie auf Sonderzeichen.';
        }
      } else if (istLeerCheck) {
        this.errors.text = 'Bitte beschreiben Sie Ihren Schaden.';
      } else {
        this.errors.text = '';
      }
    },
    validateErreichbarkeit(istLeerCheck) {
      if (this.erreichbarkeit !== '') {
        if (this.checkISO(this.erreichbarkeit)) {
          this.errors.erreichbarkeit = '';
        } else {
          this.errors.erreichbarkeit = 'Bitte achten Sie auf Sonderzeichen.';
        }
      } else if (istLeerCheck) {
        this.errors.erreichbarkeit = 'Bitte geben Sie an, wie wir Sie erreichen können.';
      } else {
        this.errors.erreichbarkeit = '';
      }
    },
    zumAbschluss(erfolg) {
      document.getElementById('app').scrollTo(0, 0);
      this.verlasseSeite = true;
      this.$router.push({
        name: 'Home',
        params: {
          bestaetigung: erfolg ? 'schadenmeldung' : 'schadenmeldung-error',
        },
      });
    },
    isKrankenversicherung(vsnr) {
      const tarifwerkKranken = ['0428', '2451', '0552', '0551', '1128', '0527', '0228'];
      return tarifwerkKranken.includes(vsnr.substr(0, 4));
    },
    isRechtsschutz(vsnr, produktkey) {
      const tarifwerk = vsnr.substr(2, 2);
      return tarifwerk === '25' || (tarifwerk === '68' && produktkey.toString() === '17');
    },
    detectPlatform() {
      const isTouchDevice = 'ontouchstart' in window;
      if (isTouchDevice) {
        if (/iPad|iPhone|iPod/.test(navigator.userAgent)) {
          this.platform = 'ios';
        } else {
          this.platform = 'touchDevice'; // Default touchDevice if not iOS
        }
      } else {
        this.platform = 'desktop';
      }
      return this.platform;
    },
    getAppStoreKranken() {
      if (this.mandant === 1) {
        return this.platform === 'ios' ? this.appstoreVghKrankenPath : this.googlePlayVghKrankenPath;
      }
      if (this.mandant === 3) {
        return this.platform === 'ios' ? this.appstoreOesaKrankenPath : this.googlePlayOesaKrankenPath;
      }
      return this.platform; // Default link for mandant
    },
    setMandantAppName() {
      if (this.mandant === 1) {
        this.mandantAppName = 'VGH Gesundheit';
      }
      if (this.mandant === 3) {
        this.mandantAppName = 'UKV - Mein GesundheitsManager';
      }
      if (this.mandant === 5) {
        this.mandantAppName = 'Ihres Krankenversicherers';
      }
      return this.mandantAppName;
    },
    async getVertrag() {
      this.vertrag = await api.getEinzelVertrag(
        this.vsnr,
        this.laufendenr,
        this.produktkey,
        Vue.$keycloak.token,
      )
        .then((response) => response.data)
        .then((vertrag) => vertrag)
        .catch((error) => {
          console.log(error);
        });
    },
  },
  async beforeMount() {
    this.setZurueckFeld();
    await this.getVertrag();
  },
  mounted() {
    this.detectPlatform();
    this.$emit('view-geladen');
  },
  async created() {
    await this.getVertrag();
    this.loading = false;
    if (this.vertrag.bestandsvertreter.email === this.vertreter.email) {
      this.vertreterLink = true;
    }
  },
  watch: {
    datum() {
      this.validateDatumWatcher();
    },
    uhrzeit() {
      this.validateUhrzeitWatcher();
    },
    text() {
      this.validateMessage(false);
    },
    erreichbarkeit() {
      this.validateErreichbarkeit(false);
    },
    radioAuswahl(value) {
      this.validatePersonenschaden();
      this.personenschadenHinweis = value === 'ja';
    },
  },
};
</script>

<style scoped>
#nachricht, #erreichbarkeit {
  height: 160px;
  resize: none;
}

#schaden-zeitpunkt .eingabe {
  display: flex;
}

#schaden-timepicker {
  background-image: url(../assets/clock-regular.svg);
  text-indent: 45px;
  background-size: 20px;
  background-repeat: no-repeat;
  background-position: 23px 15px;
  -webkit-box-shadow: none;
  cursor: pointer;
}

#schaden-timepicker:hover {
  background-image: url(../assets/clock-solid.svg);
}

#senden-btn {
  margin-right: 15px;
}

#senden-btn:disabled {
  background: #0558F0;
  opacity: 0.5;
}

#pflicht-label {
  color: #6B7171;
  margin: 19px 0 30px 0;
}

#ja-label {
  margin-left: -30px;
}

.zaehler-label {
  display: flex;
  justify-content: flex-end;
  color: #6B7171;
  margin-bottom: 0;
}

.textarea-fehler-text {
  position: relative;
  top: -15px;
  left: 0;
}

.aufschrift {
  margin-bottom: 6px;
  white-space: nowrap;
}

.eingabe {
  padding: 6px 9px;
}

input[type='time']::-webkit-calendar-picker-indicator {
  opacity: 0;
  position: absolute;
  width: 100%;
}

.app-link {
  color: white;
  text-decoration: underline;
  cursor: pointer;
}

.app-link:hover {
  color: white;
  text-decoration: none;
}

input::-webkit-date-and-time-value {
  max-width: 150px;
}

.fehler:focus {
  outline: none !important;
}

#upload-hinweis {
  padding: 0;
}

@media (max-width: 576px) {
  #pflicht-label {
    margin-bottom: 20px;
  }

  #upload-hinweis {
    width: 100%;
  }
}

@supports (-webkit-touch-callout: none) {
  #schaden-timepicker {
    text-indent: 0px;
  }
}
</style>
