<template>
  <a id="button" class="copy_16 pfeil-btn text-link" @click="zumLink"
     :class="{'vgh-blau-color': mandant === 1,
      'vgh-blau-text-decoration-hover': mandant === 1,
      'oesa-gruen-color': mandant === 3,
      'oesa-gruen-text-decoration-hover': mandant === 3,
      'oevo-blau-color': mandant === 5,
      'oevo-blau-text-decoration-hover': mandant === 5,
      'disabled': disabled}">
    <em class="fa-solid fa-chevron-right pfeil"></em>{{ buttonText }}
  </a>
</template>

<script>
import mandant from '@/mandant';

export default {
  name: 'PfeilButton',
  mixins: [mandant],
  props: ['button-text', 'path', 'param', 'disabled'],

  methods: {
    zumLink() {
      if (!this.disabled) {
        if (this.path) {
          if (this.param) {
            this.$router.push({ path: `/${this.path}/${this.param}` });
          } else if (this.path.startsWith('https')) {
            window.open(this.path, '_blank');
          } else {
            this.$router.push(this.path);
          }
        } else {
          this.$emit('click');
        }
      }
    },
  },
};
</script>

<style scoped>
.pfeil-btn.disabled {
  color: #808485 !important;
  cursor: default;
}

.pfeil-btn.disabled:hover {
  text-decoration: none !important;
  color: #808485 !important;
  border-bottom: none;
}

.pfeil-btn {
  color: #484a4f;
  background: none;
  padding-bottom: 0;
  padding-left: 0;
  cursor: pointer;
}

.pfeil-btn:hover {
  text-decoration: underline;
  text-decoration-color: #484a4f;
  color: #484a4f;
}

.pfeil {
  font-size: 18px;
  margin-left: 5px;
  margin-right: 14px;
}

</style>
