<template>
  <div>
    <div id="overlay" class="oevo-blau-bg"></div>
    <div id="info-banner-container" class="container row">
      <div class="row">
        <div class="col-12">
          <div>
            <h3 class="info-titel">Es ist ein Fehler aufgetreten.</h3>
            <p class="copy_16 info-text">
              Leider ist beim Laden des Portals ein Problem aufgetreten. <br>
              Bitte versuchen Sie es später erneut.<br>
              <br>
              Sollte das Problem bestehen bleiben, können Sie sich
              <a
                :href="faqLink"
                target="_blank"
                class="text-link oevo-blau-color oevo-blau-text-decoration-hover">hier</a>
              an unser Support-Team wenden.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'OevoKeinVertreter',
  computed: {
    faqLink() {
      return this.$store.state.weiterleitungen.faq;
    },
  },
};
</script>

<style scoped>
#overlay {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 10;
  opacity: 0.85;
}

#info-banner-container {
  display: inline-block;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  max-width: 730px;
  height: fit-content;
  border-radius: 2px;
  text-align: left;
  filter: drop-shadow(0px 10px 20px rgba(0, 0, 0, 0.15));
  margin: auto;
  background-color: #ffffff;
  z-index: 11; /* 1px higher than the overlay layer */
}

.info-text {
  padding: 0 15px;
  margin-bottom: 30px;
}

.info-titel {
  padding: 0 15px;
  margin-top: 62px;
  margin-bottom: 20px;
}

/* Mobile View */
@media (max-width: 750px) {
  #info-banner-container {
    margin: 40px 15px;
    width: auto;
  }
  .info-titel {
    margin-top: 40px;
    margin-bottom: 10px;
  }
  .info-text {
    margin-bottom: 20px;
  }
}
</style>
