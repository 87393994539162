<template>
  <div>
    <sicherheitsabfrage
      v-show="showAbfrage"
      typ="verwerfen"
      :titel="titelAbfrage"
      :text="textAbfrage"
      @zustimmung="zurueck"
      @abbruch="closeAbfrage"
      @ablehnung="closeAbfrage">
    </sicherheitsabfrage>
    <sicherheitsabfrage
      v-if="verlasseSeiteAbfrage"
      typ="verlassen"
      @zustimmung="behandleSeiteVerlassen"
      @ablehnung="schliesseAbfrage"
      @abbruch="schliesseAbfrage">
    </sicherheitsabfrage>
    <div class="container main-content">
      <slot name="benachrichtigung"></slot>
      <div class="row g-3">
        <div class="col-xl-8">
          <h1 class="abstand-unten-30">Nachricht schreiben</h1>
          <h2 class="abstand-unten-30">Meine Nachricht an {{ vertreter.name }}</h2>
          <div class="row">
            <div class="col-12 abstand-unten-17">
              <label class="aufschrift copy_bold_12" for="betreff">Betreff *</label>
              <input
                class="eingabe copy_16"
                :class="{'fehler': betreffFehler && mandant === 1,
                        'fehler-oesa': betreffFehler && mandant === 3,
                        'vgh-eingabe': mandant === 1,
                        'oesa-eingabe': mandant === 3,
                        'oevo-eingabe': mandant === 5,
                        'fehler-oevo': betreffFehler && mandant === 5}"
                v-model="betreffEingabe"
                maxlength="120"
                id="betreff">
            </div>
            <label id="betreff-fehler-label"
                   v-if="betreffFehler"
                   class="aufschrift fehler-text copy_bold_12"
                   :class="{'fehler-text-oesa': mandant === 3,
                'fehler-text-oevo': mandant === 5}"
            >
              {{ betreffFehlerText }}
            </label>
            <div class="col-12 abstand-unten-15">
              <label class="aufschrift copy_bold_12">Nachricht *</label>
              <textarea
                class="eingabe copy_16"
                :class="{'fehler': nachrichtFehler && mandant === 1,
                        'fehler-oesa': nachrichtFehler && mandant === 3,
                        'vgh-eingabe': mandant === 1,
                        'oesa-eingabe': mandant === 3,
                        'oevo-eingabe': mandant === 5,
                        'fehler-oevo': nachrichtFehler && mandant === 5}"
                v-model="nachrichtEingabe"
                maxlength="1200"
                id="nachricht"
              ></textarea>
              <label class="zaehler-label copy_12">
                {{ nachrichtEingabe.length }} / 1200 Zeichen
              </label>
              <label
                v-if="nachrichtFehler"
                class="fehler-text copy_bold_12"
                :class="{'fehler-text-oesa': mandant === 3,
                  'fehler-text-oevo': mandant === 5}">
                {{ nachrichtFehlerText }}
              </label>
            </div>
            <div class="col-12">
              <div class="col-12" id="upload-hinweis">
                <hinweis label="Anhang" right="0">
                  Zulässige Dateiformate sind PDF, JPG, JPEG und PNG. <br>
                  Die maximale Uploadgröße ist 12 MB.
                </hinweis>
              </div>
              <upload
                v-on:dateienSenden="dateienAnnehmen"
                ref="upload"
                upload-bezeichnung="Anhang"
                @dateiGeloescht="showUploadFehler = false"
                columnSize="col-12"
                id="upload"
              >
              </upload>
              <label
                v-if="showUploadFehler"
                class="fehler-text copy_bold_12"
                :class="{'fehler-text-oesa': mandant === 3,
                  'fehler-text-oevo': mandant === 5}">
                Bitte versuchen Sie andere Dateien hochzuladen.
              </label>
            </div>
              <div class="col-12">
                <label class="copy_bold_12">Ihre Nachricht wird dem hier angezeigten
                  Ansprechpartner zugestellt - dieser kann von dem für
                  Ihre Vertrags- und Schadenbetreuung zuständigen Vertriebspartner
                  abweichen.</label>
                <label class="copy_bold_12">Bitte beachten Sie daher den separaten
                  Prozess zur Schadenmeldung - nur dieser stellt sicher,
                  dass Ihre Schadenmeldung auch an den dafür zuständigen
                  Vertriebspartner weitergeleitet wird.</label>
            </div>
            <div class="col-12">
              <label id="pflicht-label" class="copy_12">Pflichtfeld*</label>
            </div>
          </div>
        </div>
      </div>
      <div class="row g-3 abstand-unten-15">
        <div class="col-12">
          <input type="button"
                 id="senden-btn"
                 class="button-ivv white"
                 :class="{'button-vgh': mandant === 1,
                 'button-oesa': mandant === 3,
                 'button-oevo': mandant === 5}"
                 value="Senden"
                 :disabled="sendenButtonDisabled"
                 @click="sendeNachricht"
          >
          <input type="button"
                 id="verwerfen-btn"
                 class="button-ivv"
                 value="Verwerfen"
                 @click="showAbfrage = true"
          >
        </div>
      </div>
      <backend-fehler :api-fehler="apiFehler" :backend-fehler="backendFehler"/>
    </div>
  </div>
</template>

<script>
import api from '@/backend-api';
import Sicherheitsabfrage from '@/components/Sicherheitsabfrage.vue';
import Upload from '@/components/Upload.vue';
import sicherheitsabfrage from '@/sicherheitsabfrage';
import isoUtil from '@/isoUtil';
import bestaetigungUtil from '@/bestaetigungUtil';
import mandant from '@/mandant';
import BackendFehler from '@/components/BackendFehler.vue';
import Hinweis from '@/components/Hinweis.vue';
import Vue from 'vue';

export default {
  name: 'Nachricht',
  mixins: [sicherheitsabfrage, isoUtil, bestaetigungUtil, mandant],
  props: {
    vsnr: {
      type: String,
      required: false,
    },
    produktkey: {
      type: String,
      required: false,
    },
    laufendenr: {
      type: String,
      required: false,
    },
    konversationsId: {
      type: String,
      required: false,
    },
    nachrichtenverlauf: {
      type: Boolean,
      required: false,
    },
  },
  components: {
    Sicherheitsabfrage,
    Upload,
    BackendFehler,
    Hinweis,
  },

  data() {
    return {
      // Mixin Attribute:
      // verlasseSeite: false,
      // verlasseSeiteAbfrage: false,
      sendenButtonDisabled: false,
      betreffEingabe: '',
      nachrichtEingabe: '',

      betreffFehler: false,
      betreffFehlerText: '',
      nachrichtFehler: false,
      nachrichtFehlerText: '',
      eingabeFehler: false,
      backendFehler: false,
      apiFehler: false,

      vertreter: {},
      vorherigeSeite: '',
      showUploadFehler: false,
      showAbfrage: false,
      titelAbfrage: 'Möchten Sie Ihre Nachricht wirklich verwerfen?',
      textAbfrage: 'Ihr bisheriger Entwurf und Ihre hochgeladenen Anhänge werden gelöscht.',
      dateien: [],
      reloadVSNR: '',
      reloadLaufendenr: '',
      reloadProduktkey: '',
      reloadKonversationsId: '',
    };
  },
  methods: {
    // Mixin Methoden:
    // behandleSeiteVerlassen()
    // schliesseAbfrage ()

    checkeEingabeFehler() {
      this.eingabeFehler = !this.betreffFehler && !this.nachrichtFehler;
    },

    closeAbfrage() {
      this.showAbfrage = false;
    },
    dateienAnnehmen(daten) {
      this.dateien = daten;
    },
    eingegeben() {
      return this.betreffEingabe || this.nachrichtEingabe || this.dateien.length > 0;
    },
    zurueck() {
      this.verlasseSeite = true;
      this.$router.back();
    },
    verwerfeUpload() {
      this.$refs.upload.verwerfen();
    },
    async setFormData() {
      const formData = new FormData();
      this.dateien.forEach((datei) => {
        const currentFile = new File([datei], encodeURI(datei.name.normalize('NFC')), {
          type: datei.type,
        });
        formData.append('file', currentFile);
      });
      formData.append('betreff', this.betreffEingabe);
      formData.append('inhalt', this.nachrichtEingabe);
      formData.append('vertreternummer', this.vertreter.vertreternummer);
      formData.append('hauptvertreternummer', this.vertreter.hauptvertreternummer);
      formData.append('mandantengruppenId', this.vertreter.mandantengruppenId);
      if (this.vertreter.vertreternummer === this.$store.state.vertreter.vertreternummer
        || this.nachrichtenverlauf) {
        await api.sendeNachricht(
          formData,
          Vue.$keycloak.token,
        );
      } else {
        formData.append('versicherungsscheinnummer', this.vsnr);
        formData.append('laufendenr', this.laufendenr);
        formData.append('produktkey', this.produktkey);
        await api.sendeVertragsvertreterNachricht(
          formData,
          Vue.$keycloak.token,
        );
      }
    },
    async sendeNachricht() {
      try {
        this.backendFehler = false;
        this.validateNachricht(true);
        this.validateBetreff(true);
        if (!this.nachrichtFehler && !this.betreffFehler) {
          this.sendenButtonDisabled = true;
          await this.setFormData();
          this.verlasseSeite = true;
          await this.$store.dispatch('ladePostfachstatus');
          if (this.vsnr) {
            await this.$router.push({
              name: this.$store.state.vorherigeURL,
              params: {
                vsnr: this.vsnr,
                bestaetigung: 'nachricht',
              },
            });
          } else if (this.reloadVSNR) {
            await this.$router.push({
              name: this.$store.state.vorherigeURL,
              params: {
                vsnr: this.reloadVSNR,
                bestaetigung: 'nachricht',
              },
            });
          } else if (this.konversationsId) {
            await this.$router.push({
              name: this.$store.state.vorherigeURL,
              params: {
                id: this.konversationsId,
                bestaetigung: 'nachricht',
              },
            });
          } else if (this.reloadKonversationsId) {
            await this.$router.push({
              name: this.$store.state.vorherigeURL,
              params: {
                id: this.reloadKonversationsId,
                bestaetigung: 'nachricht',
              },
            });
          } else {
            await this.$router.push({
              name: this.$store.state.vorherigeURL,
              params: {
                bestaetigung: 'nachricht',
              },
            });
          }
        }
      } catch (e) {
        this.sendenButtonDisabled = false;
        if (e.response.status === 429) {
          this.apiFehler = true;
        } else {
          this.backendFehler = true;
        }
        console.log(e);
      }
    },
    validateBetreff(istLeerCheck) {
      if (this.betreffEingabe === '' && istLeerCheck) {
        this.betreffFehlerText = 'Bitte geben Sie einen Betreff ein';
        this.betreffFehler = true;
      } else if (!this.checkISO(this.betreffEingabe)) {
        this.betreffFehlerText = 'Bitte Achten Sie auf Sonderzeichen';
        this.betreffFehler = true;
      } else {
        this.betreffFehler = false;
      }
    },

    validateNachricht(istleerCheck) {
      if (this.nachrichtEingabe === '' && istleerCheck) {
        this.nachrichtFehlerText = 'Bitte geben Sie Ihre Nachricht ein';
        this.nachrichtFehler = true;
      } else if (!this.checkISO(this.nachrichtEingabe)) {
        this.nachrichtFehlerText = 'Bitte achten Sie auf Sonderzeichen';
        this.nachrichtFehler = true;
      } else {
        this.nachrichtFehler = false;
      }
    },
    setZurueckFeld() {
      this.$root.$refs.app.setZurueckFeld(true);
    },
    async getVertreterFromVertrag(vsnr, laufendenr, produktkey) {
      await api.getEinzelVertrag(
        vsnr,
        laufendenr,
        produktkey,
        Vue.$keycloak.token,
      ).then((response) => response.data)
        .then((vertrag) => {
          this.vertreter = vertrag.bestandsvertreter;
        }).catch((error) => {
          console.log(`Vertreter: ${error}`);
        });
    },
    async getVertreterdatenByKonversationsId(konversationsId) {
      await api.getVertreterdatenByKonversationsId(konversationsId, Vue.$keycloak.token)
        .then((response) => response.data)
        .then((vertreter) => {
          this.vertreter = vertreter;
        }).catch((error) => {
          this.$store.commit('SET_FEHLER', true);
          console.log(`KonverationsvertreterStatus: ${error}`);
        });
    },
  },
  mounted() {
    this.$emit('view-geladen');
  },
  async beforeMount() {
    this.setZurueckFeld();
    if (!this.vsnr && !this.konversationsId) {
      this.reloadVSNR = this.$store.state.vsnr;
      this.reloadLaufendenr = this.$store.state.laufendenr;
      this.reloadProduktkey = this.$store.state.produktkey;
      this.reloadKonversationsId = this.$store.state.konversationsId;
      if (this.reloadVSNR) {
        await this.getVertreterFromVertrag(
          this.reloadVSNR,
          this.reloadLaufendenr,
          this.reloadProduktkey,
        );
      } else if (this.reloadKonversationsId) {
        await this.getVertreterdatenByKonversationsId(this.reloadKonversationsId);
      } else {
        this.vertreter = this.$store.state.vertreter;
      }
    } else if (this.vsnr) {
      this.$store.commit('SET_VSNR', this.vsnr);
      this.$store.commit('SET_LAUFENDENR', this.laufendenr);
      this.$store.commit('SET_PRODUKTKEY', this.produktkey);
      await this.getVertreterFromVertrag(this.vsnr, this.laufendenr, this.produktkey);
    } else {
      this.$store.commit('SET_KONVERSATION', this.konversationsId);
      await this.getVertreterdatenByKonversationsId(this.konversationsId);
    }
  },
  watch: {
    betreffEingabe() {
      this.validateBetreff(false);
      this.checkeEingabeFehler();
    },
    nachrichtEingabe() {
      this.validateNachricht(false);
      this.checkeEingabeFehler();
    },
  },
};
</script>

<style scoped>
#betreff-fehler-label {
  margin: -9px 0 17px 15px;
}

#nachricht {
  height: 160px;
  resize: none;
}

#senden-btn {
  margin-right: 15px;
}

#senden-btn:disabled {
  background: #0558F0;
  opacity: 0.5;
}

#pflicht-label {
  color: #6B7171;
  margin: 19px 0 30px 0;
}

.zaehler-label {
  display: flex;
  justify-content: flex-end;
  color: #6B7171;
}

#upload-hinweis {
  padding: 0;
}

.aufschrift {
  margin-bottom: 6px;
  white-space: nowrap;
}

.eingabe {
  padding: 9px 0 9px 9px;
}

@media (max-width: 576px) {

  #pflicht-label {
    margin-bottom: 20px;
  }

  #upload-hinweis {
    width: 100%;
  }
}

</style>
