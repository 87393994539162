<template>
  <!-- eslint-disable max-len -->
  <div>
    <div class="container main-content">
      <div class="row g-3">
        <div class="col-xl-8 mb-5">
          <h1 class="abstand-unten-30">E-Mail-Adresse ändern </h1>
          <div>
            <div class="card abstand-60 col-md-9 col-12 abstand-unten-30">
              <div class="card-body">
                <p class="copy_bold_16">Meine aktuelle E-Mail-Adresse</p>
                <p class="copy_16">
                  {{ this.$keycloak.idTokenParsed.email }}
                </p>
              </div>
            </div>
            <div class="row">
              <div class="col-md-9 col-12">
                <label class="copy_18 abstand-unten-30">
                  Klicken Sie auf weiter, um Ihre E-Mail-Adresse zu ändern.
                </label>
              </div>
            </div>
            <div>
              <div>
                <a class="kein-unterstreichen">
                  <button class="button-ivv white"
                          :class="{'button-vgh': mandant === 1,
                            'button-oesa': mandant === 3,
                            'button-oevo': mandant === 5}"
                          :disabled="weiterButtonDisabled"
                          @click="absenden">
                    Weiter
                  </button>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div  v-if="windowWidth>767" class="col-xl-4">
          <vertreter-info
            ueberschrift="Ich helfe Ihnen gerne bei der Änderung Ihrer Daten weiter."
            :social-media="true"
            :nachricht="true"
            :vertrLink="true"
            :termin="true"
            :vertreter="vertreter">
          </vertreter-info>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VertreterInfo from '@/components/VertreterInfo.vue';
import Vue from 'vue';
import { mapState } from 'vuex';
import mandant from '@/mandant';

export default {
  name: 'EmailAendern',
  mixins: [mandant],
  beforeMount() {
    this.$root.$refs.app.setZurueckFeld(true);
    this.erstelleEmailLink();
  },

  mounted() {
    this.$emit('view-geladen');
  },

  components: {
    VertreterInfo,
  },

  computed: {
    ...mapState(['partner', 'vertreter']),
  },

  data() {
    return {
      abgesendet: false,
      emailLink: '',
      weiterButtonDisabled: false,
    };
  },

  methods: {
    async absenden() {
      this.abgesendet = true;
      this.weiterButtonDisabled = true;
      this.erstelleEmailLink();
      window.location.href = this.emailLink;
    },
    erstelleEmailLink() {
      this.emailLink = Vue.$keycloak.createLoginUrl({ redirectUri: `${window.location.origin}/#`, action: 'CHANGE_EMAIL_ADDRESS' });
    },
  },
};
</script>

<style scoped>

.kein-unterstreichen:hover {
  text-decoration: none;
}

</style>
