<template>
  <!-- eslint-disable max-len -->
  <div id="karte" class="card">
    <div class="card-body">
      <h6 v-if="vsnr" id="vr-nr" class="copy_bold_12"
          :class="{'vgh-blau-sekundaer-color': mandant === 1,
          'oesa-gruen-sekundaer-color': mandant === 3,
          'oevo-blau-sekundaer-color': mandant === 5}">{{ vsnr }}</h6>
      <h3 v-if="produktname" id="produkt" class="card-title">{{ produktname }}</h3>
      <div id="merkmale">
        <h5 v-if="spezifischesMerkmal" id="merkmal" class="copy_16 card-info">{{ formatiereSpezifischesMerkmal(spezifischesMerkmal) }}</h5>
        <h5 v-if="jahresbeitrag" id="beitrag" class="copy_16 card-info">{{ formatiereGeldbetrag(jahresbeitrag) }} / jährlich</h5>
      </div>
      <pfeil-button class="stretched-link"
        :path="feature"
        :param="vsnr + (laufendenr !== null ? '/' + laufendenr : '') + (produktkey !== null ? '/' + produktkey : '')"
        :button-text="buttonText"
        :class="{'oesa-gruen-color': mandant === 3,
         'oevo-blau-color': mandant === 5}">
      </pfeil-button>
    </div>
  </div>
</template>

<script>
import mandant from '@/mandant';
import PfeilButton from '@/components/PfeilButton.vue';

export default {
  name: 'Karte',
  mixins: [mandant],
  components: { PfeilButton },
  props: [
    'feature',
    'vsnr',
    'laufendenr',
    'produktkey',
    'produktname',
    'spezifischesMerkmal',
    'kennzeichner',
    'jahresbeitrag',
    'zahlart',
    'buttonText',
  ],
  methods: {
    formatiereGeldbetrag(beitrag) {
      let geldbetrag = '';
      geldbetrag = Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(beitrag);
      return geldbetrag;
    },
    formatiereSpezifischesMerkmal(merkmal) {
      switch (this.vsnr.substring(2, 4)) {
        case '24': // KFZ
          return this.extrahiereKfzKennzeichen(merkmal);
        case '01': // HH Glas, Hausrat
        case '02': // Wohngebäude
        case '03': // Gebäude
        case '04': // Landwirtschaft
        case '05': // Gewerbe
        case '06': // Technische Versicherung
        case '07': // Hagel
        case '08': // Industrie
        case '09': // Transport
        case '13': // Sonstige Sachsparten
        case '22': // Haftpflicht
        case '25': // Rechtsschutz
        case '68': // Komposit
          return this.entferneLaenderkennzeichen(merkmal);
        default:
          return merkmal;
      }
    },
    extrahiereKfzKennzeichen(merkmal) {
      return merkmal.split('/')[0];
    },
    entferneLaenderkennzeichen(adresse) {
      if (adresse.substring(0, 2) === 'D ') {
        return adresse.substring(2);
      }
      if (adresse.substring(0, 3) === 'NL ') {
        return adresse.substring(3);
      }
      return adresse;
    },
  },
};
</script>

<style scoped>
#produkt {
  margin-bottom: 10px;
}

#merkmale {
  min-height: 68px;
}

#vr-nr {
  margin-bottom: 0;
}

.card {
  width: 350px;
  height: 100%;
  margin-left: 0;
  background-color: #EDF0F3;
  text-align: left;
  border-radius: 0;
  border-color: #EDF0F3;
  position: relative;
}

.card-body {
  padding: 30px 15px;
}

.card-info {
  margin-bottom: 0;
  padding: 0;
}

@media (max-width: 410px) {
  .card {
    width: 100%;
  }
}

</style>
