<template>
  <div>
    <sicherheitsabfrage
      v-show="showAbfrage"
      :titel="titelAbfrag"
      :text="textAbfrage"
      :zustimmungsBtn="ablehnungsBtnAbfrage"
      :ablehnungsBtn="zustimmungsBtnAbfrage"
      @abbruch="closeAbfrage"
      @zustimmung="leitenAufPostfachUm"
      @ablehnung="closeAbfrage">
    </sicherheitsabfrage>
    <sicherheitsabfrage
      v-if="verlasseSeiteAbfrage"
      typ="verlassen"
      @zustimmung="behandleSeiteVerlassen"
      @ablehnung="schliesseAbfrage"
      @abbruch="schliesseAbfrage">
    </sicherheitsabfrage>
    <div class="container main-content">
      <slot name="benachrichtigung"></slot>
      <div class="row">
        <div v-if="!loading" class="col-lg-8">
          <div v-if="!nachrichtSchreiben">
            <h2 class="abstand-unten-30" id="betreff" v-html=conversation[0].betreff />
          </div>
          <div v-if="nachrichtSchreiben" id="nachricht-schreiben" class="abstand-unten-60">
            <div class="row">
              <div class="col-12 abstand-unten-15">
                <h1 class="abstand-unten-30">Nachricht schreiben</h1>
                <h3 class="abstand-unten-30" v-html=conversation[0].betreff />
                <label class="aufschrift copy_bold_12">Meine Nachricht</label>
                <textarea
                  class="eingabe copy_16"
                  :class="{'fehler': nachrichtFehler && mandant === 1,
                        'fehler-oesa': nachrichtFehler && mandant === 3,
                        'vgh-eingabe': mandant === 1,
                        'oesa-eingabe': mandant === 3,
                        'oevo-eingabe': mandant === 5,
                        'fehler-oevo': nachrichtFehler && mandant === 5}"
                  v-model="nachrichtEingabe"
                  maxlength="1200"
                  id="nachricht"
                ></textarea>
                <label
                  v-if="nachrichtFehler"
                  class="fehler-text copy_bold_12">
                  {{ nachrichtFehlerText }}
                </label>
              </div>
              <div class="col-12">
                <div class="col-12" id="upload-hinweis">
                  <hinweis label="Anhang" right="0px">
                    Zulässige Dateiformate sind PDF, JPG, JPEG und PNG. <br>
                    Die maximale Uploadgröße ist 12 MB.
                  </hinweis>
                </div>
                <upload
                  v-on:dateienSenden="dateienAnnehmen"
                  ref="upload"
                  upload-bezeichnung="Anhang"
                  @dateiGeloescht="showUploadFehler = false"
                  columnSize="col-12"
                  id="upload"
                >
                </upload>
                <label
                  v-if="showUploadFehler"
                  class="fehler-text copy_bold_12">
                  Bitte versuchen Sie andere Dateien hochzuladen.
                </label>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <input type="button"
                       id="senden-btn"
                       class="button-ivv white"
                       :class="{'button-vgh': mandant === 1,
                          'button-oesa': mandant === 3,
                          'button-oevo': mandant === 5}"
                       value="Senden"
                       :disabled="sendenButtonDisabled"
                       @click="sendeNachricht"
                >
                <input type="button"
                       id="verwerfen-btn"
                       class="button-ivv"
                       value="Verwerfen"
                       @click="showAbfrage = true"
                >
              </div>
            </div>
            <backend-fehler :api-fehler="apiFehler" :backend-fehler="backendFehler"/>
          </div>
          <div v-if="!nachrichtSchreiben && conversation[0].typ != 'Kundendokument'"
               class="abstand-unten-30">
            <input v-if="istAntwortButtonErlaubt()"
                   type="button"
                   id="antworten-btn"
                   class="button-ivv white"
                   :class="{'button-vgh': mandant === 1,
                       'button-oesa': mandant === 3,
                       'button-oevo': mandant === 5}"
                   :disabled="zugriffsStatus !== 'eigeneOrga'"
                   value="+ Antworten"
                   @click="showNachrichtScreiben"
            >
          </div>
          <div v-for="nachricht in conversation" :key="nachricht.id" class="mb-4 row">
            <div class="col-12">
              <div class="nachrichten-blase"
                   :class="{ 'partnernachricht': nachricht.absender === nachricht.emailAdresse,
                   'nachricht-vgh': mandant === 1,
                   'nachricht-oesa': mandant === 3,
                   'nachricht-oevo': mandant === 5}">
                <div class="nachrichten-content">
                  <p class="nachrichten-label copy_12 text-right font-weight-bold">
                    {{nachricht.absenderName}} | {{ trimDatum(nachricht.erstellungsDatum) }}
                  </p>
                  <vue-markdown class="copy_16" :anchor-attributes="anchorAttrs">
                    {{nachricht.text}}
                  </vue-markdown>
                  <div v-if="nachricht.uploadList.length > 0">
                    <div v-for="(datei, index) in nachricht.uploadList"
                         :key="index" class="anhang-nachricht copy_16">
                      <div class="zeiger"
                           @click="downloadAnhang(datei.name, datei.anzeigename)"
                           v-if="datei.available">
                        <em class="fa-solid fa-paperclip"></em>
                        <label class="zeiger anhang-label" v-if="datei.groesse < 1024">
                          {{datei.anzeigename}}
                          ({{kbZuFixedKb(datei.groesse)}}KB)
                        </label>
                        <label class="zeiger" v-else>{{datei.anzeigename}}
                          ({{kbZuMb(datei.groesse)}}MB)</label>
                      </div>
                      <div
                           v-else>
                        <em class="fa-solid fa-circle-notch fa-spin"></em>
                        <label class="anhang-label">
                          {{datei.anzeigename}}
                          <em>(Wird noch verarbeitet)</em>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="!loading && conversation[0].typ !== 'Kundendokument'
         && conversation[0].typ !== 'Benachrichtigung'" class="col-xl-4 col-lg-4">
          <vertreter-info
            ueberschrift="Sie haben Fragen? Wir unterstützen Sie gerne."
            :mail-adresse="true"
            :nachricht="true"
            :termin="true"
            :vertrLink="vertreterLink"
            :social-media="true"
            :vertreter="konversationsPartner"
            :konversations-id="konversationsId"
            :nachrichtenverlauf="true"
          >
          </vertreter-info>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VertreterInfo from '@/components/VertreterInfo.vue';
import Sicherheitsabfrage from '@/components/Sicherheitsabfrage.vue';
import Upload from '@/components/Upload.vue';
import bestaetigungUtil from '@/bestaetigungUtil';
import sicherheitsabfrage from '@/sicherheitsabfrage';
import { mapState } from 'vuex';
import isoUtil from '@/isoUtil';
import api from '@/backend-api';
import mandant from '@/mandant';
import BackendFehler from '@/components/BackendFehler.vue';
import Hinweis from '@/components/Hinweis.vue';
import Vue from 'vue';
import VueMarkdown from 'vue-markdown';
import downloadUtil from '@/downloadUtil';

export default {
  name: 'Nachrichtenverlauf',
  mixins: [bestaetigungUtil, sicherheitsabfrage, isoUtil, mandant, downloadUtil],
  props: {
    id: {
      type: String,
      required: false,
    },
    bestaetigung: String,
  },
  components: {
    VertreterInfo,
    Sicherheitsabfrage,
    Upload,
    BackendFehler,
    Hinweis,
    VueMarkdown,
  },

  data() {
    return {
      anchorAttrs: {
        target: '_blank',
        rel: 'noopener noreferrer',
      },
      nachrichtSchreiben: false,
      nachrichtEingabe: '',
      nachrichtFehler: false,
      nachrichtFehlerText: '',
      eingabeFehler: {},
      apiFehler: false,
      backendFehler: false,
      dateien: [],
      showUploadFehler: false,
      showAbfrage: false,
      titelAbfrag: 'Möchten Sie Ihre Nachricht wirklich verwerfen?',
      textAbfrage: 'Ihr bisheriger Entwurf und Ihre hochgeladenen Anhänge werden gelöscht.',
      zustimmungsBtnAbfrage: 'Bearbeitung fortsetzen',
      ablehnungsBtnAbfrage: 'Verwerfen',
      conversation: [],
      konversationsPartner: {},
      loading: true,
      fallbackId: '',
      anzahlDokumente: 0,
      sendenButtonDisabled: false,
    };
  },
  computed: {
    ...mapState(['vertreter', 'zugriffsStatus']),
    vertreterLink() {
      return this.konversationsPartner.email === this.vertreter.email;
    },
    konversationsId() {
      if (this.id) {
        return this.id;
      }
      return this.fallbackId;
    },
  },
  methods: {
    showNachrichtScreiben() {
      this.nachrichtSchreiben = true;
    },
    trimDatum(datum) {
      return datum.substring(0, 10);
    },
    istAntwortButtonErlaubt() {
      return !(this.conversation[0].typ === 'Schadenmeldung'
        || this.conversation[0].typ === 'Dokumenteinreichung'
        || this.conversation[0].typ === 'Terminvereinbarung'
        || this.conversation[0].typ === 'Kundendokument');
    },
    checkeEingabeFehler() {
      if (!this.nachrichtFehler) {
        this.eingabeFehler = true;
      } else {
        this.eingabeFehler = false;
      }
    },
    closeAbfrage() {
      this.showAbfrage = false;
    },
    leitenAufPostfachUm() {
      this.nachrichtEingabe = '';
      this.$router.push({
        name: 'Postfach',
      });
    },
    dateienAnnehmen(daten) {
      this.dateien = daten;
    },
    eingegeben() {
      return this.nachrichtEingabe || this.dateien.length > 0;
    },
    emptyForm() {
      this.showUploadFehler = false;
      this.nachrichtEingabe = '';
      this.dateien = [];
      this.verwerfeUpload();
      this.closeAbfrage();
      this.nachrichtSchreiben = false;
    },
    async getKonversation() {
      await api.getNachrichtenByKonversationsId(
        this.konversationsId,
        Vue.$keycloak.token,
      ).then((response) => response.data)
        .then((konversationsInfo) => {
          this.conversation = konversationsInfo.nachrichten;
          this.anzahlDokumente = konversationsInfo.dokumenteAnzahl;
        }).catch((error) => {
          this.$store.commit('SET_FEHLER', true);
          console.log(`KonverationStatus: ${error}`);
        });
    },
    kbZuFixedKb(kb) {
      return kb.toFixed(1);
    },
    kbZuMb(kb) {
      const mb = kb / 1024;
      return mb.toFixed(1);
    },
    async sendeNachricht() {
      try {
        this.validateNachricht(true);
        if (!this.nachrichtFehler) {
          this.sendenButtonDisabled = true;
          let empfaenger = {};
          if (this.conversation[0].emailAdresse !== this.conversation[0].empfaenger) {
            empfaenger = this.conversation[0].empfaenger;
          } else {
            empfaenger = this.conversation[0].absender;
          }
          const formData = new FormData();
          this.dateien.forEach((element) => {
            const currentFile = new File([element], encodeURI(element.name.normalize('NFC')), {
              type: element.type,
            });
            formData.append('file', currentFile);
          });
          formData.append('betreff', this.conversation[0].betreff);
          formData.append('konversationid', this.conversation[0].konversationsId);
          formData.append('inhalt', this.nachrichtEingabe);
          formData.append('empfaenger', empfaenger);
          formData.append('typ', this.conversation[0].typ);
          formData.append('vertreternummer', this.konversationsPartner.vertreternummer);
          formData.append('hauptvertreternummer', this.konversationsPartner.hauptvertreternummer);
          await api.sendeNachrichtzuKonversation(formData, Vue.$keycloak.token);
          await this.getKonversation();
          this.emptyForm();
          await this.$store.dispatch('ladePostfachstatus');
          this.sendenButtonDisabled = false;
          this.$root.$refs.app.setBestaetigung('nachricht');
        }
      } catch (e) {
        this.sendenButtonDisabled = false;
        if (e.response.status === 429) {
          this.apiFehler = true;
        } else {
          this.backendFehler = true;
        }
        console.log(e);
      }
    },
    validateNachricht(istLeerCheck) {
      if (this.nachrichtEingabe === '' && istLeerCheck) {
        this.nachrichtFehlerText = 'Bitte geben Sie Ihre Nachricht ein';
        this.nachrichtFehler = true;
      } else if (!this.checkISO(this.nachrichtEingabe)) {
        this.nachrichtFehlerText = 'Bitte achten Sie auf Sonderzeichen';
        this.nachrichtFehler = true;
      } else if (this.conversation[0].typ === 'Kundendokument') {
        this.nachrichtFehlerText = 'Antwort auf Dokumente nicht möglich';
        this.nachrichtFehler = true;
      } else {
        this.nachrichtFehler = false;
      }
    },
    verwerfeUpload() {
      this.$refs.upload.verwerfen();
      this.nachrichtSchreiben = false;
    },
    setZurueckFeld() {
      this.$root.$refs.app.setZurueckFeld(true);
    },
  },
  async beforeMount() {
    this.setZurueckFeld();
    if (this.id) {
      this.$store.commit('SET_KONVERSATION', this.id);
    } else {
      this.fallbackId = this.$store.state.konversationsId;
    }
    await this.getKonversation();
    await api.getVertreterdatenByKonversationsId(this.konversationsId, Vue.$keycloak.token)
      .then((response) => response.data)
      .then((vertreter) => {
        this.konversationsPartner = vertreter;
      }).catch((error) => {
        this.$store.commit('SET_FEHLER', true);
        console.log(`KonverationsvertreterStatus: ${error}`);
      });
    this.loading = false;
  },
  async mounted() {
    const setGelesenPromise = await api.setNachrichtenZuKonversationsIdGelesen(
      this.konversationsId,
      Vue.$keycloak.token,
    ).catch((error) => {
      this.$store.commit('SET_FEHLER', true);
      console.log(`Konversation gelesen: ${error}`);
    });
    const postfachstatusPromise = this.$store.dispatch('ladePostfachstatus');
    Promise.all([setGelesenPromise, postfachstatusPromise]).finally(() => {
      this.$emit('view-geladen');
    });
  },
  watch: {
    nachrichtEingabe() {
      this.validateNachricht(false);
      this.checkeEingabeFehler();
    },
  },
};
</script>

<style scoped>
#betreff {
  overflow-wrap: break-word;
}

#nachricht {
  height: 160px;
  resize: none;
}

#senden-btn {
  margin-right: 15px;
}

#upload-hinweis{
  padding: 0;
}

.aufschrift {
  margin-bottom: 6px;
  white-space: nowrap;
}

.anhang-nachricht {
  margin-bottom: 4px;
  color: var(--text-color);
}

.eingabe {
  padding: 9px 0 9px 9px;
}

.fehler:focus {
  outline: none !important;
}

.nachrichten-blase {
  width: 100%;
  background-color: #EDF0F3;
  border: 1px solid transparent;
}

.nachrichten-content {
  padding: 20px;
  overflow-wrap: break-word;
  hyphens: manual;
  white-space:pre-wrap;
}

.partnernachricht {
  background-color: #FFFFFF;
  box-shadow: 0 .2rem .4rem rgba(0,0,0,.15);
}

.partnernachricht.nachricht-vgh .nachrichten-label,
:not(.partnernachricht).nachricht-vgh .anhang-nachricht{
  color: var(--blau);
}

.partnernachricht.nachricht-oesa .nachrichten-label,
:not(.partnernachricht).nachricht-oesa .anhang-nachricht{
  color: #00935d;
}

.partnernachricht.nachricht-oevo .nachrichten-label,
:not(.partnernachricht).nachricht-oevo .anhang-nachricht{
  color: #004494;
}

.anhang-label {
  display: contents;
}

.zeiger {
  cursor: pointer;
}

@media (max-width: 576px) {
    #upload-hinweis {
    width: 100%;
  }
}
</style>
