var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('sicherheitsabfrage',{directives:[{name:"show",rawName:"v-show",value:(_vm.showAbfrage),expression:"showAbfrage"}],attrs:{"typ":"verwerfen","titel":_vm.titelAbfrage,"text":_vm.textAbfrage},on:{"zustimmung":_vm.zurueck,"abbruch":_vm.closeAbfrage,"ablehnung":_vm.closeAbfrage}}),(_vm.verlasseSeiteAbfrage)?_c('sicherheitsabfrage',{attrs:{"typ":"verlassen"},on:{"zustimmung":_vm.behandleSeiteVerlassen,"ablehnung":_vm.schliesseAbfrage,"abbruch":_vm.schliesseAbfrage}}):_vm._e(),_c('div',{staticClass:"container main-content"},[_vm._t("benachrichtigung"),_c('div',{staticClass:"row g-3"},[_c('div',{staticClass:"col-xl-8"},[_c('h1',{staticClass:"abstand-unten-30"},[_vm._v("Nachricht schreiben")]),_c('h2',{staticClass:"abstand-unten-30"},[_vm._v("Meine Nachricht an "+_vm._s(_vm.vertreter.name))]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 abstand-unten-17"},[_c('label',{staticClass:"aufschrift copy_bold_12",attrs:{"for":"betreff"}},[_vm._v("Betreff *")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.betreffEingabe),expression:"betreffEingabe"}],staticClass:"eingabe copy_16",class:{'fehler': _vm.betreffFehler && _vm.mandant === 1,
                      'fehler-oesa': _vm.betreffFehler && _vm.mandant === 3,
                      'vgh-eingabe': _vm.mandant === 1,
                      'oesa-eingabe': _vm.mandant === 3,
                      'oevo-eingabe': _vm.mandant === 5,
                      'fehler-oevo': _vm.betreffFehler && _vm.mandant === 5},attrs:{"maxlength":"120","id":"betreff"},domProps:{"value":(_vm.betreffEingabe)},on:{"input":function($event){if($event.target.composing){ return; }_vm.betreffEingabe=$event.target.value}}})]),(_vm.betreffFehler)?_c('label',{staticClass:"aufschrift fehler-text copy_bold_12",class:{'fehler-text-oesa': _vm.mandant === 3,
              'fehler-text-oevo': _vm.mandant === 5},attrs:{"id":"betreff-fehler-label"}},[_vm._v(" "+_vm._s(_vm.betreffFehlerText)+" ")]):_vm._e(),_c('div',{staticClass:"col-12 abstand-unten-15"},[_c('label',{staticClass:"aufschrift copy_bold_12"},[_vm._v("Nachricht *")]),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.nachrichtEingabe),expression:"nachrichtEingabe"}],staticClass:"eingabe copy_16",class:{'fehler': _vm.nachrichtFehler && _vm.mandant === 1,
                      'fehler-oesa': _vm.nachrichtFehler && _vm.mandant === 3,
                      'vgh-eingabe': _vm.mandant === 1,
                      'oesa-eingabe': _vm.mandant === 3,
                      'oevo-eingabe': _vm.mandant === 5,
                      'fehler-oevo': _vm.nachrichtFehler && _vm.mandant === 5},attrs:{"maxlength":"1200","id":"nachricht"},domProps:{"value":(_vm.nachrichtEingabe)},on:{"input":function($event){if($event.target.composing){ return; }_vm.nachrichtEingabe=$event.target.value}}}),_c('label',{staticClass:"zaehler-label copy_12"},[_vm._v(" "+_vm._s(_vm.nachrichtEingabe.length)+" / 1200 Zeichen ")]),(_vm.nachrichtFehler)?_c('label',{staticClass:"fehler-text copy_bold_12",class:{'fehler-text-oesa': _vm.mandant === 3,
                'fehler-text-oevo': _vm.mandant === 5}},[_vm._v(" "+_vm._s(_vm.nachrichtFehlerText)+" ")]):_vm._e()]),_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"col-12",attrs:{"id":"upload-hinweis"}},[_c('hinweis',{attrs:{"label":"Anhang","right":"0"}},[_vm._v(" Zulässige Dateiformate sind PDF, JPG, JPEG und PNG. "),_c('br'),_vm._v(" Die maximale Uploadgröße ist 12 MB. ")])],1),_c('upload',{ref:"upload",attrs:{"upload-bezeichnung":"Anhang","columnSize":"col-12","id":"upload"},on:{"dateienSenden":_vm.dateienAnnehmen,"dateiGeloescht":function($event){_vm.showUploadFehler = false}}}),(_vm.showUploadFehler)?_c('label',{staticClass:"fehler-text copy_bold_12",class:{'fehler-text-oesa': _vm.mandant === 3,
                'fehler-text-oevo': _vm.mandant === 5}},[_vm._v(" Bitte versuchen Sie andere Dateien hochzuladen. ")]):_vm._e()],1),_vm._m(0),_vm._m(1)])])]),_c('div',{staticClass:"row g-3 abstand-unten-15"},[_c('div',{staticClass:"col-12"},[_c('input',{staticClass:"button-ivv white",class:{'button-vgh': _vm.mandant === 1,
               'button-oesa': _vm.mandant === 3,
               'button-oevo': _vm.mandant === 5},attrs:{"type":"button","id":"senden-btn","value":"Senden","disabled":_vm.sendenButtonDisabled},on:{"click":_vm.sendeNachricht}}),_c('input',{staticClass:"button-ivv",attrs:{"type":"button","id":"verwerfen-btn","value":"Verwerfen"},on:{"click":function($event){_vm.showAbfrage = true}}})])]),_c('backend-fehler',{attrs:{"api-fehler":_vm.apiFehler,"backend-fehler":_vm.backendFehler}})],2)],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-12"},[_c('label',{staticClass:"copy_bold_12"},[_vm._v("Ihre Nachricht wird dem hier angezeigten Ansprechpartner zugestellt - dieser kann von dem für Ihre Vertrags- und Schadenbetreuung zuständigen Vertriebspartner abweichen.")]),_c('label',{staticClass:"copy_bold_12"},[_vm._v("Bitte beachten Sie daher den separaten Prozess zur Schadenmeldung - nur dieser stellt sicher, dass Ihre Schadenmeldung auch an den dafür zuständigen Vertriebspartner weitergeleitet wird.")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-12"},[_c('label',{staticClass:"copy_12",attrs:{"id":"pflicht-label"}},[_vm._v("Pflichtfeld*")])])}]

export { render, staticRenderFns }