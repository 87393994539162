<template>
  <div>
    <div class="row">
      <div v-if="windowWidth > 767" @dragover.prevent @drop.prevent
           class="abstand-unten-15" :class="columnSize">
        <div @drop="dropDatei" class="drop-feld"
             :class="{'drop-feld-vgh': mandant === 1,
             'drop-feld-oesa': mandant === 3,
             'drop-feld-oevo': mandant === 5}">
          <h3 class="copy_16 drop-text">{{ uploadBezeichnung }} hierhin ziehen oder</h3>
            <input
              type="file"
              id="datei-auswahl"
              multiple @change="dateiAuswaehlen"
              accept=".jpeg, .jpg, .png, .pdf"/>
            <input
              type="button"
              class="button-ivv upload-button"
              value="Dateien für Upload auswählen"
              @click="clickAuswahl">
        </div>
      </div>
      <label v-if="fehlerUpload" class="copy_12 col-12 fehler-text"
             :class="{'fehler-text-oesa': mandant === 3,
             'fehler-text-oevo': mandant === 5}">{{fehlerText}}</label>
      <div class="col-12">
        <slot></slot>
      </div>
      <div v-if="dateien.length" class="col-12">
        <ul class="liste row" v-for="(datei, index) in dateien" :key="index">
         <li class="copy_16 listen-element"
             :class="{'vgh-blau-color': mandant === 1,
              'oesa-gruen-color': mandant === 3,
              'oevo-blau-color': mandant === 5}">
            <em class="fa-solid fa-paperclip"
               :class="{'vgh-blau-color': mandant === 1,
                'oesa-gruen-color': mandant === 3,
                'oevo-blau-color': mandant === 5}"></em>
            <label id="datei-name copy_16">{{datei.name.normalize("NFC")}}&nbsp;</label>
            <label v-if="datei.size < 1048576">({{bZuKb(datei.size)}}KB)</label>
           <label v-else>({{bZuMb(datei.size)}}MB)</label>
           <button
             @click.prevent="dateiLoeschen(dateien, index)"
             class="x-button">
             <em class="fa-solid fa-xmark"></em>
           </button>
          </li>
        </ul>
      </div>
      <div v-if="windowWidth < 768" class="col-12">
        <input
          type="file" id="anhang"
          multiple @change="dateiAuswaehlen"
          accept=".jpeg, .jpg, .png, .pdf"/>
        <button
          class="anhang-hinzu"
          :class="{'vgh-blau-color': mandant === 1,
            'oesa-gruen-color': mandant === 3,
            'oevo-blau-color': mandant === 5}"
          @click="clickAnhang">
          <em class="fa-solid fa-plus"
             :class="{'vgh-blau-color': mandant === 1,
              'oesa-gruen-color': mandant === 3,
              'oevo-blau-color': mandant === 5}"></em>
          <a class="copy_16 anhang-text"
             :class="{'vgh-blau-color': mandant === 1,
              'oesa-gruen-color': mandant === 3,
              'oevo-blau-color': mandant === 5}"
          >{{ uploadBezeichnung }} hinzufügen</a>
        </button>
      </div>
    </div>
  </div>

</template>

<script>
import mandant from '@/mandant';

export default {
  name: 'Upload',
  mixins: [mandant],
  data() {
    return {
      datei: [],
      dateien: [],
      gesamtgroesse: 0,
      index: 0,
      fehlerUpload: false,
      fehlerText: '',
      maxAnzahlDateien: 10,
      minDateiGroesseInKb: 5,
      maxDateiGroesseInMb: 12,
      minNameLaenge: 1,
      maxNameLaenge: 255,
    };
  },

  props: ['columnSize', 'uploadBezeichnung'],

  computed: {
    aktuelleGesamtgroesse() {
      let groesse = 0;
      this.dateien.forEach(
        (datei) => {
          groesse += datei.size;
        },
      );
      return groesse;
    },
    anzahlDateien() {
      return this.dateien.length;
    },
  },

  methods: {
    clickAuswahl() {
      document.getElementById('datei-auswahl').click();
    },
    clickAnhang() {
      document.getElementById('anhang').click();
    },
    dateiAuswaehlen(e) {
      this.datei = [];
      this.buttonDeaktivieren();
      this.datei.push(e.target.files);
      this.dateienAnzeigen();
    },
    dateiLoeschen(dateien, index) {
      dateien.splice(index, 1);
      this.fehlerUpload = false;
      this.$emit('dateiGeloescht');
    },
    checkNameAndAddFile(aktuelleDatei) {
      if (this.ueberpruefeDateiName(aktuelleDatei)) {
        this.dateien.push(aktuelleDatei);
        this.index += 1;
      }
    },
    async dateiUeberpruefenUndHinzufuegen(index) {
      if (this.formatUeberpruefung(index)) {
        const aktuelleDatei = this.datei[0][index];
        // eslint-disable-next-line no-await-in-loop
        if (await this.ueberpruefePasswortgeschuetzt(aktuelleDatei)) {
          if (aktuelleDatei.size > (this.minDateiGroesseInKb * 1024)
            && aktuelleDatei.size < (this.maxDateiGroesseInMb * 1048576)) {
            this.checkNameAndAddFile(aktuelleDatei);
          } else {
            this.fehlerbericht('groesse');
          }
        }
      }
    },
    async dateiHinzufuegen() {
      for (let i = 0; i < this.datei[0].length; i += 1) {
        if (this.anzahlDateien < this.maxAnzahlDateien) {
          // eslint-disable-next-line no-await-in-loop
          await this.dateiUeberpruefenUndHinzufuegen(i);
        } else {
          this.fehlerbericht('anzahl');
        }
      }
    },
    async dateienAnzeigen() {
      this.fehlerUpload = false;
      let currentGroesse = 0;
      Array.from(this.datei[0]).forEach((element) => {
        currentGroesse += element.size;
      });
      console.log(`this.aktuelleGesamtgroesse = ${this.aktuelleGesamtgroesse}`);
      console.log(`currentGroesse = ${currentGroesse}`);
      if (this.aktuelleGesamtgroesse + currentGroesse < this.maxDateiGroesseInMb * 1048576) {
        await this.dateiHinzufuegen();
      } else {
        this.fehlerbericht('gesamtgroesse');
      }
      this.dateienSenden();
    },
    dateienSenden() {
      this.buttonAktivieren();
      this.$emit('dateienSenden', this.dateien);
    },
    buttonAktivieren() {
      const buttonSenden = document.getElementById('senden-btn');
      const buttonWeiter = document.getElementById('weiter-btn');
      if (buttonSenden == null) {
        buttonWeiter.disabled = false;
      } else if (buttonWeiter == null) {
        buttonSenden.disabled = false;
      }
    },
    buttonDeaktivieren() {
      const buttonSenden = document.getElementById('senden-btn');
      const buttonWeiter = document.getElementById('weiter-btn');
      if (buttonSenden == null) {
        buttonWeiter.disabled = true;
      } else if (buttonWeiter == null) {
        buttonSenden.disabled = true;
      }
    },
    dropDatei(e) {
      this.datei = [];
      this.datei.push(e.dataTransfer.files);
      this.dateienAnzeigen();
    },
    fehlerbericht(art) {
      this.fehlerUpload = true;
      if (art === 'anzahl') {
        this.fehlerText = `Es dürfen nicht mehr als ${this.maxAnzahlDateien} Dateien geladen werden.`;
      } else if (art === 'typ') {
        this.fehlerText = 'Es werden nur Dateien vom Typ PDF (unverschlüsselt), PNG, JPG oder JPEG akzeptiert.';
      } else if (art === 'groesse') {
        this.fehlerText = `Die Datei darf nicht kleiner als ${this.minDateiGroesseInKb}KB oder größer als ${this.maxDateiGroesseInMb}MB sein.`;
      } else if (art === 'sonderzeichen') {
        this.fehlerText = 'Der Dateiname darf keine Sonderzeichen enthalten, die nach ISO-8859-15 nicht erlaubt sind.';
      } else if (art === 'laenge') {
        this.fehlerText = `Der Dateiname darf mindestens ${this.minNameLaenge} und höchstens ${this.maxNameLaenge} Zeichen lang sein.`;
      } else if (art === 'gesamtgroesse') {
        this.fehlerText = `Die Gesamtgröße aller Dateien darf nicht größer als ${this.maxDateiGroesseInMb}MB sein.`;
      }
    },

    formatUeberpruefung(index) {
      if (this.datei[0][index].type === 'image/jpeg') {
        return true;
      }
      if (this.datei[0][index].type === 'image/jpg') {
        return true;
      }
      if (this.datei[0][index].type === 'image/png') {
        return true;
      }
      if (this.datei[0][index].type === 'application/pdf') {
        return true;
      }
      this.fehlerbericht('typ');
      return false;
    },
    bZuKb(b) {
      const kb = b / 1024;
      return kb.toFixed(1);
    },
    bZuMb(b) {
      const mb = b / 1048576;
      return mb.toFixed(1);
    },
    verwerfen() {
      this.datei = [];
      this.dateien = [];
      this.index = 0;
      this.fehlerUpload = false;
      this.fehlerText = '';
    },
    keineDateien(fehlermeldung) {
      this.fehlerUpload = true;
      this.fehlerText = fehlermeldung;
    },
    ueberpruefeDateiName(datei) {
      const tempdatei = datei.name.split('.').slice(0, -1).join('.');
      if (tempdatei.length < this.minNameLaenge
        || tempdatei.length > this.maxNameLaenge) {
        this.fehlerbericht('laenge');
        return false;
      }
      const tempdateiNormalized = tempdatei.normalize('NFC');
      // ISO-8859-15-Standardprüfung
      const regex = /[^\x20-\x7E\xA0-\xA3\xA5\xA7\xA9-\xB3\xB5-\xB7\xB9-\xBB\xBF-\xFF\u20AC\u0160\u0161\u017D\u017E\u0152\u0153\u0178\u005C]/; // NOSONAR
      if (tempdateiNormalized.match(regex)) {
        this.fehlerbericht('sonderzeichen');
        return false;
      }
      return true;
    },
    async leseDateiAus(datei) {
      return new Promise((resolve) => {
        const reader = new FileReader();
        reader.onload = function auslesen() {
          resolve(reader.result);
        };
        reader.readAsText(datei);
      });
    },
    async ueberpruefePasswortgeschuetzt(datei) {
      const dateiInfo = await this.leseDateiAus(datei);
      if (dateiInfo.includes('/Encrypt')) {
        this.fehlerbericht('typ');
        return false;
      }
      return true;
    },
  },
};
</script>

<style scoped>

#anhang, #datei-auswahl {
  display: none;
}

#datei-name {
  margin-bottom: 0;
  max-width: 420px;
  overflow: hidden;
  text-overflow: ellipsis;
  transform: translateY(7px);
}

.anhang-hinzu {
  padding-left: 0;
  background-color: transparent;
  border-color: transparent;
  color: #484a4f;
}

.drop-feld {
  border: 2px dashed #484a4f;
  box-sizing: border-box;
  border-radius: 2px;
  width: auto;
  min-height: 124px;
  height: auto;
}

.drop-feld-vgh {
  border: 2px dashed #7E98C4 !important;
}

.drop-feld-oevo {
  border: 2px dashed #b2c9e2 !important;
}

.drop-feld-oesa {
  border: 2px dashed #78bfa4 !important;
}

.drop-text {
  margin-top: 30px;
  font-size: 16px;
  text-align: center;
}

.fa-paperclip {
  color: #484a4f;
  font-size: 24px;
  margin-right: 7px;
  transform: translateY(5px);
}

.fa-plus {
  color: #484a4f;
  margin-right: 9px;
}

.liste {
  margin-top: -3px;
  margin-bottom: 0;
  padding-left: 15px;
  list-style-type:none;
}

.listen-element {
  color: #484a4f;
  max-width: 500px;
  padding: 0;
  white-space: nowrap;
}

.upload-button {
  border-color: #EDF0F3;
  border-radius: 2px;
  display: block;
  font-size: 12px;
  height: 25px;
  margin : auto;
  padding: 5px 10px;
  width: 181px;
}

.x-button {
  background-color: #EDF0F3;
  border: 0;
  border-radius: 50%;
  height: 25px;
  margin-left: 10px;
  outline: none;
  transform: translateY(2px);
  width: 25px;
  z-index: 1;
}

/* Mobile View */
@media (max-width: 576px) {
  #datei-name {
    max-width: 100%;
  }

  .anhang-hinzu {
    margin-top: 15px;
  }

  .fa-paperclip {
    font-size: 16px;
    margin-left: -3px;
    transform: translateY(1px);
  }
  .fehler-text {
    margin: 10px 0;
  }
  .listen-element {
    margin-left: 5px;
    max-width: calc(92% - 100px);
  }
  .x-button {
    margin-left: 10px;
  }
}

@media (min-width: 577px) and (max-width: 767px) {
  #datei-name {
    max-width: 400px;
  }
  .fa-paperclip {
    font-size: 16px;
    transform: translateY(1px);
  }
  .liste {
    margin-top: 5px;
  }
  .x-button {
    margin-left: 10px;
  }
}

</style>
