import { render, staticRenderFns } from "./Nachrichtenbalke.vue?vue&type=template&id=62b586ae&scoped=true&"
import script from "./Nachrichtenbalke.vue?vue&type=script&lang=js&"
export * from "./Nachrichtenbalke.vue?vue&type=script&lang=js&"
import style0 from "./Nachrichtenbalke.vue?vue&type=style&index=0&id=62b586ae&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "62b586ae",
  null
  
)

export default component.exports