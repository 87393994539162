<template>
  <div>
    <div class="d-flex justify-content-start suche"
         :style="windowWidth <= 576 ? 'margin-top: ' + mobileMargin + 'em' :
         windowWidth <= 992 ? 'margin-top: ' + tabletMargin + 'em' : ''">
      <input type="search"
             class="copy_16"
             :class="{'vgh-search': mandant === 1,
                        'oesa-search': mandant === 3,
                        'oevo-search': mandant === 5}"
             id="suche"
             ref="suche"
             placeholder="Suchen"
             maxlength="40"
             v-model="suchtextPreview"
             v-on:keyup.enter="sucheAnwenden"
             v-on:keyup.delete="behandleDelete"
             :style="{width: windowWidth > 576 ? inputWidth : 'auto'}"/>
      <button class="cancelSearchBtn d-block d-md-none"
              v-if="this.suchtextPreview.length" @click="suchtextVerwerfen">
        <em id="cancelSearchIcon" class="fa-regular fa-xmark-circle"/>
      </button>
      <button id="sucheButton"
              class="buttons grayText-Background"
              @click="sucheAnwenden"
              :disabled="!this.suchtextPreview.length">
        <em id="sucheIcon" class="fa-solid fa-magnifying-glass buttons-icon"/>
      </button>
      <button v-if="showFilter" id="filterButton"
              class="buttons"
              :class="{'button-vgh': mandant === 1 && filterPreview.length,
                             'button-oesa': mandant === 3 && filterPreview.length,
                             'button-oevo': mandant === 5 && filterPreview.length,
                             'grayText-Background': !filterPreview.length}"
              @click="filterMenuToggle">
        <em id="filterIcon" class="fa-solid fa-filter buttons-icon"/>
      </button>
    </div>
    <div v-if="showFilter && filterMenuActive"
         id="filterMenu"
         ref="filterMenu"
         v-on-clickaway="onClickAway"
         class="col-12 filterMenu">
      <b-form-checkbox class="filterMenu-Checkbox"
                       v-for="(filterItem, index) in filterItems"
                       :key="index"
                       :value="filterItem"
                       v-model="filterPreview"
      >{{ filterItem }}
      </b-form-checkbox>
      <div class="filterMenu-ButtonDiv">
        <button @click="filterAnwenden"
                class="button-ivv white filterMenu-Button"
                :class="{'button-vgh': mandant === 1,
                             'button-oesa': mandant === 3,
                             'button-oevo': mandant === 5}"
        >Anwenden
        </button>
        <button @click="resetFilter"
                class="button-ivv filterMenu-Button"
        >Abbrechen
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import mandant from '@/mandant';
import { mixin as clickaway } from 'vue-clickaway';

export default {
  name: 'Suche',
  mixins: [mandant, clickaway],

  data() {
    return {
      suchtext: '',
      suchtextPreview: '',
      filterMenuActive: false,
      filter: [],
      filterPreview: [],
    };
  },

  props: {
    showFilter: {
      type: Boolean,
      default: false,
    },
    filterItems: {
      type: Array,
      default: () => [],
    },
    mobileMargin: {
      type: String,
      default: '-5',
    },
    tabletMargin: {
      type: String,
      default: '-8',
    },
    inputWidth: {
      type: String,
      default: 'auto',
    },
  },
  methods: {
    sucheAnwenden(event) {
      event.target.blur();
      if (this.suchtext !== this.suchtextPreview) {
        this.suchtext = this.suchtextPreview;
        this.sucheUndFilterAnwenden();
      }
    },
    behandleDelete() {
      if (!this.suchtextPreview.length && this.suchtext.length) {
        this.suchtext = '';
        this.sucheUndFilterAnwenden();
      }
    },
    suchtextVerwerfen() {
      this.suchtextPreview = '';
      if (this.suchtext.length) {
        this.suchtext = '';
        this.sucheUndFilterAnwenden();
      }
      this.$refs.suche.focus();
    },
    filterMenuToggle() {
      this.filterMenuActive = !this.filterMenuActive;
    },
    filterAnwenden() {
      this.filter = this.filterPreview;
      this.filterMenuActive = false;
      this.sucheUndFilterAnwenden();
    },
    resetFilter() {
      this.filterPreview = [];
      if (this.filter.length) {
        this.filter = [];
        this.sucheUndFilterAnwenden();
      }
      this.filterMenuActive = false;
    },
    onClickAway() {
      this.filterPreview = this.filter;
      this.filterMenuActive = false;
    },
    sucheUndFilterAnwenden() {
      this.$emit('selected', this.suchtext, this.filter);
    },
  },
  updated() {
    this.$emit('filterMenuToggled', this.$refs.filterMenu);
  },
};
</script>

<style scoped>
.vgh-search:focus-within {
  outline-offset: -2px;
  outline: 2px solid #0558f0;
}

.oesa-search:focus-within {
  outline-offset: -2px;
  outline: 2px solid #78bfa4 !important;
}

.oevo-search:focus-within {
  outline-offset: -2px;
  outline: 2px solid #004494;
}

.cancelSearchBtn {
  border: none;
  background: none;
  width: 30px;
  height: 30px;
  margin-top: 5px;
  margin-left: -30px;
  padding: 4px;
  color: GrayText;
}

.buttons {
  border: 2px transparent;
  width: 30px;
  height: 30px;
  margin-top: 5px;
  margin-left: 2px;
  padding: 4px;
  color: white;
  background-image: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.01) 0,
    rgba(0, 0, 0, 0.05)
  );
}

.buttons:hover {
  box-shadow: inset 0 0 0 4rem rgb(0 0 0 / 25%);
  transition-duration: 0.08s;
  transition-timing-function: ease-out;
  transition-property: box-shadow, background-color;
}

.buttons-icon {
  width: 22px;
  height: 22px;
}

.grayText-Background {
  background-color: GrayText;
}

input[type="search"] {
  border: 2px solid #999;
  font-size: 1.2em;
  padding-left: 0.5em;
  padding-right: 1.6em;
  margin-top: 5px;
  height: 30px
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  -webkit-appearance:none;
}

.filterMenu {
  display: flex; flex-direction: column;
  position: absolute; z-index: 1;
  left: 15px;
  padding-top: 5px;
  width: fit-content;
  background-color: white;
}

.filterMenu-Checkbox {
  padding-right: 1.5rem;
  display:flex;
}

.filterMenu-ButtonDiv {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin: 5px;
}

.filterMenu-Button {
  margin: 5px;
  height: 24px;
}

@media (max-width: 992px) {
  .suche {
    position: absolute;
    top: 0;
  }
}
</style>
