<template>
  <!-- eslint-disable max-len -->
  <div>
    <sicherheitsabfrage
      v-show="showAbfrage"
      typ="verwerfen"
      :titel="titelAbfrage"
      :text="textAbfrage"
      @zustimmung="verwerfenZurueck"
      @abbruch="closeAbfrage"
      @ablehnung="closeAbfrage">
    </sicherheitsabfrage>
    <sicherheitsabfrage
      v-if="verlasseSeiteAbfrage"
      typ="verlassen"
      @zustimmung="behandleSeiteVerlassen"
      @ablehnung="schliesseAbfrage"
      @abbruch="schliesseAbfrage">
    </sicherheitsabfrage>
    <div v-if="zusammenfassung" class="card card-grau" id="rechteck">
      <div class="container">
        <a class="copy_16 pfeil-btn text-link"
           :class="{'text-link-vgh': mandant === 1,
              'text-link-oesa': mandant === 3,
              'text-link-oevo': mandant === 5}"
           @click="zurueck">
          <em class="fa-solid fa-chevron-left"></em>Zurück
        </a>
      </div>
    </div>
    <div class="container main-content">
      <slot name="benachrichtigung"></slot>
      <div class="row g-3">
        <div class="col-xl-8 mb-5">
          <h1 class="abstand-unten-30">Persönliche Daten ändern </h1>
          <div v-show="!zusammenfassung">
            <div class="card card-grau abstand-60 col-md-9 col-12 abstand-unten-30">
              <div class="card-body">
                <p class="copy_bold_16">Meine aktuellen Daten</p>
                <p class="copy_16">
                  {{partner.titel}}
                  {{partner.vorname}}
                  {{partner.namensvorsatz }}
                  {{partner.name}}
                </p>
              </div>
            </div>
            <div class="row">
              <div class="col-md-9 col-12">
                <div class="row abstand-unten-22">
                  <h2 class="abstand-unten-32 col-12">Bitte geben Sie Ihre neuen Daten ein</h2>
                  <div class="col-md-6  col-12 col-kalibrieren">
                    <label class="aufschrift copy_bold_12">Anrede*</label>
                    <div class="col-12 copy_16 dropdown">
                      <button class="copy_16 dropdown-button"
                              @click="toggleAnredeDropdown"
                              :class="{'fehler': !anredeAusgewaehlt && mandant === 1,
                              'fehler-oesa': !anredeAusgewaehlt && mandant === 3,
                              'fehler-oevo': !anredeAusgewaehlt && mandant === 5}">
                        <label class="copy_16" id="anrede">{{anrede}}</label>
                        <em class="fa-solid fa-chevron-down"
                           :class="{'oesa-gruen-color': mandant === 3,
                            'oevo-blau-color': mandant === 5}"></em>
                      </button>
                      <ul v-show="showAnredeDropdown" class="dropdown-inhalt">
                        <li
                          class="dropdown-auswahl"
                          :class="{'vgh-blau-bg-hover': mandant === 1,
                             'oesa-gruen-bg-hover': mandant === 3,
                            'oevo-blau-bg-hover': mandant === 5}"
                          @click="anredeAuswahl('Herr')">
                          Herr
                        </li>
                        <li
                          class="dropdown-auswahl"
                          :class="{'vgh-blau-bg-hover': mandant === 1,
                            'oesa-gruen-bg-hover': mandant === 3,
                            'oevo-blau-bg-hover': mandant === 5}"
                          @click="anredeAuswahl('Frau')">
                          Frau
                        </li>
                        <li id="auswahlAusPartnerdaten"
                          class="dropdown-auswahl"
                          :class="{'vgh-blau-bg-hover': mandant === 1,
                            'oesa-gruen-bg-hover': mandant === 3,
                            'oevo-blau-bg-hover': mandant === 5}"
                          @click="anredeAuswahl(partner.anrede)">
                          {{this.partner.anrede}}
                        </li>
                      </ul>
                      <label
                        v-if="!anredeAusgewaehlt"
                        class="fehler-text copy_bold_12"
                        :class="{'fehler-text-oesa': mandant === 3,
                          'fehler-text-oevo': mandant === 5}">
                        Bitte wählen Sie eine Anrede
                      </label>
                    </div>
                  </div>
                  <div class="col-md-6 col-12 col-kalibrieren">
                  </div>
                  <div class="col-md-6  col-12 col-kalibrieren">
                    <label class="aufschrift copy_bold_12">Vorname*</label>
                    <input
                      class="eingabe copy_16"
                      :class="{'fehler': vornameFehler && mandant === 1,
                        'fehler-oesa': vornameFehler && mandant === 3,
                        'vgh-eingabe': mandant === 1,
                        'oesa-eingabe': mandant === 3,
                        'oevo-eingabe': mandant === 5,
                        'fehler-oevo': vornameFehler && mandant === 5}"
                      maxlength="60"
                      v-model="vornameEingabe"
                      id="vorname"
                    >
                    <label
                      v-if="vornameFehler"
                      class="fehler-text copy_bold_12"
                      :class="{'fehler-text-oesa': mandant === 3,
                        'fehler-text-oevo': mandant === 5}">
                      {{vornameFehlerText}}
                    </label>
                  </div>
                  <div class="col-md-6 col-12 col-kalibrieren">
                    <label class="aufschrift copy_bold_12">Nachname*</label>
                    <input
                      class="eingabe copy_16"
                      :class="{'fehler': nachnameFehler && mandant === 1,
                        'fehler-oesa': nachnameFehler && mandant === 3,
                        'vgh-eingabe': mandant === 1,
                        'oesa-eingabe': mandant === 3,
                        'oevo-eingabe': mandant === 5,
                        'fehler-oevo': nachnameFehler && mandant === 5}"
                      maxlength="60"
                      v-model="nachnameEingabe"
                      id="nachname"
                    >
                    <label
                      v-if="nachnameFehler"
                      class="fehler-text copy_bold_12"
                      :class="{'fehler-text-oesa': mandant === 3,
                        'fehler-text-oevo': mandant === 5}">
                      {{nachnameFehlerText}}
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-9 col-12" id="upload-hinweis">
              <hinweis label="Amtlicher Nachweis*" right="9">
                Als Nachweis der Änderung Ihrer persönlichen Daten
                benötigen wir eine Kopie eines amtlichen Dokumentes
                z.B. Heiratsurkunde, Scheidungsurkunde, Personalausweis. <br>
                Zulässige Dateiformate sind PDF, JPG, JPEG und PNG. <br>
                Die maximale Uploadgröße ist 12 MB.
              </hinweis>
            </div>
            <upload
              v-on:dateienSenden="dateienAnnehmen"
              ref="upload"
              columnSize="col-9"
              upload-bezeichnung="Anhang"
              id="upload"
            >
            </upload>
            <label v-if="!dateienAusgewaehlt" id="upload-label" class="row fehler-text copy_bold_12"
                   :class="{'fehler-text-oesa': mandant === 3,
                    'fehler-text-oevo': mandant === 5}">
              Bitte laden Sie einen Nachweis hoch.
            </label>
            <label id="pflicht-label" class="copy_12">Pflichtfeld*</label>
            <div class="row g-3" v-if="keineAenderung">
              <label
                class="fehler-text copy_bold_12 col-12"
                :class="{'fehler-text-oesa': mandant === 3,
                'fehler-text-oevo': mandant === 5}">
                Es wurde keine Änderung hinterlegt.
              </label>
            </div>
            <div>
              <input
                type="button"
                @click="zurZusammenfassung"
                class="button-ivv white"
                :class="{'button-vgh': mandant === 1,
                     'button-oesa': mandant === 3,
                     'button-oevo': mandant === 5}"
                value="Weiter"
                id="weiter-btn"
              >
              <input type="button"
                     id="verwerfen-btn"
                     class="button-ivv"
                     value="Verwerfen"
                     @click="showAbfrage = true"
              >
            </div>
          </div>
          <name-zusammenfassung
            v-if="zusammenfassung"
            @absenden="dateienAbsenden"
            ref="zusammenfassung"
            :anrede="anrede"
            :titel="titelEingabe"
            :vorname="vornameEingabe"
            :nachname="nachnameEingabe"
            :dateien="dateien">
          </name-zusammenfassung>
        </div>
        <div class="col-xl-4">
          <vertreter-info
            ueberschrift="Ich helfe Ihnen gerne bei der Änderung Ihrer Daten weiter."
            :mail-adresse="true"
            :nachricht="true"
            :vertrLink="true"
            :termin="true"
            :social-media="true"
            :vertreter="vertreter">
          </vertreter-info>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import api from '@/backend-api';
import Hinweis from '@/components/Hinweis.vue';
import Upload from '@/components/Upload.vue';
import VertreterInfo from '@/components/VertreterInfo.vue';
import Sicherheitsabfrage from '@/components/Sicherheitsabfrage.vue';
import NameZusammenfassung from '@/change-personal-data/NameZusammenfassung.vue';
import sicherheitsabfrage from '@/sicherheitsabfrage';
import bestaetigungUtil from '@/bestaetigungUtil';
import mandant from '@/mandant';
import zugriffsstatus from '@/zugriffsstatus';
import Vue from 'vue';

export default {
  mixins: [sicherheitsabfrage, bestaetigungUtil, mandant, zugriffsstatus],
  name: 'NameAendern',
  data() {
    return {
      dateien: [],
      dateienAusgewaehlt: {},
      anrede: '',
      anredeAusgewaehlt: true,
      showAnredeDropdown: false,
      titelEingabe: '',
      vornameEingabe: '',
      nachnameEingabe: '',

      eingabeFehler: true,
      zusammenfassung: false,
      fehler: false,
      titelFehler: false,
      vornameFehler: false,
      nachnameFehler: false,
      keineAenderung: false,

      showHinweis: false,

      vornameFehlerText: '',
      nachnameFehlerText: '',
      showAbfrage: false,
      titelAbfrage: 'Möchten Sie die Änderung Ihrer persönlichen Daten wirklich verwerfen?',
      textAbfrage: 'Ihr bisheriger Entwurf und Ihre hochgeladenen Nachweise werden gelöscht.',
    };
  },
  components: {
    Hinweis,
    Sicherheitsabfrage,
    NameZusammenfassung,
    Upload,
    VertreterInfo,
  },
  computed: {
    ...mapState(['partner', 'vertreter']),
  },

  beforeMount() {
    if (this.istBenutzerFirma()) {
      this.$router.push('/');
    }
    this.setZurueckFeld();
  },

  mounted() {
    this.anredeAusPartnerdaten();
    this.vornameEingabe = this.partner.vorname;
    this.nachnameEingabe = this.partner.name;
    this.$emit('view-geladen');
  },

  methods: {
    anredeAusPartnerdaten() {
      this.anrede = this.partner.anrede;
      if (this.anrede === 'Herr' || this.anrede === 'Frau') {
        document.getElementById('auswahlAusPartnerdaten').classList.add('hide');
      }
    },
    toggleAnredeDropdown() {
      this.showAnredeDropdown = !this.showAnredeDropdown;
    },

    anredeAuswahl(anrede) {
      this.anredeAusgewaehlt = true;
      this.anrede = anrede;
      this.toggleAnredeDropdown();
    },

    hasAenderungen() {
      return this.anrede !== this.partner.anrede
        || this.vornameEingabe !== this.partner.vorname
        || this.nachnameEingabe !== this.partner.name;
    },

    aktualisiereKeineAenderung() {
      if (this.keineAenderung && this.hasAenderungen()) {
        this.keineAenderung = false;
      }
    },

    checkeEingabeFehler() {
      if (!this.vornameFehler && !this.nachnameFehler && !this.titelFehler
      && (this.dateien.length > 0) && this.anrede) {
        this.eingabeFehler = false;
      } else {
        this.eingabeFehler = true;
      }
    },
    closeAbfrage() {
      this.showAbfrage = false;
    },

    toggleHinweis() {
      this.showHinweis = !this.showHinweis;
    },

    schliesseHinweis() {
      this.showHinweis = false;
    },

    async dateienAbsenden() {
      try {
        this.$refs.zusammenfassung.setBackendFehler(false);
        this.$refs.zusammenfassung.setApiFehler(false);
        this.validateAlle();
        if (!this.eingabeFehler) {
          const formData = new FormData();
          this.dateien.forEach((element) => {
            const currentFile = new File([element], encodeURI(element.name.normalize('NFC')), {
              type: element.type,
            });
            formData.append('file', currentFile);
          });
          formData.append('anrede', this.anrede);
          formData.append('titel', this.titelEingabe);
          formData.append('vorname', this.vornameEingabe);
          formData.append('nachname', this.nachnameEingabe);
          formData.append('namensvorsatz', '');
          await api.sendePersoenlicheDaten(formData, Vue.$keycloak.token);
          await this.$store.dispatch('ladePartner');
          this.zusammenfassung = false;
          this.verlasseSeite = true;
          let vorherigeSeite = this.$store.state.vorherigeURL;
          if (!vorherigeSeite) {
            vorherigeSeite = 'Profil';
          }
          await this.$router.push({
            name: vorherigeSeite,
            params: {
              bestaetigung: 'name',
            },
          });
        }
      } catch (e) {
        if (e.response.status === 429) {
          this.$refs.zusammenfassung.setApiFehler(true);
        } else {
          this.$refs.zusammenfassung.setBackendFehler(true);
        }
        console.log(e);
      }
    },

    dateienAnnehmen(daten) {
      this.dateien = daten;
    },

    eingegeben() {
      return this.titelEingabe || this.vornameEingabe || this.nachnameEingabe
        || this.dateien.length > 0;
    },

    grosseAnfangsbuchstaben(eingabe) {
      // Die Eingabe wird komplett kleingeschrieben und in einzelne Strings getrennt,
      // wenn es Leerzeichen gibt.
      let einzelWoerter = eingabe.toLowerCase().split(' ');

      // Der erste Buchstabe jedes Wortes wird groß gesetzt und die restlichen Buchstaben werden
      // konkateniert.
      for (let i = 0; i < einzelWoerter.length; i += 1) {
        einzelWoerter[i] = einzelWoerter[i].charAt(0).toUpperCase() + einzelWoerter[i].substring(1);
      }

      // Die Wörter werden mit Leerzeichen verbunden.
      einzelWoerter = einzelWoerter.join(' ');

      // Der gleiche Vorgang wird wiederholt, nur mit Bindestrichen, statt Leerzeichen.
      einzelWoerter = einzelWoerter.toString().split('-');
      for (let i = 0; i < einzelWoerter.length; i += 1) {
        einzelWoerter[i] = einzelWoerter[i].charAt(0).toUpperCase() + einzelWoerter[i].substring(1);
      }
      return einzelWoerter.join('-');
    },

    gueltigerName(name) {
      const regEx = new RegExp(/^([a-zA-ZäöüÄÖÜ]+['-]? *)+[a-zA-ZäöüÄÖÜß]*$/);
      return regEx.test(name);
    },

    gueltigerTitel() {
      const regEx = new RegExp(/^[a-zA-ZäöüÄÖÜß .'()-]*$/);
      return regEx.test(this.titelEingabe);
    },

    validateAlle() {
      if (this.anredeAusgewaehlt !== true) {
        this.anredeAusgewaehlt = false;
      }
      this.validateVorname(true);
      this.validateNachname(true);
      this.validateDateien(true);
    },

    validateDateien(istLeerCheck) {
      if (this.dateien.length < 1 && istLeerCheck) {
        this.dateienAusgewaehlt = false;
      } else {
        this.dateienAusgewaehlt = true;
      }
    },

    validateVorname(istLeerCheck) {
      if (this.vornameEingabe === '' && istLeerCheck) {
        this.vornameFehler = true;
        this.vornameFehlerText = 'Bitte geben Sie Ihren Vornamen ein';
      } else if (!this.gueltigerName(this.vornameEingabe) && this.vornameEingabe) {
        this.vornameFehler = true;
        this.vornameFehlerText = 'Bitte achten Sie auf ungültige Zeichen';
      } else {
        this.vornameFehler = false;
      }
    },

    validateNachname(istLeerCheck) {
      if (this.nachnameEingabe === '' && istLeerCheck) {
        this.nachnameFehler = true;
        this.nachnameFehlerText = 'Bitte geben Sie Ihren Nachnamen ein';
      } else if (!this.gueltigerName(this.nachnameEingabe) && this.nachnameEingabe) {
        this.nachnameFehler = true;
        this.nachnameFehlerText = 'Bitte achten Sie auf ungültige Zeichen';
      } else {
        this.nachnameFehler = false;
      }
    },

    validateTitel() {
      if (!this.gueltigerTitel()) {
        this.titelFehler = true;
        this.titelFehlerText = 'Bitte achten Sie auf ungültige Zeichen';
      } else {
        this.titelFehler = false;
      }
    },

    zurueck() {
      this.zusammenfassung = false;
      this.$root.$refs.app.setZurueckFeld(true);
      document.getElementById('app').scrollTo(0, 0);
    },

    verwerfenZurueck() {
      this.verlasseSeite = true;
      this.$router.back();
    },

    zurZusammenfassung() {
      this.vornameEingabe = this.vornameEingabe.trim();
      this.nachnameEingabe = this.nachnameEingabe.trim();
      if (this.hasAenderungen()) {
        this.validateAlle();
        this.checkeEingabeFehler();
        if (!this.eingabeFehler) {
          this.$root.$refs.app.setZurueckFeld(false);
          this.vornameEingabe = this.grosseAnfangsbuchstaben(this.vornameEingabe);
          this.zusammenfassung = true;
        }
        document.getElementById('app').scrollTo(0, 0);
      } else {
        this.keineAenderung = true;
      }
    },
    setZurueckFeld() {
      this.$root.$refs.app.setZurueckFeld(true);
    },
  },

  watch: {
    anrede() {
      this.checkeEingabeFehler();
      this.aktualisiereKeineAenderung();
    },
    vornameEingabe() {
      this.validateVorname();
      this.checkeEingabeFehler();
      this.aktualisiereKeineAenderung();
    },
    nachnameEingabe() {
      this.validateNachname();
      this.checkeEingabeFehler();
      this.aktualisiereKeineAenderung();
    },
    titelEingabe() {
      this.validateTitel();
      this.checkeEingabeFehler();
    },
    dateien() {
      this.validateDateien();
      this.checkeEingabeFehler();
    },
  },
};
</script>

<style scoped>
#anrede {
  padding-top: 11px;
}

#pflicht-label {
  color: #6B7171;
  margin: 19px 0 30px 0;
}

#rechteck {
  background-color: #EDF0F3;
  width: 100%;
  height: 50px;
  border-radius: 0;
  border-color: #EDF0F3;
  border-top: 1px solid #CBCFD3;
  padding-top: 12px;
}

#upload-hinweis{
  padding: 0;
}

#upload-label {
  margin-left: 0;
}

#weiter-btn {
  margin-right: 15px;
}

#weiter-btn:disabled {
  opacity: 0.5;
}

.aufschrift {
  color: #6B7171;
  margin-top: 22px;
  margin-bottom: 6px;
  white-space: nowrap;
}

.card-grau {
  width: auto;
  height: auto;
  margin-left: 0;
  background-color: #EDF0F3;
  text-align: left;
  border-radius: 0;
  border-color: #EDF0F3;
  padding: 0;
}

.card-body {
  padding: 30px 15px;
}

.dropdown {
  position: relative;
  display: inline-block;
  padding: 0;
}

.dropdown-auswahl {
  height: 50px;
  padding: 14px 0 0 10px;
  text-align: left;
}

.dropdown-auswahl:hover {
  color: white;
}

.dropdown-button {
  text-align: left;
  padding-left: 9px;
  height: 50px;
  width: 100%;
  color: black;
  background: #FFFFFF;
  border: 1px solid #EDF0F3;
}

.dropdown-inhalt {
  background-color: #f1f1f1;
  box-shadow: 2px 2px 8px rgba(53, 53, 53, 0.25);
  display: block;
  list-style-type: none;
  padding-left: 0;
  position: absolute;
  width: 100%;
  z-index: 1;
}

.eingabe {
  padding: 0 9px;
}

.fa-chevron-down{
  color: #0446be;
  font-size: 26px;
  float: right;
  padding: 13px 6px;
}

.fa-chevron-left {
  padding-right: 10px;
}

.fehler:focus {
  outline: none !important;
}

.hide {
  display: none;
}

@media (max-width: 576px) {
  #pflicht-label {
    margin-bottom: 20px;
  }

  #upload-hinweis {
    width: 100%;
  }

  .abstand-unten-32 {
    margin-bottom: 22px;
  }

  .card-grau {
    margin-bottom: 30px;
  }
}

</style>
