<template>
  <div>
    <h2 class="abstand-unten-30">Zusammenfassung Ihrer Änderung</h2>
    <label
      class="abstand-unten-30 copy_16"
      id="bitte">
      Bitte überprüfen Sie Ihre neuen eingegebenen Daten
    </label>
    <div class="card col-md-9 col-12">
      <div class="card-body">
        <p class="copy_bold_16">Meine neuen Daten</p>
        <p class="copy_16">
          {{titel }}
          {{vorname}}
          {{nachname}}
        </p>
      </div>
    </div>
    <div>
      <ul class="liste row" v-for="(datei, index) in dateien" :key="index">
          <li class="copy_16 listen-element"
              :class="{'vgh-blau-color': mandant === 1,
                  'oesa-gruen-color': mandant === 3,
                  'oevo-blau-color': mandant === 5}">
            <em class="fa-solid fa-paperclip"
               :class="{'vgh-blau-color': mandant === 1,
                  'oesa-gruen-color': mandant === 3,
                  'oevo-blau-color': mandant === 5}"></em>
            <label id="datei-name copy_16">{{datei.name.normalize("NFC")}}&nbsp;</label>
            <label v-if="datei.size < 1048576">({{bZuKb(datei.size)}}KB)</label>
            <label v-else>({{bZuMb(datei.size)}}MB)</label>
          </li>
      </ul>
    </div>
    <input @click="absenden"
           type="button"
           class="button-ivv white"
           :class="{'button-vgh': mandant === 1,
                     'button-oesa': mandant === 3,
                     'button-oevo': mandant === 5}"
           :disabled="sendenButtonDisabled"
           value="Absenden">
    <backend-fehler :api-fehler="apiFehler" :backend-fehler="backendFehler"/>
  </div>
</template>

<script>
import mandant from '@/mandant';
import BackendFehler from '@/components/BackendFehler.vue';

export default {
  name: 'NameZusammenfassung',
  mixins: [mandant],
  props: ['anrede', 'titel', 'vorname', 'nachname', 'dateien'],
  components: {
    BackendFehler,
  },
  data() {
    return {
      apiFehler: false,
      backendFehler: false,
      sendenButtonDisabled: false,
    };
  },
  methods: {
    absenden() {
      this.sendenButtonDisabled = true;
      this.$emit('absenden');
    },
    bZuKb(b) {
      const kb = b / 1024;
      return kb.toFixed(1);
    },
    bZuMb(b) {
      const mb = b / 1048576;
      return mb.toFixed(1);
    },
    setBackendFehler(backendValue) {
      this.backendFehler = backendValue;
    },
    setApiFehler(apiValue) {
      this.apiFehler = apiValue;
    },
  },
};
</script>

<style scoped>
#bitte {
  color: #6B7171;
}

#datei-name {
  margin-bottom: 0;
  max-width: 450px;
  overflow: hidden;
  text-overflow: ellipsis;
  transform: translateY(7px);
}

.button-ivv {
  margin-top: 15px;
}

.card {
  background-color: #EDF0F3;
  border-radius: 0;
  border-color: #EDF0F3;
  height: auto;
  margin-bottom: 15px;
  text-align: left;
  width: 100%;
}

.card-body {
  padding: 30px 15px;
}

.fa-paperclip {
  color: #484a4f;
  font-size: 24px;
  margin-right: 10px;
  transform: translateY(5px);
}

.fehler-text {
  margin-top: 15px;
}

.liste {
  list-style-type:none;
  margin-bottom: 0;
  padding-left: 15px;
}

.listen-element {
  color: #484a4f;
  max-width: 500px;
  padding: 0;
  white-space: nowrap;
}

@media (max-width: 991px) {
  #datei-name {
    max-width: 425px;
  }
}

@media (max-width: 576px) {
  #datei-name {
    margin-left: -4px;
    max-width: 88%;
  }

  .abstand-unten-30 {
    margin-bottom: 20px;
  }

  .fa-paperclip {
    font-size: 16px;
    transform: translateY(2px);
  }

  .liste {
    margin-top: 5px;
  }

  .listen-element {
    max-width: 85%;
  }
}

@media (max-width: 380px) {
  #datei-name {
    max-width: 80%;
  }

  .card {
    width: auto;
  }

  .liste {
    margin-top: 0;
  }
}
</style>
