<template>
  <div>
    <div class="container main-content">
      <div class="row g-3">
        <div class="col-xl-8 mb-5">
          <h1 class="abstand-unten-30">Passwort ändern </h1>
          <div>
            <div class="row">
              <div class="col-md-9 col-12">
                <p class="copy_18 abstand-unten-30">
                  Klicken Sie auf weiter, um Ihr Passwort zu ändern.
                </p>
              </div>
            </div>
            <div>
              <div>
                <a class="kein-unterstreichen">
                  <button class="button-ivv white"
                          :class="{'button-vgh': mandant === 1,
                             'button-oesa': mandant === 3,
                             'button-oevo': mandant === 5}"
                          :disabled="weiterButtonDisabled"
                          @click="absenden">
                    Weiter
                  </button>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div  v-if="windowWidth>767" class="col-xl-4">
          <vertreter-info
            ueberschrift="Ich helfe Ihnen gerne bei der Änderung Ihrer Daten weiter."
            :social-media="true"
            :nachricht="true"
            :vertrLink="true"
            :termin="true"
            :vertreter="vertreter">
          </vertreter-info>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import mandant from '@/mandant';
import Vue from 'vue';
import VertreterInfo from '@/components/VertreterInfo.vue';
import { mapState } from 'vuex';

export default {
  name: 'PasswortAendern',
  mixins: [mandant],
  beforeMount() {
    this.$root.$refs.app.setZurueckFeld(true);
    this.erstellePasswortLink();
  },
  mounted() {
    this.$emit('view-geladen');
  },
  components: {
    VertreterInfo,
  },
  computed: {
    ...mapState(['partner', 'vertreter']),
  },
  data() {
    return {
      passwortLink: '',
      weiterButtonDisabled: false,
    };
  },
  methods: {
    async absenden() {
      this.weiterButtonDisabled = true;
      this.erstellePasswortLink();
      window.location.href = this.passwortLink;
    },
    erstellePasswortLink() {
      const redirectUri = `${window.location.origin}?aktion=passwort-geaendert`;
      this.passwortLink = Vue.$keycloak.createLoginUrl({ redirectUri, action: 'UPDATE_PASSWORD' });
    },
  },
};
</script>

<style scoped>
.kein-unterstreichen:hover {
  text-decoration: none;
}
</style>
