import Vue from 'vue';
import axios from 'axios';
import VueAxios from 'vue-axios';

Vue.use(VueAxios, axios);

const AXIOS = axios.create({
  baseURL: `${process.env.VUE_APP_API_URL}`,
});

export default {
  getAppInfo() {
    return AXIOS.get('/info');
  },
};
