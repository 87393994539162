<template>
  <div id="karte" class="card"
       :class="{'checked-karte-vgh': checked && mandant === 1,
       'checked-karte-oesa': checked && mandant === 3,
       'checked-karte-oevo': checked && mandant === 5}">
    <div class="card-body">
      <div class="row">
        <h6 id="vr-nr" class="copy_bold_12 col-10"
            :class="{'vgh-blau-sekundaer-color': mandant === 1,
              'oesa-gruen-sekundaer-color': mandant === 3,
              'oevo-blau-sekundaer-color': mandant === 5}">
          {{ versicherungsscheinnummer }}</h6>
        <slot class="col-2"></slot>
      </div>
      <div id="merkmale">
        <h3 id="produkt" class="card-title">{{ produktname }}</h3>
        <h5 v-if="spezifischesMerkmal" id="merkmal" class="copy_16 card-info">
          {{ formatiereSpezifischesMerkmal(spezifischesMerkmal) }}
        </h5>
      </div>
    </div>
  </div>
</template>

<script>
import mandant from '@/mandant';

export default {
  name: 'KarteVertragBank',
  mixins: [mandant],
  props: [
    'versicherungsscheinnummer',
    'produktname',
    'spezifischesMerkmal',
    'kennzeichner',
    'iban',
    'button-text',
    'checked',
  ],
  methods: {
    formatiereSpezifischesMerkmal(merkmal) {
      switch (this.versicherungsscheinnummer.substring(2, 4)) {
        case '24': // KFZ
          return this.extrahiereKfzKennzeichen(merkmal);
        case '01': // HH Glas, Hausrat
        case '02': // Wohngebäude
        case '03': // Gebäude
        case '04': // Landwirtschaft
        case '05': // Gewerbe
        case '06': // Technische Versicherung
        case '07': // Hagel
        case '08': // Industrie
        case '09': // Transport
        case '13': // Sonstige Sachsparten
        case '22': // Haftpflicht
        case '25': // Rechtsschutz
        case '68': // Komposit
          return this.entferneLaenderkennzeichen(merkmal);
        default:
          return merkmal;
      }
    },
    extrahiereKfzKennzeichen(merkmal) {
      return merkmal.split('/')[0];
    },
    entferneLaenderkennzeichen(adresse) {
      if (adresse.substring(0, 2) === 'D ') {
        return adresse.substring(2);
      }
      if (adresse.substring(0, 3) === 'NL ') {
        return adresse.substring(3);
      }
      return adresse;
    },
  },
};
</script>

<style scoped>

#produkt {
  margin-bottom: 10px;
}

#merkmale {
  min-height: 90px;
}

#vr-nr {
  margin-bottom: 0;
}

.card {
  width: 350px;
  height: 100%;
  margin-left: 0;
  background-color: #EDF0F3;
  text-align: left;
  border-radius: 0;
  border-color: #EDF0F3;
  border-width: 3px;
}

.card-body {
  padding: 30px 15px;
}

.card-info {
  margin-bottom: 0;
  padding: 0;
}

.checked-karte-vgh {
  border-color: rgb(126, 152, 196);
  border-style: solid;
}

.checked-karte-oesa {
  border-color: rgb(120, 191, 164);
  border-style: solid;
}

.checked-karte-oevo {
  border-color: rgb(178, 202, 226);
  border-style: solid;
}

@media (max-width: 576px) {
  .card {
    margin-bottom: 0px;
  }
}

@media (max-width: 410px) {
  .card {
    width: 100%;
  }
}

</style>
