import { mapState } from 'vuex';

export default {
  computed: {
    ...mapState(['partner']),
  },
  methods: {
    istBenutzerFirma() {
      switch (this.partner.anrede) {
        case 'Firma':
        case 'Praxisgemeinschaft':
        case 'Verein':
          return true;
        default:
          return false;
      }
    },
    istBenutzerPartnergemeinschaft() {
      switch (this.partner.anrede) {
        case 'Herr und Frau':
        case 'Herrn und Frau':
        case 'Frau und Herrn':
        case 'Frau und Herr':
        case 'Frauen':
        case 'Herren':
          return true;
        default:
          return false;
      }
    },
  },
};
