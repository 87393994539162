<template>
  <!-- eslint-disable max-len -->
  <div>
    <sicherheitsabfrage
      v-show="showAbfrage"
      typ="verwerfen"
      :titel="titelAbfrage"
      :text="textAbfrage"
      @zustimmung="verwerfenZurueck"
      @abbruch="closeAbfrage"
      @ablehnung="closeAbfrage">
    </sicherheitsabfrage>
    <sicherheitsabfrage
      v-if="verlasseSeiteAbfrage"
      typ="verlassen"
      @zustimmung="behandleSeiteVerlassen"
      @ablehnung="schliesseAbfrage"
      @abbruch="schliesseAbfrage">
    </sicherheitsabfrage>
    <div v-if="zusammenfassung" class="card card-grau" id="rechteck">
      <div class="container">
        <a class="copy_16 pfeil-btn text-link abstand-unten-0"
           :class="{'text-link-vgh': mandant === 1,
              'text-link-oesa': mandant === 3,
              'text-link-oevo': mandant === 5}"
           @click="zurueck">
          <em class="fa-solid fa-chevron-left"></em>Zurück
        </a>
      </div>
    </div>
    <div class="container main-content">
      <slot name="benachrichtigung"></slot>
      <div class="row g-3 abstand-unten-17">
        <div class="col-xl-8 mb-5">
          <h1 class="abstand-unten-30">Adresse ändern</h1>
          <div v-if="!zusammenfassung">
            <div class="card card-grau abstand-unten-60 col-md-9">
              <div class="card-body">
                <p class="copy_bold_16 abstand-unten-5">Meine aktuelle Adresse</p>
                <p class="copy_16 abstand-unten-0">{{partner.anschrift.strasse }} {{partner.anschrift.hausnummer}}</p>
                <p class="copy_16 abstand-unten-0">{{partner.anschrift.postleitzahl }} {{partner.anschrift.ort}}</p>
                <p class="copy_16 abstand-unten-0">{{partner.anschrift.land}}</p>
                <p class="copy_16 abstand-unten-0">{{partner.anschrift.ortsteil}}</p>
                <p class="copy_16 abstand-unten-0">{{partner.anschrift.zustellvermerk}}</p>
              </div>
            </div>
            <h2 class="abstand-unten-32">Bitte geben Sie Ihre neuen Daten ein</h2>
            <div class="row abstand-unten-17">
              <div class="col-md-6 col-9 col-kalibrieren">
                <label class="aufschrift copy_bold_12" for="str">Straße *</label>
                <input maxlength="60"
                       :class="{'fehler': strFehler && mandant === 1,
                        'fehler-oesa': strFehler && mandant === 3,
                        'vgh-eingabe': mandant === 1,
                        'oesa-eingabe': mandant === 3,
                        'oevo-eingabe': mandant === 5,
                        'fehler-oevo': strFehler && mandant === 5}"
                       class="eingabe copy_16"
                       v-model="strEingabe"
                       id="str">
                <label v-if="strFehler" class="fehler-text copy_bold_12"
                       :class="{'fehler-text-oesa': mandant === 3,
                       'fehler-text-oevo': mandant === 5}">{{strFehlerText}}</label>
              </div>
              <div class="col-3 col-kalibrieren">
                <label class="aufschrift copy_bold_12" for="nr" >Hausnr. *</label>
                <input :class="{'fehler': nrFehler && mandant === 1,
                        'fehler-oesa': nrFehler && mandant === 3,
                        'vgh-eingabe': mandant === 1,
                        'oesa-eingabe': mandant === 3,
                        'oevo-eingabe': mandant === 5,
                        'fehler-oevo': nrFehler && mandant === 5}"
                       class="eingabe copy_16"
                       maxlength="10"
                       v-model="nrEingabe"
                       id="nr">
                <label v-if="nrFehler" class="fehler-text copy_bold_12"
                       :class="{'fehler-text-oesa': mandant === 3,
                       'fehler-text-oevo': mandant === 5}">{{nrFehlerText}}</label>
              </div>
            </div>
            <div class="row abstand-unten-17">
              <div class="col-md-9 col-12 col-kalibrieren">
                <hinweis label="Zustellvermerk" right="16px">
                  Sie können uns optional einen Zustellvermerk nennen,
                  z.B. Hinterhof rechts kleine Tür
                </hinweis>
                <input
                  :class="{'fehler': vermerkFehler && mandant === 1,
                        'fehler-oesa': vermerkFehler && mandant === 3,
                        'vgh-eingabe': mandant === 1,
                        'oesa-eingabe': mandant === 3,
                        'oevo-eingabe': mandant === 5,
                        'fehler-oevo': vermerkFehler && mandant === 5}"
                  class="eingabe copy_16"
                  maxlength="120"
                  id="vermerk"
                  v-model="vermerkEingabe">
                <label v-if="vermerkFehler" class="fehler-text copy_bold_12"
                       :class="{'fehler-text-oevo': mandant === 5}">{{vermerkFehlerText}}</label>
              </div>
            </div>
            <div class="row abstand-unten-17">
              <div class="col-md-3 col-4 col-kalibrieren">
                <label class="aufschrift copy_bold_12" for="plz">Postleitzahl *</label>
                <input :class="{'fehler': plzFehler && mandant === 1,
                        'fehler-oesa': plzFehler && mandant === 3,
                        'vgh-eingabe': mandant === 1,
                        'oesa-eingabe': mandant === 3,
                        'oevo-eingabe': mandant === 5,
                        'fehler-oevo': plzFehler && mandant === 5}"
                       class="eingabe copy_16"
                       @keypress="nurZahlen"
                       @paste="behandlePlzEinfuegen"
                       @input="plzFehler = false"
                       @blur="validatePlz(false)"
                       maxlength="5"
                       v-model="plzEingabe"
                       id="plz">
                <label v-if="plzFehler" class="fehler-text copy_bold_12"
                       :class="{'fehler-text-oevo': mandant === 5}">{{plzFehlerText}}</label>
              </div>
              <div class="col-md-6 col-8 col-kalibrieren">
                <label class="aufschrift copy_bold_12" for="ort">Ort *</label>
                <input class="eingabe copy_16"
                       maxlength="60"
                       :class="{'fehler': ortFehler && mandant === 1,
                        'fehler-oesa': ortFehler && mandant === 3,
                        'vgh-eingabe': mandant === 1,
                        'oesa-eingabe': mandant === 3,
                        'oevo-eingabe': mandant === 5,
                        'fehler-oevo': ortFehler && mandant === 5}"
                       v-model="ortEingabe"
                       id="ort">
                <label v-if="ortFehler" class="fehler-text copy_bold_12"
                       :class="{'fehler-text-oevo': mandant === 5}">{{ortFehlerText}}</label>
              </div>
            </div>
            <div class="row abstand-unten-17">
              <div class="col-md-9 col-12">
                <div class="row abstand-unten-17">
                  <div class="col-md-6  col-12 col-kalibrieren">
                    <label class="aufschrift copy_bold_12" for="teil">Ortsteil</label>
                    <input class="eingabe copy_16"
                           :class="{'fehler': teilFehler && mandant === 1,
                            'fehler-oesa': teilFehler && mandant === 3,
                            'vgh-eingabe': mandant === 1,
                            'oesa-eingabe': mandant === 3,
                            'oevo-eingabe': mandant === 5,
                            'fehler-oevo': teilFehler && mandant === 5}"
                           id="teil" maxlength="60" v-model="teilEingabe">
                    <label v-if="teilFehler" class="fehler-text copy_bold_12"
                           :class="{'fehler-text-oevo': mandant === 5}">{{teilFehlerText}}</label>
                  </div>
                </div>
              </div>
            </div>
            <label id="pflicht-label" class="copy_12">Pflichtfeld*</label>
            <div>
              <input type="button" id="zusammenfassung-btn"
                     @click="zurZusammenfassung"
                     class="button-ivv white"
                     :class="{'button-vgh': mandant === 1,
                     'button-oesa': mandant === 3,
                     'button-oevo': mandant === 5}"
                     value="Weiter">
              <input type="button"
                     id="verwerfen-btn"
                     class="button-ivv"
                     value="Verwerfen"
                     @click="showAbfrage = true"
              >
            </div>
          </div>
          <adresse-zusammenfassung v-if="zusammenfassung"
                                   @fertig="abgesendet"
                                   :str="strEingabe"
                                   :nr="nrEingabe"
                                   :plz="plzEingabe"
                                   :ort="ortEingabe"
                                   :land="partner.anschrift.land"
                                   :vermerk="vermerkEingabe"
                                   :teil="teilEingabe">
          </adresse-zusammenfassung>
        </div>
        <div class="col-xl-4">
          <vertreter-info
            :ueberschrift="vertreterUeberschrift"
            :mail-adresse="true"
            :nachricht="true"
            :vertrLink="true"
            :termin="true"
            :social-media="true"
            :vertreter="vertreter">
          </vertreter-info>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import VertreterInfo from '@/components/VertreterInfo.vue';
import Sicherheitsabfrage from '@/components/Sicherheitsabfrage.vue';
import Hinweis from '@/components/Hinweis.vue';
import sicherheitsabfrage from '@/sicherheitsabfrage';
import isoUtil from '@/isoUtil';
import bestaetigungUtil from '@/bestaetigungUtil';
import mandant from '@/mandant';
import AdresseZusammenfassung from './AdresseZusammenfassung.vue';

export default {
  name: 'Adresse',
  mixins: [sicherheitsabfrage, isoUtil, bestaetigungUtil, mandant],
  data() {
    return {
      // Mixin Attribute:
      // verlasseSeite: false,
      // verlasseSeiteAbfrage: false,

      globalesZurueck: false,
      zurueckAbfrage: false,

      vertreterLink: true,
      vertreterTermin: true,
      vertreterUeberschrift: 'Ich helfe Ihnen gerne bei der Änderung Ihrer Daten weiter.',

      zusammenfassung: false,

      eingabeFehler: true,
      nrFehler: false,
      ortFehler: false,
      plzFehler: false,
      strFehler: false,
      teilFehler: false,
      vermerkFehler: false,

      nrEingabe: '',
      ortEingabe: '',
      plzEingabe: '',
      strEingabe: '',
      teilEingabe: '',
      vermerkEingabe: '',

      nrFehlerText: '',
      ortFehlerText: '',
      plzFehlerText: '',
      strFehlerText: '',
      teilFehlerText: 'Bitte achten Sie auf Sonderzeichen.',
      vermerkFehlerText: 'Bitte achten Sie auf Sonderzeichen.',
      showAbfrage: false,
      titelAbfrage: 'Möchten Sie Ihre Adressänderung wirklich verwerfen?',
      textAbfrage: 'Ihr bisheriger Entwurf wird gelöscht.',
    };
  },

  components: {
    AdresseZusammenfassung,
    Hinweis,
    Sicherheitsabfrage,
    VertreterInfo,
  },
  computed: {
    ...mapState(['partner', 'vertreter']),
  },

  methods: {
    // Mixin Methoden:
    // behandleSeiteVerlassen()
    // schliesseAbfrage ()
    // nurZahlen(eingabe)
    // checkISO(input)

    abgesendet() {
      this.verlasseSeite = true;
      let vorherigeSeite = this.$store.state.vorherigeURL;
      if (!vorherigeSeite) {
        vorherigeSeite = 'Profil';
      }
      this.$router.push({
        name: vorherigeSeite,
        params: {
          bestaetigung: 'adresse',
        },
      });
      this.zusammenfassung = false;
    },

    behandlePlzEinfuegen(value) {
      value.preventDefault();
      let eingefuegtes = value.clipboardData.getData('text/plain');
      eingefuegtes = eingefuegtes.replace(/\D/g, '');
      if (eingefuegtes.length > 5) {
        eingefuegtes = eingefuegtes.slice(0, 5);
      }
      this.plzEingabe = eingefuegtes;
    },

    checkeEingabeFehler() {
      if (!this.strFehler && !this.nrFehler && !this.ortFehler && !this.plzFehler
        && !this.vermerkFehler && !this.teilFehler) {
        this.eingabeFehler = false;
      } else {
        this.eingabeFehler = true;
      }
    },
    closeAbfrage() {
      this.showAbfrage = false;
    },
    eingegeben() {
      return !!(this.nrEingabe || this.ortEingabe || this.plzEingabe
        || this.strEingabe || this.teilEingabe || this.vermerkEingabe);
    },

    grosseAnfangsbuchstaben(eingabe) {
      // Die Eingabe wird komplett kleingeschrieben und in einzelne Strings getrennt,
      // wenn es Leerzeichen gibt.
      let einzelWoerter = eingabe.toLowerCase().split(' ');

      // Der erste Buchstabe jedes Wortes wird groß gesetzt und die restlichen Buchstaben werden
      // konkateniert.
      for (let i = 0; i < einzelWoerter.length; i += 1) {
        einzelWoerter[i] = einzelWoerter[i].charAt(0).toUpperCase() + einzelWoerter[i].substring(1);
      }

      // Die Wörter werden mit Leerzeichen verbunden.
      einzelWoerter = einzelWoerter.join(' ');

      // Der gleiche Vorgang wird wiederholt, nur mit Bindestrichen, statt Leerzeichen.
      einzelWoerter = einzelWoerter.toString().split('-');
      for (let i = 0; i < einzelWoerter.length; i += 1) {
        einzelWoerter[i] = einzelWoerter[i].charAt(0).toUpperCase() + einzelWoerter[i].substring(1);
      }
      return einzelWoerter.join('-');
    },

    validateAlle() {
      this.validateNr(true);
      this.validateOrt(true);
      this.validatePlz(true);
      this.validateStrasse(true);
    },

    validateNr(istLeerCheck) {
      if (this.nrEingabe === '' && istLeerCheck) {
        this.nrFehler = true;
        this.nrFehlerText = 'Bitte geben Sie die Hausnr. ein';
      } else if (!this.gueltigeNr() && this.nrEingabe) {
        this.nrFehler = true;
        this.nrFehlerText = 'Bitte gültige Nr. eingeben.';
      } else {
        this.nrFehler = false;
      }
    },

    validateOrt(istLeerCheck) {
      if (this.ortEingabe === '' && istLeerCheck) {
        this.ortFehler = true;
        this.ortFehlerText = 'Bitte geben Sie Ihren Ort ein.';
      } else if (!this.gueltigerOrt() && this.ortEingabe) {
        this.ortFehler = true;
        this.ortFehlerText = 'Bitte gültigen Ort eingeben.';
      } else {
        this.ortFehler = false;
      }
    },

    validatePlz(istLeerCheck) {
      if (this.plzEingabe === '' && istLeerCheck) {
        this.plzFehler = true;
        this.plzFehlerText = 'Bitte Postleitzahl eingeben.';
      } else if (!this.gueltigePLZ() && this.plzEingabe) {
        this.plzFehler = true;
        this.plzFehlerText = 'Bitte gültige Postleitzahl eingeben.';
      } else {
        this.plzFehler = false;
      }
    },

    validateStrasse(istLeerCheck) {
      if (this.strEingabe === '' && istLeerCheck) {
        this.strFehler = true;
        this.strFehlerText = 'Bitte geben Sie Ihre Straße ein';
      } else if (!this.gueltigeStrasse() && this.strEingabe) {
        this.strFehler = true;
        this.strFehlerText = 'Bitte gültige Straße eingeben.';
      } else {
        this.strFehler = false;
      }
    },

    validateTeil() {
      if (!this.checkISO(this.teilEingabe)) {
        this.teilFehler = true;
      } else {
        this.teilFehler = false;
      }
    },

    validateVermerk() {
      if (!this.checkISO(this.vermerkEingabe)) {
        this.vermerkFehler = true;
      } else {
        this.vermerkFehler = false;
      }
    },

    verwerfenZurueck() {
      this.verlasseSeite = true;
      this.$router.back();
    },
    gueltigeStrasse() {
      const regEx = new RegExp(/^[a-zA-ZÄäÖöÜüß '-.0-9]*$/);
      return regEx.test(this.strEingabe);
    },

    gueltigeNr() {
      const regEx = new RegExp(/^[0-9/]+[a-zA-Z -]*\d*$/);
      return regEx.test(this.nrEingabe);
    },

    gueltigePLZ() {
      const regEx = new RegExp(/^((0[1-9]|[1-9]\d)\d{3})$/);
      this.plzEingabe = this.plzEingabe.trim();
      return regEx.test(this.plzEingabe);
    },

    gueltigerOrt() {
      const regEx = new RegExp(/^[a-zA-ZÄäÖöÜüß '-]*$/);
      return regEx.test(this.ortEingabe);
    },

    zurueck() {
      this.zusammenfassung = false;
      this.$root.$refs.app.setZurueckFeld(true);
      document.getElementById('app').scrollTo(0, 0);
    },

    zurZusammenfassung() {
      this.validateAlle();
      this.checkeEingabeFehler();
      if (!this.eingabeFehler) {
        this.$root.$refs.app.setZurueckFeld(false);
        this.zusammenfassung = true;
      }
      document.getElementById('app').scrollTo(0, 0);
    },
    setZurueckFeld() {
      this.$root.$refs.app.setZurueckFeld(true);
    },
  },
  watch: {
    nrEingabe() {
      this.validateNr();
      this.checkeEingabeFehler();
    },
    ortEingabe() {
      this.validateOrt();
      this.checkeEingabeFehler();
    },
    strEingabe() {
      this.validateStrasse();
      this.checkeEingabeFehler();
    },
    teilEingabe() {
      this.validateTeil();
      this.checkeEingabeFehler();
    },
    vermerkEingabe() {
      this.validateVermerk();
      this.checkeEingabeFehler();
    },
  },

  beforeMount() {
    this.setZurueckFeld();
  },

  mounted() {
    this.$emit('view-geladen');
  },
};
</script>

<style scoped>
#pflicht-label {
  color: #6B7171;
  margin-top: -4px;
  padding-bottom: 30px;
}

#rechteck {
  background-color: #EDF0F3;
  width: 100%;
  height: 50px;
  border-radius: 0;
  border-color: #EDF0F3;
  border-top: 1px solid #CBCFD3;
  padding-top: 12px;
}

#zusammenfassung-btn {
  margin-right: 15px;
}

.aufschrift {
  color: #6B7171;
  margin-bottom: 6px;
  white-space: nowrap;
}

.card-grau {
  width: auto;
  height: auto;
  margin-left: 0;
  background-color: #EDF0F3;
  text-align: left;
  border-radius: 0;
  border-color: #EDF0F3;
}

.card-body {
  padding: 30px 15px;
}

.col-kalibrieren {
  margin-top: -8px;
  margin-bottom: -2px;
}

.eingabe {
  padding-left: 10px;
}

.fa-chevron-left {
  padding-right: 10px;
}

.fehler:focus {
  outline: none !important;
}

.fehler-text {
  margin-top: 7px;
}

.main-content {
  margin-bottom: -75px;
}

.pfeil-btn {
  color: #1B1C1A;
}

@media (max-width: 768px) {
  #pflicht-label {
    color: #6B7171;
    margin-top: -4px;
    padding-bottom: 10px;
  }
  .col-kalibrieren {
    margin-top: -8px;
    margin-bottom: 7px;
  }
}

@media (max-width: 576px) {
  .abstand-unten-30 {
    margin-bottom: 20px;
  }
}

@media (max-width: 380px) {
  .card-grau {
    width: auto;
  }
}
</style>
