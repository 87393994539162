export default {
  methods: {
    checkISO(input) {
      const regEx = new RegExp(/^[!"§#$%&'()*+,.0123456789:;=?@ABCDEFGHIJKLMNOPQRSTUVWXYZÄÖÜ_`abcdefghijklmnopqrstuvwxyzäöüß £€\r\n|-]*$/);
      return regEx.test(input.trim());
    },
    nurZahlen(eingabe) {
      const regEx = new RegExp(/^\d*$/);
      if (!regEx.test(eingabe.key)) {
        eingabe.preventDefault();
      }
    },
  },
};
