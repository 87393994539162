<template>
    <div id="header" :class="{'header-oesa': mandant === 3}">
        <div id="header-container" class="container">
            <div class="row g-3 align-items-center" id="header-row">
                <div class="col-11 col-xl-10 header-text">
                    <h1 class="header-text">{{textLine1}}</h1>
                    <h1 class="header-text">{{textLine2}}</h1>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import mandant from '@/mandant';

export default {
  name: 'Header',
  mixins: [mandant],
  props: ['textLine1', 'textLine2'],
};
</script>

<style scoped>
#header {
  background-image: url('../assets/RectangleHeaderDesktopBlue.jpg');
  height: 260px;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-position:center center;
  position: relative;
}

#header.header-oesa {
  background-image: url('../assets/RectangleHeaderDesktopGreen.jpeg');
}

#header-container {
  padding-top: 3rem;
  padding-bottom: 0px;
  height: 260px;
}

#header-row {
  height: calc(260px - 6rem);
}

.header-text {
  color: white;
  text-align: left;
  text-shadow: 3px 4px 10px rgba(0, 0, 0, 0.6);
  margin-bottom: 0px;
}

@media (max-width: 768px) {
  #header {
    background-image: url('../assets/RectangleHeaderBlue.jpg');
  }
  #header.header-oesa {
    background-image: url('../assets/RectangleHeaderGreen.jpeg');
  }
}

/* Mobile View */
@media (max-width: 576px) {
  #header {
    height: 200px;
    background-image: url('../assets/RectangleHeaderMobileBlue.jpg');
  }

  #header.header-oesa {
    background-image: url('../assets/RectangleHeaderMobileGreen.jpeg');
  }

  #header-container {
    padding-top: 1rem;
    padding-bottom: 0px;
    margin-bottom: 30px;
    height: 200px;
  }

  #header-row {
    height: calc(200px - 2rem);
  }
}

</style>
