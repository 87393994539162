var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('sicherheitsabfrage',{directives:[{name:"show",rawName:"v-show",value:(_vm.showAbfrage),expression:"showAbfrage"}],attrs:{"titel":_vm.titelAbfrag,"text":_vm.textAbfrage,"zustimmungsBtn":_vm.ablehnungsBtnAbfrage,"ablehnungsBtn":_vm.zustimmungsBtnAbfrage},on:{"abbruch":_vm.closeAbfrage,"zustimmung":_vm.leitenAufPostfachUm,"ablehnung":_vm.closeAbfrage}}),(_vm.verlasseSeiteAbfrage)?_c('sicherheitsabfrage',{attrs:{"typ":"verlassen"},on:{"zustimmung":_vm.behandleSeiteVerlassen,"ablehnung":_vm.schliesseAbfrage,"abbruch":_vm.schliesseAbfrage}}):_vm._e(),_c('div',{staticClass:"container main-content"},[_vm._t("benachrichtigung"),_c('div',{staticClass:"row"},[(!_vm.loading)?_c('div',{staticClass:"col-lg-8"},[(!_vm.nachrichtSchreiben)?_c('div',[_c('h2',{staticClass:"abstand-unten-30",attrs:{"id":"betreff"},domProps:{"innerHTML":_vm._s(_vm.conversation[0].betreff)}})]):_vm._e(),(_vm.nachrichtSchreiben)?_c('div',{staticClass:"abstand-unten-60",attrs:{"id":"nachricht-schreiben"}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 abstand-unten-15"},[_c('h1',{staticClass:"abstand-unten-30"},[_vm._v("Nachricht schreiben")]),_c('h3',{staticClass:"abstand-unten-30",domProps:{"innerHTML":_vm._s(_vm.conversation[0].betreff)}}),_c('label',{staticClass:"aufschrift copy_bold_12"},[_vm._v("Meine Nachricht")]),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.nachrichtEingabe),expression:"nachrichtEingabe"}],staticClass:"eingabe copy_16",class:{'fehler': _vm.nachrichtFehler && _vm.mandant === 1,
                      'fehler-oesa': _vm.nachrichtFehler && _vm.mandant === 3,
                      'vgh-eingabe': _vm.mandant === 1,
                      'oesa-eingabe': _vm.mandant === 3,
                      'oevo-eingabe': _vm.mandant === 5,
                      'fehler-oevo': _vm.nachrichtFehler && _vm.mandant === 5},attrs:{"maxlength":"1200","id":"nachricht"},domProps:{"value":(_vm.nachrichtEingabe)},on:{"input":function($event){if($event.target.composing){ return; }_vm.nachrichtEingabe=$event.target.value}}}),(_vm.nachrichtFehler)?_c('label',{staticClass:"fehler-text copy_bold_12"},[_vm._v(" "+_vm._s(_vm.nachrichtFehlerText)+" ")]):_vm._e()]),_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"col-12",attrs:{"id":"upload-hinweis"}},[_c('hinweis',{attrs:{"label":"Anhang","right":"0px"}},[_vm._v(" Zulässige Dateiformate sind PDF, JPG, JPEG und PNG. "),_c('br'),_vm._v(" Die maximale Uploadgröße ist 12 MB. ")])],1),_c('upload',{ref:"upload",attrs:{"upload-bezeichnung":"Anhang","columnSize":"col-12","id":"upload"},on:{"dateienSenden":_vm.dateienAnnehmen,"dateiGeloescht":function($event){_vm.showUploadFehler = false}}}),(_vm.showUploadFehler)?_c('label',{staticClass:"fehler-text copy_bold_12"},[_vm._v(" Bitte versuchen Sie andere Dateien hochzuladen. ")]):_vm._e()],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('input',{staticClass:"button-ivv white",class:{'button-vgh': _vm.mandant === 1,
                        'button-oesa': _vm.mandant === 3,
                        'button-oevo': _vm.mandant === 5},attrs:{"type":"button","id":"senden-btn","value":"Senden","disabled":_vm.sendenButtonDisabled},on:{"click":_vm.sendeNachricht}}),_c('input',{staticClass:"button-ivv",attrs:{"type":"button","id":"verwerfen-btn","value":"Verwerfen"},on:{"click":function($event){_vm.showAbfrage = true}}})])]),_c('backend-fehler',{attrs:{"api-fehler":_vm.apiFehler,"backend-fehler":_vm.backendFehler}})],1):_vm._e(),(!_vm.nachrichtSchreiben && _vm.conversation[0].typ != 'Kundendokument')?_c('div',{staticClass:"abstand-unten-30"},[(_vm.istAntwortButtonErlaubt())?_c('input',{staticClass:"button-ivv white",class:{'button-vgh': _vm.mandant === 1,
                     'button-oesa': _vm.mandant === 3,
                     'button-oevo': _vm.mandant === 5},attrs:{"type":"button","id":"antworten-btn","disabled":_vm.zugriffsStatus !== 'eigeneOrga',"value":"+ Antworten"},on:{"click":_vm.showNachrichtScreiben}}):_vm._e()]):_vm._e(),_vm._l((_vm.conversation),function(nachricht){return _c('div',{key:nachricht.id,staticClass:"mb-4 row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"nachrichten-blase",class:{ 'partnernachricht': nachricht.absender === nachricht.emailAdresse,
                 'nachricht-vgh': _vm.mandant === 1,
                 'nachricht-oesa': _vm.mandant === 3,
                 'nachricht-oevo': _vm.mandant === 5}},[_c('div',{staticClass:"nachrichten-content"},[_c('p',{staticClass:"nachrichten-label copy_12 text-right font-weight-bold"},[_vm._v(" "+_vm._s(nachricht.absenderName)+" | "+_vm._s(_vm.trimDatum(nachricht.erstellungsDatum))+" ")]),_c('vue-markdown',{staticClass:"copy_16",attrs:{"anchor-attributes":_vm.anchorAttrs}},[_vm._v(" "+_vm._s(nachricht.text)+" ")]),(nachricht.uploadList.length > 0)?_c('div',_vm._l((nachricht.uploadList),function(datei,index){return _c('div',{key:index,staticClass:"anhang-nachricht copy_16"},[(datei.available)?_c('div',{staticClass:"zeiger",on:{"click":function($event){return _vm.downloadAnhang(datei.name, datei.anzeigename)}}},[_c('em',{staticClass:"fa-solid fa-paperclip"}),(datei.groesse < 1024)?_c('label',{staticClass:"zeiger anhang-label"},[_vm._v(" "+_vm._s(datei.anzeigename)+" ("+_vm._s(_vm.kbZuFixedKb(datei.groesse))+"KB) ")]):_c('label',{staticClass:"zeiger"},[_vm._v(_vm._s(datei.anzeigename)+" ("+_vm._s(_vm.kbZuMb(datei.groesse))+"MB)")])]):_c('div',[_c('em',{staticClass:"fa-solid fa-circle-notch fa-spin"}),_c('label',{staticClass:"anhang-label"},[_vm._v(" "+_vm._s(datei.anzeigename)+" "),_c('em',[_vm._v("(Wird noch verarbeitet)")])])])])}),0):_vm._e()],1)])])])})],2):_vm._e(),(!_vm.loading && _vm.conversation[0].typ !== 'Kundendokument'
       && _vm.conversation[0].typ !== 'Benachrichtigung')?_c('div',{staticClass:"col-xl-4 col-lg-4"},[_c('vertreter-info',{attrs:{"ueberschrift":"Sie haben Fragen? Wir unterstützen Sie gerne.","mail-adresse":true,"nachricht":true,"termin":true,"vertrLink":_vm.vertreterLink,"social-media":true,"vertreter":_vm.konversationsPartner,"konversations-id":_vm.konversationsId,"nachrichtenverlauf":true}})],1):_vm._e()])],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }