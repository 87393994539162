<template>
    <div>
      <div class="container main-content">
        <div class="row g-3">
          <div class="col-xl-8 mb-5">
            <h1 class="abstand-unten-30">Kundenportalzugang löschen</h1>
            <div class="card card-grau abstand-60 col-md-9 col-12 abstand-unten-30">
              <div class="card-body">
                 <p class="copy_16 abstand-unten-0">
                    Nach Löschung des Kundenportalzugangs haben Sie noch 6 Wochen lang die
                    Möglichkeit auf das Kundenportal zuzugreifen und Ihre Dokumente herunterzuladen.
                    Alle weiteren Funktionen stehen Ihnen ab sofort nicht mehr zur Verfügung.
                 </p>
              </div>
            </div>
            <div class="row">
              <div class="col-md-9 col-12">
                <div id="loeschen-check-div">
                  <input type="checkbox"
                         id="loeschen-check"
                         :class="{'eingabe-fehler': errors.checkLoeschen,
                          'input-vgh': mandant === 1,
                          'input-oesa': mandant === 3,
                          'input-oevo': mandant === 5}"
                         v-model="checkLoeschen">
                  <label for="loeschen-check" class="checkbox-transformation"></label>
                  <label class="copy_bold_16 col-11 loeschen-check-text">
                    Hiermit bestätige ich die Löschung meines Kundenportalzugangs.
                  </label>
                  <label v-if="errors.checkLoeschen"
                         class="aufschrift fehler-text copy_bold_12"
                         :class="{'fehler-text-oevo': mandant === 5}">
                    Bitte bestätigen Sie, um mit der Löschung fortzufahren.
                  </label>
                </div>
              </div>
            </div>
            <div>
              <input
                type="button"
                @click="loeschenAbschicken"
                class="button-ivv white"
                :class="{'button-vgh': mandant === 1,
                  'button-oesa': mandant === 3,
                  'button-oevo': mandant === 5,
                  'button-disabled': weiterButtonDisabled}"
                value="Weiter"
                :disabled="weiterButtonDisabled"
                id="weiter-btn"
              >
              <input type="button"
                     id="abbrechen-btn"
                     class="button-ivv"
                     value="Abbrechen"
                     @click="zurueck"
              >
            </div>
          </div>
          <div  v-if="windowWidth>767" class="col-xl-4">
            <vertreter-info
              ueberschrift="Sie haben Fragen? Wir helfen Ihnen gerne weiter."
              :social-media="true"
              :nachricht="true"
              :vertrLink="true"
              :termin="true"
              :vertreter="vertreter">
            </vertreter-info>
          </div>
          <div v-if="errors.backendFehler" class="row abstand-unten-17">
            <div class="col-xl-10">
              <p class="fehler-text"
                 :class="{'fehler-text-oevo': mandant === 5}">
                {{ backendFehlerText }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import VertreterInfo from '@/components/VertreterInfo.vue';
import { mapState } from 'vuex';
import mandant from '@/mandant';
import api from '@/backend-api';
import Vue from 'vue';

export default {
  name: 'PortalzugangLoeschen',
  mixins: [mandant],
  components: {
    VertreterInfo,
  },
  computed: {
    ...mapState(['partner', 'vertreter']),
  },
  data() {
    return {
      showAbfrage: false,
      checkLoeschen: false,
      weiterButtonDisabled: true,
      backendFehlerText: 'Es gab einen technischen Fehler.',
      errors: {
        checkLoeschen: false,
        backendFehler: false,
      },
    };
  },
  methods: {
    zurueck() {
      this.$router.back();
    },
    validateLoeschenBestaetigen() {
      if (!this.checkLoeschen) {
        this.errors.checkLoeschen = true;
        this.weiterButtonDisabled = true;
      } else {
        this.errors.checkLoeschen = false;
        this.weiterButtonDisabled = false;
      }
    },
    checkeEingabeFehler() {
      return this.errors.checkLoeschen;
    },
    async loeschenAbschicken() {
      this.validateLoeschenBestaetigen();
      this.resetValidierung();
      try {
        if (!this.checkeEingabeFehler()) {
          this.weiterButtonDisabled = true;
          await api.sendePortalzugangDeaktiviert(
            Vue.$keycloak.token,
          );
          await this.$store.dispatch('ladeZugriffsstatus');
          await this.$router.push({
            name: 'Profil',
            params: {
              bestaetigung: 'portalzugang-geloescht',
            },
          });
        }
      } catch (e) {
        console.log(e);
        this.errors.backendFehler = true;
        this.weiterButtonDisabled = false;
      }
    },
    resetValidierung() {
      this.errors.checkLoeschen = false;
      this.backendFehler = false;
    },
  },
  watch: {
    checkLoeschen() {
      this.validateLoeschenBestaetigen();
    },
  },
  mounted() {
    this.$emit('view-geladen');
  },
};
</script>

<style scoped>
#loeschen-check-div {
  margin: 20px 0;
}

.checkbox-transformation {
  vertical-align: top;
  transform: translateY(-1px);
}

.loeschen-check-text {
  padding: 0;
}

.card-grau {
  width: auto;
  height: auto;
  margin-left: 0;
  background-color: #EDF0F3;
  text-align: left;
  border-radius: 0;
  border-color: #EDF0F3;
  padding: 0;
}

.card-body {
  padding: 30px 15px;
}

#weiter-btn {
  margin-right: 15px;
}

#weiter-btn:disabled {
  opacity: 0.5;
}

@media (max-width: 576px) {
  .card-grau {
    margin-bottom: 30px;
  }
}
</style>
