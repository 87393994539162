<template>
  <div>
    <hr class="header-border">
    <div class="container main-content">
      <div class="row abstand-unten-40">
        <div class="col-xl-2 col-1"></div>
        <div class="col-xl-8 col-10">
          <prozess-schritte :mandant="mandant" :current-step="4"></prozess-schritte>
        </div>
        <div class="col-xl-2 col-1"></div>
      </div>
      <div class="row">
      <div class="col-xl-2 col-1"></div>
        <div class="col-xl-8 col-10">
          <iframe id="nect-prozess-iframe"
                  :src="`${iframePath}`"
                  title="Nect Prozess"
                  sandbox="allow-scripts
                   allow-forms
                   allow-same-origin
                   allow-top-navigation
                   allow-popups
                   allow-popups-to-escape-sandbox">
          </iframe>
        </div>
        <div class="col-xl-2 col-1"></div>
      </div>
    </div>
  </div>

</template>

<script>
import ProzessSchritte from '@/components/ProzessSchritte.vue';
import mandant from '@/mandant';
import api from '@/backend-api';
import Vue from 'vue';

export default {
  name: 'Nect',
  mixins: [mandant],
  data() {
    return {
      path: window.location.origin,
      cUUID: '',
      nectErreichbarkeit: false,
      iframePath: '',
    };
  },
  components: {
    ProzessSchritte,
  },
  methods: {
    nectSuccess(event) {
      // ToDo Auf Sicherheitsaspekte prüfen
      if (event.origin !== window.location.origin) {
        return;
      }
      if (event.data === 'Nect erfolgreich abgeschlossen') {
        this.weiter();
      }
    },
    async weiter() {
      this.$router.push({ name: 'Zusammenfassung' });
    },
    getIframePath(isNectErreichbar) {
      const path = this.path.concat('/#');
      if (isNectErreichbar) {
        return path.concat('/nect-start/', this.cUUID);
      }
      return path.concat('/nect-wartungsfenster');
    },
  },
  mounted() {
    window.addEventListener('message', this.nectSuccess);
    this.$emit('view-geladen');
  },
  beforeDestroy() {
    window.removeEventListener('message', this.nectSuccess);
  },
  async beforeMount() {
    this.$root.$refs.app.setZurueckFeld(false);
    await api.istNectErreichbar(Vue.$keycloak.token)
      .then((response) => {
        this.nectErreichbarkeit = response.data;
      });
    if (this.nectErreichbarkeit === true) {
      await api.legeNectCaseAn(Vue.$keycloak.token)
        .then((response) => {
          if (response.status === 204) {
            this.weiter();
          }
          this.cUUID = response.data;
        }).catch((error) => {
          console.log('Nect Case not created!', error);
        });
      this.iframePath = this.getIframePath(true);
    } else {
      this.iframePath = this.getIframePath(false);
      console.log('Nect Service ist nicht erreichbar!');
    }
  },
};
</script>

<style scoped>
#nect-prozess-iframe {
  border-width: 1px;
  border-style: ridge;
  border-color: #EDF0F3;
  width: 100%;
  height: 915px;
  overflow: hidden;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.header-border {
  border-color: #EDF0F3;
  width: 100vw;
  border-style: inset;
  margin-bottom: 0;
  margin-top: 0;
}

.main-content {
  max-width: 1140px;
}

.abstand-unten-40 {
  margin-bottom: 40px;
}

/* Tablet View */
@media (max-width: 1200px) {
  #nect-prozess-iframe {
    width: 100%;
  }
}

</style>
