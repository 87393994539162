<template>
  <div>
    <sicherheitsabfrage
      v-show="showAbfrage"
      typ="verwerfen"
      :titel="titelAbfrage"
      :text="textAbfrage"
      @zustimmung="zurueck"
      @abbruch="closeAbfrage"
      @ablehnung="closeAbfrage">
    </sicherheitsabfrage>
    <sicherheitsabfrage
      v-if="verlasseSeiteAbfrage"
      typ="verlassen"
      @abbruch="schliesseAbfrage"
      @ablehnung="schliesseAbfrage"
      @zustimmung="behandleSeiteVerlassen">
    </sicherheitsabfrage>
    <div class="container main-content">
      <slot name="benachrichtigung"></slot>
      <div class="row g-3">
        <div class="col-xl-8">
          <h1 class="abstand-unten-30">Dokument einreichen</h1>
          <p class="copy_16 abstand-unten-30">
            Hier können Sie uns Dokumente zu Ihrem Vertrag zusenden.
          </p>
          <div class="row">
            <div class="col-12 abstand-unten-17">
              <label class="aufschrift copy_bold_12" for="betreff">Betreff *</label>
              <input class="eingabe copy_16"
                     :class="{'fehler': betreffFehler===true,
                        'fehler-oesa': betreffFehler===true && mandant === 3,
                        'vgh-eingabe': mandant === 1,
                        'oesa-eingabe': mandant === 3,
                        'oevo-eingabe': mandant === 5,
                        'fehler-oevo': betreffFehler===true && mandant === 5}"
                     v-model="betreffEingabe"
                     maxlength="50"
                     id="betreff">
              <label
                v-if="betreffFehler"
                class="fehler-text copy_12">
                {{ betreffFehlermeldung }}
              </label>
            </div>
            <div class="col-12">
              <div class="col-12" id="upload-hinweis">
                <hinweis label="Dokument hochladen *" right="0">
                  Zulässige Dateiformate sind PDF, JPG, JPEG und PNG. <br>
                  Die maximale Uploadgröße ist 12 MB.
                </hinweis>
              </div>
              <upload
                v-on:dateienSenden="dateienAnnehmen"
                class="abstand-unten-15"
                ref="upload"
                upload-bezeichnung="Dokument"
                @dateiGeloescht="showUploadFehler = false"
                columnSize="col-12"
                id="upload"
              >
              </upload>
            </div>
            <div class="col-12 abstand-unten-15">
              <textarea
                class="eingabe copy_16"
                :class="{'fehler': textFehler,
                        'fehler-oesa': textFehler && mandant === 3,
                        'vgh-eingabe': mandant === 1,
                        'oesa-eingabe': mandant === 3,
                        'oevo-eingabe': mandant === 5,
                        'fehler-oevo': textFehler && mandant === 5}"
                v-model="textEingabe"
                maxlength="250"
                placeholder="Möchten Sie uns noch etwas zum Dokument mitteilen?"
                @blur="validateMessage"
                id="nachricht"
              ></textarea>
              <label class="zaehler-label copy_12">
                {{textEingabe.length}} / 250 Zeichen
              </label>
              <label
                v-if="textFehler===true"
                class="fehler-text copy_12">
                Bitte achten Sie auf Sonderzeichen.
              </label>
            </div>
            <div class="col-12">
              <label id="pflicht-label" class="copy_12">Pflichtfeld*</label>
            </div>
          </div>
        </div>
      </div>
      <div class="row g-3 abstand-unten-15">
        <div class="col-12">
          <input type="button"
                 id="senden-btn"
                 class="button-ivv white"
                 :class="{'button-vgh': mandant === 1,
                    'button-oesa': mandant === 3,
                    'button-oevo': mandant === 5}"
                 value="Senden"
                 :disabled="sendenButtonDisabled"
                 @click="sendeDokument"
          >
          <input type="button"
                 id="verwerfen-btn"
                 class="button-ivv"
                 value="Verwerfen"
                 @click="showAbfrage = true"
          >
          <backend-fehler :api-fehler="apiFehler" :backend-fehler="backendFehler"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/backend-api';
import Sicherheitsabfrage from '@/components/Sicherheitsabfrage.vue';
import Upload from '@/components/Upload.vue';
import isoUtil from '@/isoUtil';
import sicherheitsabfrage from '@/sicherheitsabfrage';
import bestaetigungUtil from '@/bestaetigungUtil';
import mandant from '@/mandant';
import BackendFehler from '@/components/BackendFehler.vue';
import Hinweis from '@/components/Hinweis.vue';
import Vue from 'vue';

export default {
  name: 'DokumentEinreichen',
  mixins: [isoUtil, sicherheitsabfrage, bestaetigungUtil, mandant],
  props: ['vertreter', 'vsnr', 'laufendenr', 'produktkey'],
  components: {
    BackendFehler,
    Hinweis,
    Sicherheitsabfrage,
    Upload,
  },
  data() {
    return {
      sendenButtonDisabled: false,
      vertreternummer: '',
      hauptvertreternummer: '',

      betreffEingabe: '',
      textEingabe: '',

      betreffFehler: false,
      textFehler: false,

      apiFehler: false,
      backendFehler: false,

      showUploadFehler: false,
      betreffFehlermeldung: '',
      showAbfrage: false,
      titelAbfrage: 'Möchten Sie Ihren Entwurf wirklich verwerfen?',
      textAbfrage: 'Ihr bisheriger Entwurf und Ihre hochgeladenen Anhänge werden gelöscht.',
      dateien: [],
    };
  },
  methods: {
    async sendeDokument() {
      try {
        this.backendFehler = false;
        if (this.validierung()) {
          this.sendenButtonDisabled = true;
          const formData = new FormData();
          this.dateien.forEach((element) => {
            const currentFile = new File([element], encodeURI(element.name.normalize('NFC')), {
              type: element.type,
            });
            formData.append('file', currentFile);
          });
          formData.append('betreff', this.betreffEingabe);
          formData.append('inhalt', this.textEingabe);
          formData.append('versicherungsscheinnummer', this.vsnr);
          formData.append('laufendenr', this.laufendenr);
          formData.append('produktkey', this.produktkey);
          formData.append('vertreternummer', this.vertreternummer);
          formData.append('hauptvertreternummer', this.hauptvertreternummer);
          await api.sendeDokument(
            formData,
            Vue.$keycloak.token,
          );
          this.$store.dispatch('ladePostfachstatus');
          this.verlasseSeite = true;
          this.$router.push({
            path: `/vertragsdetails/${this.vsnr}/${this.laufendenr}/${this.produktkey}`,
            name: 'Vertragsdetails',
            params: {
              bestaetigung: 'dokument',
            },
          });
        }
      } catch (e) {
        this.sendenButtonDisabled = false;
        if (e.response.status === 429) {
          this.apiFehler = true;
        } else {
          this.backendFehler = true;
        }
        console.log(e);
      }
    },
    async getVertrag() {
      this.vertrag = await api.getEinzelVertrag(
        this.vsnr,
        this.laufendenr,
        this.produktkey,
        Vue.$keycloak.token,
      )
        .then((response) => response.data)
        .then((vertrag) => vertrag)
        .catch((error) => {
          console.log(error);
        });
      this.vertreternummer = this.vertrag.bestandsvertreter.vertreternummer;
      this.hauptvertreternummer = this.vertrag.bestandsvertreter.hauptvertreternummer;
    },
    dateienAnnehmen(daten) {
      this.dateien = daten;
    },
    zurueck() {
      this.verlasseSeite = true;
      this.$router.back();
    },
    eingegeben() {
      return this.betreffEingabe || this.textEingabe || this.dateien.length > 0;
    },
    closeAbfrage() {
      this.showAbfrage = false;
    },
    validierung() {
      this.validateBetreff(true);
      this.validateMessage();
      this.validateUpload();
      return !(this.textFehler || this.betreffFehler || this.dateien.length === 0);
    },
    validateUpload() {
      if (this.dateien.length === 0) {
        this.$refs.upload.keineDateien('Bitte laden Sie ein Dokument hoch.');
      }
    },
    validateBetreff(istLeerCheck) {
      if (!this.betreffEingabe && istLeerCheck) {
        this.betreffFehlermeldung = 'Bitte geben Sie einen Betreff ein.';
        this.betreffFehler = true;
      } else if (!this.checkISO(this.betreffEingabe)) {
        this.betreffFehlermeldung = 'Bitte auf Sonderzeichen achten.';
        this.betreffFehler = true;
      } else {
        this.betreffFehler = false;
      }
    },
    validateMessage() {
      if (!this.checkISO(this.textEingabe)) {
        this.textFehler = true;
      } else {
        this.textFehler = false;
      }
    },
    setZurueckFeld() {
      this.$root.$refs.app.setZurueckFeld(true);
    },
  },
  mounted() {
    this.$emit('view-geladen');
  },
  beforeMount() {
    this.setZurueckFeld();
  },
  async created() {
    await this.getVertrag();
  },

  watch: {
    betreffEingabe() {
      this.validateBetreff(false);
    },
    textEingabe() {
      this.validateMessage();
    },
  },
};
</script>

<style scoped>
#nachricht {
  height: 100px;
  resize: none;
}

#senden-btn {
  margin-right: 15px;
}

#senden-btn:disabled {
  background: #0558F0;
  opacity: 0.5;
}

#pflicht-label {
  color: #6B7171;
  margin: 19px 0 30px 0;
}

.zaehler-label {
  display: flex;
  justify-content: flex-end;
  color: #6B7171;
}

#upload-hinweis{
  padding: 0;
}

.aufschrift {
  margin-bottom: 6px;
  white-space: nowrap;
}

.eingabe {
  padding-left: 15px;
}

textarea.eingabe {
  padding-top: 9px;
}

.fehler:focus {
  outline: none !important;
}

@media (max-width: 576px) {
  #upload-hinweis {
    width: 100%;
  }
}
</style>
