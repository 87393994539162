var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('sicherheitsabfrage',{directives:[{name:"show",rawName:"v-show",value:(_vm.showAbfrage),expression:"showAbfrage"}],attrs:{"typ":"verwerfen","titel":_vm.titelAbfrage,"text":_vm.textAbfrage},on:{"zustimmung":_vm.verwerfenZurueck,"abbruch":_vm.closeAbfrage,"ablehnung":_vm.closeAbfrage}}),(_vm.verlasseSeiteAbfrage)?_c('sicherheitsabfrage',{attrs:{"typ":"verlassen"},on:{"zustimmung":_vm.behandleSeiteVerlassen,"ablehnung":_vm.schliesseAbfrage,"abbruch":_vm.schliesseAbfrage}}):_vm._e(),(_vm.zusammenfassung)?_c('div',{staticClass:"card card-grau",attrs:{"id":"rechteck"}},[_c('div',{staticClass:"container"},[_c('a',{staticClass:"copy_16 pfeil-btn text-link",class:{'text-link-vgh': _vm.mandant === 1,
            'text-link-oesa': _vm.mandant === 3,
            'text-link-oevo': _vm.mandant === 5},on:{"click":_vm.zurueck}},[_c('em',{staticClass:"fa-solid fa-chevron-left"}),_vm._v("Zurück ")])])]):_vm._e(),_c('div',{staticClass:"container main-content"},[_vm._t("benachrichtigung"),_c('div',{staticClass:"row g-3"},[_c('div',{staticClass:"col-xl-8 mb-5"},[_c('h1',{staticClass:"abstand-unten-30"},[_vm._v("Persönliche Daten ändern ")]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.zusammenfassung),expression:"!zusammenfassung"}]},[_c('div',{staticClass:"card card-grau abstand-60 col-md-9 col-12 abstand-unten-30"},[_c('div',{staticClass:"card-body"},[_c('p',{staticClass:"copy_bold_16"},[_vm._v("Meine aktuellen Daten")]),_c('p',{staticClass:"copy_16"},[_vm._v(" "+_vm._s(_vm.partner.titel)+" "+_vm._s(_vm.partner.vorname)+" "+_vm._s(_vm.partner.namensvorsatz)+" "+_vm._s(_vm.partner.name)+" ")])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-9 col-12"},[_c('div',{staticClass:"row abstand-unten-22"},[_c('h2',{staticClass:"abstand-unten-32 col-12"},[_vm._v("Bitte geben Sie Ihre neuen Daten ein")]),_c('div',{staticClass:"col-md-6  col-12 col-kalibrieren"},[_c('label',{staticClass:"aufschrift copy_bold_12"},[_vm._v("Anrede*")]),_c('div',{staticClass:"col-12 copy_16 dropdown"},[_c('button',{staticClass:"copy_16 dropdown-button",class:{'fehler': !_vm.anredeAusgewaehlt && _vm.mandant === 1,
                            'fehler-oesa': !_vm.anredeAusgewaehlt && _vm.mandant === 3,
                            'fehler-oevo': !_vm.anredeAusgewaehlt && _vm.mandant === 5},on:{"click":_vm.toggleAnredeDropdown}},[_c('label',{staticClass:"copy_16",attrs:{"id":"anrede"}},[_vm._v(_vm._s(_vm.anrede))]),_c('em',{staticClass:"fa-solid fa-chevron-down",class:{'oesa-gruen-color': _vm.mandant === 3,
                          'oevo-blau-color': _vm.mandant === 5}})]),_c('ul',{directives:[{name:"show",rawName:"v-show",value:(_vm.showAnredeDropdown),expression:"showAnredeDropdown"}],staticClass:"dropdown-inhalt"},[_c('li',{staticClass:"dropdown-auswahl",class:{'vgh-blau-bg-hover': _vm.mandant === 1,
                           'oesa-gruen-bg-hover': _vm.mandant === 3,
                          'oevo-blau-bg-hover': _vm.mandant === 5},on:{"click":function($event){return _vm.anredeAuswahl('Herr')}}},[_vm._v(" Herr ")]),_c('li',{staticClass:"dropdown-auswahl",class:{'vgh-blau-bg-hover': _vm.mandant === 1,
                          'oesa-gruen-bg-hover': _vm.mandant === 3,
                          'oevo-blau-bg-hover': _vm.mandant === 5},on:{"click":function($event){return _vm.anredeAuswahl('Frau')}}},[_vm._v(" Frau ")]),_c('li',{staticClass:"dropdown-auswahl",class:{'vgh-blau-bg-hover': _vm.mandant === 1,
                          'oesa-gruen-bg-hover': _vm.mandant === 3,
                          'oevo-blau-bg-hover': _vm.mandant === 5},attrs:{"id":"auswahlAusPartnerdaten"},on:{"click":function($event){return _vm.anredeAuswahl(_vm.partner.anrede)}}},[_vm._v(" "+_vm._s(this.partner.anrede)+" ")])]),(!_vm.anredeAusgewaehlt)?_c('label',{staticClass:"fehler-text copy_bold_12",class:{'fehler-text-oesa': _vm.mandant === 3,
                        'fehler-text-oevo': _vm.mandant === 5}},[_vm._v(" Bitte wählen Sie eine Anrede ")]):_vm._e()])]),_c('div',{staticClass:"col-md-6 col-12 col-kalibrieren"}),_c('div',{staticClass:"col-md-6  col-12 col-kalibrieren"},[_c('label',{staticClass:"aufschrift copy_bold_12"},[_vm._v("Vorname*")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.vornameEingabe),expression:"vornameEingabe"}],staticClass:"eingabe copy_16",class:{'fehler': _vm.vornameFehler && _vm.mandant === 1,
                      'fehler-oesa': _vm.vornameFehler && _vm.mandant === 3,
                      'vgh-eingabe': _vm.mandant === 1,
                      'oesa-eingabe': _vm.mandant === 3,
                      'oevo-eingabe': _vm.mandant === 5,
                      'fehler-oevo': _vm.vornameFehler && _vm.mandant === 5},attrs:{"maxlength":"60","id":"vorname"},domProps:{"value":(_vm.vornameEingabe)},on:{"input":function($event){if($event.target.composing){ return; }_vm.vornameEingabe=$event.target.value}}}),(_vm.vornameFehler)?_c('label',{staticClass:"fehler-text copy_bold_12",class:{'fehler-text-oesa': _vm.mandant === 3,
                      'fehler-text-oevo': _vm.mandant === 5}},[_vm._v(" "+_vm._s(_vm.vornameFehlerText)+" ")]):_vm._e()]),_c('div',{staticClass:"col-md-6 col-12 col-kalibrieren"},[_c('label',{staticClass:"aufschrift copy_bold_12"},[_vm._v("Nachname*")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.nachnameEingabe),expression:"nachnameEingabe"}],staticClass:"eingabe copy_16",class:{'fehler': _vm.nachnameFehler && _vm.mandant === 1,
                      'fehler-oesa': _vm.nachnameFehler && _vm.mandant === 3,
                      'vgh-eingabe': _vm.mandant === 1,
                      'oesa-eingabe': _vm.mandant === 3,
                      'oevo-eingabe': _vm.mandant === 5,
                      'fehler-oevo': _vm.nachnameFehler && _vm.mandant === 5},attrs:{"maxlength":"60","id":"nachname"},domProps:{"value":(_vm.nachnameEingabe)},on:{"input":function($event){if($event.target.composing){ return; }_vm.nachnameEingabe=$event.target.value}}}),(_vm.nachnameFehler)?_c('label',{staticClass:"fehler-text copy_bold_12",class:{'fehler-text-oesa': _vm.mandant === 3,
                      'fehler-text-oevo': _vm.mandant === 5}},[_vm._v(" "+_vm._s(_vm.nachnameFehlerText)+" ")]):_vm._e()])])])]),_c('div',{staticClass:"col-md-9 col-12",attrs:{"id":"upload-hinweis"}},[_c('hinweis',{attrs:{"label":"Amtlicher Nachweis*","right":"9"}},[_vm._v(" Als Nachweis der Änderung Ihrer persönlichen Daten benötigen wir eine Kopie eines amtlichen Dokumentes z.B. Heiratsurkunde, Scheidungsurkunde, Personalausweis. "),_c('br'),_vm._v(" Zulässige Dateiformate sind PDF, JPG, JPEG und PNG. "),_c('br'),_vm._v(" Die maximale Uploadgröße ist 12 MB. ")])],1),_c('upload',{ref:"upload",attrs:{"columnSize":"col-9","upload-bezeichnung":"Anhang","id":"upload"},on:{"dateienSenden":_vm.dateienAnnehmen}}),(!_vm.dateienAusgewaehlt)?_c('label',{staticClass:"row fehler-text copy_bold_12",class:{'fehler-text-oesa': _vm.mandant === 3,
                  'fehler-text-oevo': _vm.mandant === 5},attrs:{"id":"upload-label"}},[_vm._v(" Bitte laden Sie einen Nachweis hoch. ")]):_vm._e(),_c('label',{staticClass:"copy_12",attrs:{"id":"pflicht-label"}},[_vm._v("Pflichtfeld*")]),(_vm.keineAenderung)?_c('div',{staticClass:"row g-3"},[_c('label',{staticClass:"fehler-text copy_bold_12 col-12",class:{'fehler-text-oesa': _vm.mandant === 3,
              'fehler-text-oevo': _vm.mandant === 5}},[_vm._v(" Es wurde keine Änderung hinterlegt. ")])]):_vm._e(),_c('div',[_c('input',{staticClass:"button-ivv white",class:{'button-vgh': _vm.mandant === 1,
                   'button-oesa': _vm.mandant === 3,
                   'button-oevo': _vm.mandant === 5},attrs:{"type":"button","value":"Weiter","id":"weiter-btn"},on:{"click":_vm.zurZusammenfassung}}),_c('input',{staticClass:"button-ivv",attrs:{"type":"button","id":"verwerfen-btn","value":"Verwerfen"},on:{"click":function($event){_vm.showAbfrage = true}}})])],1),(_vm.zusammenfassung)?_c('name-zusammenfassung',{ref:"zusammenfassung",attrs:{"anrede":_vm.anrede,"titel":_vm.titelEingabe,"vorname":_vm.vornameEingabe,"nachname":_vm.nachnameEingabe,"dateien":_vm.dateien},on:{"absenden":_vm.dateienAbsenden}}):_vm._e()],1),_c('div',{staticClass:"col-xl-4"},[_c('vertreter-info',{attrs:{"ueberschrift":"Ich helfe Ihnen gerne bei der Änderung Ihrer Daten weiter.","mail-adresse":true,"nachricht":true,"vertrLink":true,"termin":true,"social-media":true,"vertreter":_vm.vertreter}})],1)])],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }