var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('sicherheitsabfrage',{directives:[{name:"show",rawName:"v-show",value:(_vm.showAbfrage),expression:"showAbfrage"}],attrs:{"typ":"verwerfen","titel":_vm.titelAbfrage,"text":_vm.textAbfrage},on:{"zustimmung":_vm.zurueck,"abbruch":_vm.closeAbfrage,"ablehnung":_vm.closeAbfrage}}),(_vm.verlasseSeiteAbfrage)?_c('sicherheitsabfrage',{attrs:{"typ":"verlassen"},on:{"zustimmung":_vm.behandleSeiteVerlassen,"ablehnung":_vm.schliesseAbfrage,"abbruch":_vm.schliesseAbfrage}}):_vm._e(),_c('div',[(_vm.loading)?_c('div',[_c('div',{staticClass:"spinner-border",class:{'vgh-blau-color': _vm.mandant === 1,
            'oesa-gruen-color': _vm.mandant === 3,
            'oevo-blau-color': _vm.mandant === 5},attrs:{"role":"status"}},[_c('span',{staticClass:"sr-only"},[_vm._v("Loading...")])])]):_c('div',{staticClass:"container main-content"},[_vm._t("benachrichtigung"),_c('div',{staticClass:"row g-3"},[_c('div',{staticClass:"col-xl-8 abstand-unten-30"},[_c('h1',{staticClass:"abstand-unten-30"},[_vm._v("Beratungstermin vereinbaren")]),_c('h2',{staticClass:"abstand-unten-32"},[_vm._v("Mein Terminwunsch an "+_vm._s(_vm.vertragVertreter.name))]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 abstand-unten-60"},[_c('label',{staticClass:"aufschrift copy_bold_12"},[_vm._v("Wie können wir Ihnen helfen? *")]),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.nachricht),expression:"nachricht"}],staticClass:"eingabe copy_16",class:{'fehler': _vm.nachrichtFehler && _vm.mandant === 1,
                      'fehler-oesa': _vm.nachrichtFehler && _vm.mandant === 3,
                      'vgh-eingabe': _vm.mandant === 1,
                      'oesa-eingabe': _vm.mandant === 3,
                      'oevo-eingabe': _vm.mandant === 5,
                      'fehler-oevo': _vm.nachrichtFehler && _vm.mandant === 5},attrs:{"maxlength":"1200","id":"nachricht"},domProps:{"value":(_vm.nachricht)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.nachricht=$event.target.value},function($event){return _vm.validateMessage(false)}]}}),_c('label',{staticClass:"zaehler-label copy_12"},[_vm._v(" "+_vm._s(_vm.nachricht.length)+" / 1200 Zeichen ")]),(_vm.errors.nachricht)?_c('label',{staticClass:"fehler-text copy_bold_12",class:{'fehler-text-oesa': _vm.mandant === 3,
                  'fehler-text-oevo': _vm.mandant === 5}},[_vm._v(" "+_vm._s(_vm.errors.nachricht)+" ")]):_vm._e()]),_c('div',{staticClass:"col-12 abstand-unten-30"},[_c('label',{staticClass:"aufschrift copy_bold_12"},[_vm._v("Wie können wir Sie erreichen? *")]),_c('div',{staticClass:"row copy_16",attrs:{"id":"kontaktaufnahme"}},[_c('div',{staticClass:"col-12"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.kontaktart.telefon),expression:"kontaktart.telefon"}],class:{'input-vgh': _vm.mandant === 1,
                            'input-oesa': _vm.mandant === 3,
                            'input-oevo': _vm.mandant === 5},attrs:{"type":"checkbox","id":"telefon"},domProps:{"checked":Array.isArray(_vm.kontaktart.telefon)?_vm._i(_vm.kontaktart.telefon,null)>-1:(_vm.kontaktart.telefon)},on:{"change":[function($event){var $$a=_vm.kontaktart.telefon,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.kontaktart, "telefon", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.kontaktart, "telefon", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.kontaktart, "telefon", $$c)}},function($event){return _vm.validateKontaktart(false)}]}}),_c('label',{attrs:{"for":"telefon"}}),_vm._v("telefonisch "),(_vm.kontaktart.telefon)?_c('span',[_vm._v("unter")]):_vm._e()]),(_vm.kontaktart.telefon)?_c('div',{attrs:{"id":"auswahl-telefon-zeiten"}},[_c('div',{staticClass:"col-12 abstand-unten-15"},[_c('div',[_c('div',{staticClass:"eingabe-form copy_16",class:{'eingabe-fehler': _vm.handynummerFehler,
                                    'vgh-eingabe-form': _vm.mandant === 1,
                                    'oesa-eingabe-form': _vm.mandant === 3,
                                    'oevo-eingabe-form': _vm.mandant === 5,
                                     'fehler-oevo': _vm.handynummerFehler && _vm.mandant === 5}},[_c('span',{staticClass:"dropdown dropdown-button",on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.toggleVorwahlnummerDropdown.apply(null, arguments)}}},[(_vm.vorwahlnummer === '+49')?_c('span',{staticClass:"fi fi-de dropdown-bild"}):_vm._e(),(_vm.vorwahlnummer === '+31')?_c('span',{staticClass:"fi fi-nl dropdown-bild"}):_vm._e(),_c('em',{staticClass:"fa-solid fa-chevron-down"})]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.telefonnummerEingabe),expression:"telefonnummerEingabe"}],staticClass:"eingabe eingabe-handynummer copy_16",class:{'eingabe-fehler': _vm.errors.telefonnummer,
                               'vgh-eingabe': _vm.mandant === 1,
                               'oesa-eingabe': _vm.mandant === 3,
                               'oevo-eingabe': _vm.mandant === 5,
                               'fehler-oevo': _vm.errors.telefonnummer && _vm.mandant === 5},staticStyle:{"border":"none"},attrs:{"maxlength":"15","id":"telefonnummer"},domProps:{"value":(_vm.telefonnummerEingabe)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.telefonnummerEingabe=$event.target.value},function($event){_vm.errors.telefonnummer=false}],"keypress":_vm.nurZahlen,"paste":_vm.behandleHandynummerEinfuegen}})]),_c('ul',{directives:[{name:"show",rawName:"v-show",value:(_vm.showVorwahlnummerDropdown),expression:"showVorwahlnummerDropdown"}],staticClass:"dropdown-inhalt"},[_c('li',{staticClass:"dropdown-auswahl copy_12",class:{'vgh-blau-bg-hover dropdown-hover-text': _vm.mandant === 1,
                           'oesa-gruen-bg-hover dropdown-hover-text': _vm.mandant === 3,
                          'oevo-blau-bg-hover dropdown-hover-text': _vm.mandant === 5},on:{"click":function($event){return _vm.vorwahlnummerAuswahl('+49')}}},[_c('span',{staticClass:"fi fi-de dropdown-bild"}),_vm._v("   Deutschland +49 ")]),_c('li',{staticClass:"dropdown-auswahl copy_12",class:{'vgh-blau-bg-hover dropdown-hover-text': _vm.mandant === 1,
                          'oesa-gruen-bg-hover dropdown-hover-text': _vm.mandant === 3,
                          'oevo-blau-bg-hover dropdown-hover-text': _vm.mandant === 5},on:{"click":function($event){return _vm.vorwahlnummerAuswahl('+31')}}},[_c('span',{staticClass:"fi fi-nl dropdown-bild"}),_vm._v("   Niederlande +31 ")])])]),(_vm.errors.telefonnummer)?_c('label',{staticClass:"aufschrift fehler-text copy_bold_12",class:{'fehler-text-oesa': _vm.mandant === 3,
                              'fehler-text-oevo': _vm.mandant === 5}},[_vm._v(" Bitte geben Sie eine gültige "+_vm._s(_vm.vorwahlnummer === '+49' ? 'deutsche' : 'niederländische')+" Handynummer ein. ")]):_vm._e()]),_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"copy_16"},[_vm._m(0),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 erreichbarkeit"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.zeitraum.vormittag),expression:"zeitraum.vormittag"}],class:{'input-vgh': _vm.mandant === 1,
                                  'input-oesa': _vm.mandant === 3,
                                  'input-oevo': _vm.mandant === 5},attrs:{"type":"checkbox","id":"cMorgen","required":""},domProps:{"checked":Array.isArray(_vm.zeitraum.vormittag)?_vm._i(_vm.zeitraum.vormittag,null)>-1:(_vm.zeitraum.vormittag)},on:{"change":[function($event){var $$a=_vm.zeitraum.vormittag,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.zeitraum, "vormittag", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.zeitraum, "vormittag", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.zeitraum, "vormittag", $$c)}},function($event){return _vm.validateZeitraum(false)}]}}),_c('label',{attrs:{"for":"cMorgen"}}),_vm._v(" 09:00 - 12:00 Uhr ")]),_c('div',{staticClass:"col-12 erreichbarkeit"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.zeitraum.mittag),expression:"zeitraum.mittag"}],class:{'input-vgh': _vm.mandant === 1,
                                  'input-oesa': _vm.mandant === 3,
                                  'input-oevo': _vm.mandant === 5},attrs:{"type":"checkbox","id":"cMittag"},domProps:{"checked":Array.isArray(_vm.zeitraum.mittag)?_vm._i(_vm.zeitraum.mittag,null)>-1:(_vm.zeitraum.mittag)},on:{"change":[function($event){var $$a=_vm.zeitraum.mittag,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.zeitraum, "mittag", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.zeitraum, "mittag", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.zeitraum, "mittag", $$c)}},function($event){return _vm.validateZeitraum(false)}]}}),_c('label',{attrs:{"for":"cMittag"}}),_vm._v(" 12:00 - 15:00 Uhr ")]),_c('div',{staticClass:"col-12 erreichbarkeit"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.zeitraum.nachmittag),expression:"zeitraum.nachmittag"}],class:{'input-vgh': _vm.mandant === 1,
                                  'input-oesa': _vm.mandant === 3,
                                  'input-oevo': _vm.mandant === 5},attrs:{"type":"checkbox","id":"cAbend"},domProps:{"checked":Array.isArray(_vm.zeitraum.nachmittag)?_vm._i(_vm.zeitraum.nachmittag,null)>-1:(_vm.zeitraum.nachmittag)},on:{"change":[function($event){var $$a=_vm.zeitraum.nachmittag,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.zeitraum, "nachmittag", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.zeitraum, "nachmittag", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.zeitraum, "nachmittag", $$c)}},function($event){return _vm.validateZeitraum(false)}]}}),_c('label',{attrs:{"for":"cAbend"}}),_vm._v(" 15:00 - 18:00 Uhr ")])])]),(_vm.errors.zeitraum)?_c('label',{staticClass:"fehler-text copy_bold_12",class:{'fehler-text-oesa': _vm.mandant === 3,
                  'fehler-text-oevo': _vm.mandant === 5}},[_vm._v(" "+_vm._s(_vm.errors.zeitraum)+" ")]):_vm._e()])]):_vm._e(),_c('div',{staticClass:"col-12"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.kontaktart.email),expression:"kontaktart.email"}],class:{'input-vgh': _vm.mandant === 1,
                            'input-oesa': _vm.mandant === 3,
                            'input-oevo': _vm.mandant === 5},attrs:{"type":"checkbox","id":"email"},domProps:{"checked":Array.isArray(_vm.kontaktart.email)?_vm._i(_vm.kontaktart.email,null)>-1:(_vm.kontaktart.email)},on:{"change":[function($event){var $$a=_vm.kontaktart.email,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.kontaktart, "email", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.kontaktart, "email", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.kontaktart, "email", $$c)}},function($event){return _vm.validateKontaktart(false)}]}}),_c('label',{attrs:{"for":"email"}}),_vm._v("per E-Mail ")]),_c('div',{staticClass:"col-12"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.kontaktart.kundenserviceportal),expression:"kontaktart.kundenserviceportal"}],class:{'input-vgh': _vm.mandant === 1,
                            'input-oesa': _vm.mandant === 3,
                            'input-oevo': _vm.mandant === 5},attrs:{"type":"checkbox","id":"ksp"},domProps:{"checked":Array.isArray(_vm.kontaktart.kundenserviceportal)?_vm._i(_vm.kontaktart.kundenserviceportal,null)>-1:(_vm.kontaktart.kundenserviceportal)},on:{"change":[function($event){var $$a=_vm.kontaktart.kundenserviceportal,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.kontaktart, "kundenserviceportal", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.kontaktart, "kundenserviceportal", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.kontaktart, "kundenserviceportal", $$c)}},function($event){return _vm.validateKontaktart(false)}]}}),_c('label',{attrs:{"for":"ksp"}}),_vm._v("per Kundenportal ")])]),(_vm.errors.kontaktart)?_c('label',{staticClass:"fehler-text copy_bold_12",class:{'fehler-text-oesa': _vm.mandant === 3,
                  'fehler-text-oevo': _vm.mandant === 5}},[_vm._v(" "+_vm._s(_vm.errors.kontaktart)+" ")]):_vm._e()]),_vm._m(1)]),_c('div',{staticClass:"row g-3"},[_c('div',{staticClass:"col-12"},[_c('input',{staticClass:"button-ivv white",class:{'button-vgh': _vm.mandant === 1,
                        'button-oesa': _vm.mandant === 3,
                        'button-oevo': _vm.mandant === 5},attrs:{"type":"button","id":"senden-btn","value":"Senden","disabled":_vm.sendenButtonDisabled},on:{"click":_vm.sendeTerminanfrage}}),_c('input',{staticClass:"button-ivv",attrs:{"type":"button","id":"verwerfen-btn","value":"Verwerfen"},on:{"click":function($event){_vm.showAbfrage = true}}})])]),_c('backend-fehler',{attrs:{"api-fehler":_vm.apiFehler,"backend-fehler":_vm.backendFehler}})],1)])],2)])],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('label',{staticClass:"aufschrift copy_bold_12"},[_vm._v("Wann können wir Sie erreichen? * "),_c('br'),_vm._v(" Am besten können Sie mich Mo-Fr. zu folgenden Zeiten erreichen: ")])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-12"},[_c('label',{staticClass:"copy_12",attrs:{"id":"pflicht-label"}},[_vm._v("Pflichtfeld*")])])}]

export { render, staticRenderFns }