<template>
  <div>
    <div class="container main-content">
      <div v-if="mandant === 1" id="logo-container-vgh">
        <div id="logo-vgh" class="col-2 col-md-1">
          <div id="vgh-logo-container">
            <img
              id="vgh-logo"
              alt="VGH logo"
              src="@/assets/vgh-logo.png">
          </div>
        </div>
      </div>
      <div v-else-if="mandant === 3" id="logo-container-oesa">
        <div id="logo-oesa" class="col-2 col-md-1">
          <div id="oesa-logo-container">
            <img
              id="oesa-logo"
              alt="OESA logo"
              src="@/assets/Meine_OESA_Logo.svg"
            >
          </div>
        </div>
      </div>
      <div v-else-if="mandant === 5" id="logo-container-oevo">
        <div id="logo-oevo" class="col-2 col-md-1">
          <div id="oevo-logo-container">
            <img
              id="oevo-logo"
              alt="OEVO logo"
              src="@/assets/OEVO-Logo.png"
            >
          </div>
        </div>
      </div>
      <div class="col-12 abstand-oben-30">
        <prozess-schritte :mandant="mandant" :current-step="4"></prozess-schritte>
     </div>
      <div class="header-nect col-xl-8 col-12">
        <h2 class="header-text">Nect Authentifizierung</h2>
      </div>
      <div class="body-nect col-xl-8 col-12">
        <div v-if="success === true">
          <em id="icon-success"
             class="fa-regular fa-3x fa-circle-check"
             :class="{'vgh-blau-color': mandant === 1,
              'oesa-gruen-color': mandant === 3,
              'oevo-blau-color': mandant === 5}"></em>
          <h3>{{ messageHeader }}</h3>
          <p class="copy_16 abstand-unten-40">
            Ihre Authentifizierung mit Nect war erfolgreich und kann auch
            in Zukunft für weitere Vorgänge genutzt werden.
            Ihre Daten sind verschlüsselt und nur innerhalb von Nect gespeichert.
          </p>
          <div class="nect-validierung-btn">
            <div>
              <input type="button"
                     id="nect-weiter-btn"
                     class="button-ivv abstand-unten-17 white"
                     :class="{'button-vgh': mandant === 1,
                        'button-oesa': mandant === 3,
                        'button-oevo': mandant === 5}"
                     :disabled="weiterDisabled"
                     value="Weiter"
                     @click="weiter">
            </div>
          </div>
        </div>
        <div v-if="success === false">
          <em id="icon-failure"
             class="fa-regular fa-3x fa-xmark-circle"></em>
          <h3>{{ messageHeader }}</h3>
          <p class="copy_16 abstand-unten-40">
            Ihre Online-Authentifizierung ist leider fehlgeschlagen.
            Bitte versuchen Sie es erneut oder wenden Sie sich an unser
            <a :href=$store.state.weiterleitungen.faq target="_blank" rel=noopener>
              Supportteam
            </a>
            .
          </p>
          <div class="nect-validierung-btn">
            <div>
              <input type="button"
                     id="nect-neustart-btn"
                     class="button-ivv abstand-unten-17 white"
                     :class="{'button-vgh': mandant === 1,
                        'button-oesa': mandant === 3,
                        'button-oevo': mandant === 5,
                        'button-disabled': nectNeustartButtonDisabled}"
                     :disabled="nectNeustartButtonDisabled"
                     value="Erneut versuchen"
                     @click="neustart">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/backend-api';
import mandant from '@/mandant';
import Vue from 'vue';
import ProzessSchritte from '@/components/ProzessSchritte.vue';

export default {
  name: 'NectValidierungMobile',
  mixins: [mandant],
  props: {
    cUUID: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      success: '',
      messageHeader: '',
      nectNeustartButtonDisabled: false,
      weiterDisabled: true,
    };
  },
  components: {
    ProzessSchritte,
  },
  methods: {
    async neustart() {
      this.nectNeustartButtonDisabled = true;
      await api.legeNectCaseAn(Vue.$keycloak.token)
        .then((responce) => {
          this.cUUID = responce.data;
        }).catch((error) => {
          console.log('Nect Case not created!', error);
        });
      this.nectNeustartButtonDisabled = false;
      this.$router.push({ name: 'NectStart', params: { cUUID: this.cUUID } });
    },
    weiter() {
      window.location = window.location.origin.concat('/#/zusammenfassung');
    },
  },
  mounted() {
    this.$emit('view-geladen');
  },
  beforeMount() {
    if (Vue.$keycloak.tokenParsed.ivv_benutzerstatus === 'aktiv') {
      window.location = window.location.origin;
    } else {
      api.validiereNectCase(Vue.$keycloak.token, this.cUUID)
        .then((responce) => {
          if (responce.data === true) {
            this.success = true;
            this.messageHeader = 'Authentifizierung erfolgreich';
            this.weiterDisabled = false;
          } else {
            this.success = false;
            this.messageHeader = 'Authentifizierung fehlgeschlagen!';
          }
        });
    }
  },
};
</script>

<style scoped>
.abstand-oben-30 {
  margin-top: 30px;
}

.nect-validierung-btn {
  margin-top: 60px;
}

#icon-failure, #icon-success {
  font-size: 48px;
  font-weight: 200;
  margin-bottom: 10px;
}

#icon-failure {
  color: #e30613;
}

.header-nect {
  height: 75px;
  border-bottom-color: #EDF0F3;
  border-bottom-style: groove;
  border-bottom-width: thin;
  margin-top: 40px;
  text-align: center;
}

.header-text{
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.button-disabled, .button-disabled:hover {
  opacity: 0.3;
  box-shadow: none;
  cursor: default;
}

.body-nect {
  margin-top: 20px;
  text-align: center;
}

/* Mobile View */
@media (max-width: 576px) {
  #nect-validierung-btn {
    margin-top: 30px;
  }
}
</style>
