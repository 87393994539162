<template>
  <!-- eslint-disable max-len -->
  <div id="karte" class="card" :class="{'disabled': disabled}">
    <div class="card-body">
      <h3 id="title" class="card-title">{{ title }}</h3>
      <h5 id="description" class="copy_16 card-info">{{ description }}</h5>
      <pfeil-button class="stretched-link"
                    :class="{'oesa-gruen-color': mandant === 3,
                    'oevo-blau-color': mandant === 5}"
                    :path="path"
                    :button-text="buttonText"
                    :disabled="disabled">
      </pfeil-button>
    </div>
  </div>
</template>

<script>
import PfeilButton from '@/components/PfeilButton.vue';
import mandant from '@/mandant';

export default {
  name: 'KarteService',
  mixins: [mandant],
  components: { PfeilButton },
  props: [
    'title',
    'description',
    'path',
    'button-text',
    'disabled',
  ],
};
</script>

<style scoped>
#title {
  margin-bottom: 20px;
}

#description {
  margin-bottom: 20px;
  font-size: 15px;
}

.card {
  width: 350px;
  margin-left: 0;
  background-color: #EDF0F3;
  text-align: left;
  border-radius: 0;
  border-color: #EDF0F3;
}

.card.disabled {
  background-color: var(--grau);
  border-color: var(--grau);
}

.card.disabled h3, .card.disabled h5 {
  color: #808485;
}

.card-body {
  padding: 30px 15px;
}

.card-info {
  margin-bottom: 0;
  padding: 0;
}

@media (max-width: 576px) {
  .card {
    margin-bottom: 0;
  }
}

@media (max-width: 410px) {
  .card {
    width: 100%;
  }
}
</style>
