<template>
  <div>
    <sicherheitsabfrage
      v-show="showAbfrage"
      typ="verwerfen"
      :titel="titelAbfrage"
      :text="textAbfrage"
      @zustimmung="zurueck"
      @abbruch="closeAbfrage"
      @ablehnung="closeAbfrage">
    </sicherheitsabfrage>
    <sicherheitsabfrage
                        v-if="verlasseSeiteAbfrage"
                        typ="verlassen"
                        @zustimmung="behandleSeiteVerlassen"
                        @ablehnung="schliesseAbfrage"
                        @abbruch="schliesseAbfrage">
    </sicherheitsabfrage>
    <div>
      <div v-if="loading">
        <div class="spinner-border"
             :class="{'vgh-blau-color': mandant === 1,
              'oesa-gruen-color': mandant === 3,
              'oevo-blau-color': mandant === 5}" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
      <div v-else class="container main-content">
        <slot name="benachrichtigung"></slot>
        <div class="row g-3">
          <div class="col-xl-8 abstand-unten-30">
            <h1 class="abstand-unten-30">Beratungstermin vereinbaren</h1>
            <h2 class="abstand-unten-32">Mein Terminwunsch an {{vertragVertreter.name}}</h2>
            <div class="row">
              <div class="col-12 abstand-unten-60">
                <label class="aufschrift copy_bold_12">Wie können wir Ihnen helfen? *</label>
                <textarea
                  class="eingabe copy_16"
                  :class="{'fehler': nachrichtFehler && mandant === 1,
                        'fehler-oesa': nachrichtFehler && mandant === 3,
                        'vgh-eingabe': mandant === 1,
                        'oesa-eingabe': mandant === 3,
                        'oevo-eingabe': mandant === 5,
                        'fehler-oevo': nachrichtFehler && mandant === 5}"
                  v-model="nachricht"
                  maxlength="1200"
                  @input="validateMessage(false)"
                  id="nachricht"
                ></textarea>
                <label class="zaehler-label copy_12">
                  {{nachricht.length}} / 1200 Zeichen
                </label>
                <label
                  v-if="errors.nachricht"
                  class="fehler-text copy_bold_12"
                  :class="{'fehler-text-oesa': mandant === 3,
                    'fehler-text-oevo': mandant === 5}">
                  {{ errors.nachricht }}
                </label>
              </div>
              <div class="col-12 abstand-unten-30">
                <label class="aufschrift copy_bold_12">Wie können wir Sie erreichen? *</label>
                <div id="kontaktaufnahme" class="row copy_16">
                  <div class="col-12">
                    <input type="checkbox"
                           id="telefon"
                           v-model="kontaktart.telefon"
                           :class="{'input-vgh': mandant === 1,
                              'input-oesa': mandant === 3,
                              'input-oevo': mandant === 5}"
                           @change="validateKontaktart(false)">
                    <label for="telefon"></label>telefonisch
                    <span v-if="kontaktart.telefon">unter</span>
                  </div>
                  <div id="auswahl-telefon-zeiten" v-if="kontaktart.telefon">
                    <div class="col-12 abstand-unten-15">
                      <div>
                        <div class="eingabe-form copy_16"
                             :class="{'eingabe-fehler': handynummerFehler,
                                      'vgh-eingabe-form': mandant === 1,
                                      'oesa-eingabe-form': mandant === 3,
                                      'oevo-eingabe-form': mandant === 5,
                                       'fehler-oevo': handynummerFehler && mandant === 5}">
                          <span class="dropdown dropdown-button"
                                @click.stop.prevent="toggleVorwahlnummerDropdown">
                            <span v-if="vorwahlnummer === '+49'"
                                  class="fi fi-de dropdown-bild">
                            </span>
                            <span v-if="vorwahlnummer === '+31'"
                                  class="fi fi-nl dropdown-bild">
                            </span>
                            <em class="fa-solid fa-chevron-down"/>
                          </span>
                          <input class="eingabe eingabe-handynummer copy_16"
                                 :class="{'eingabe-fehler': errors.telefonnummer,
                                 'vgh-eingabe': mandant === 1,
                                 'oesa-eingabe': mandant === 3,
                                 'oevo-eingabe': mandant === 5,
                                 'fehler-oevo': errors.telefonnummer && mandant === 5}"
                                 style="border:none"
                                 v-model="telefonnummerEingabe"
                                 maxlength="15"
                                 id="telefonnummer"
                                 @input="errors.telefonnummer=false"
                                 @keypress="nurZahlen"
                                 @paste="behandleHandynummerEinfuegen">
                        </div>
                        <ul v-show="showVorwahlnummerDropdown" class="dropdown-inhalt">
                          <li
                            class="dropdown-auswahl copy_12"
                            :class="{'vgh-blau-bg-hover dropdown-hover-text': mandant === 1,
                             'oesa-gruen-bg-hover dropdown-hover-text': mandant === 3,
                            'oevo-blau-bg-hover dropdown-hover-text': mandant === 5}"
                            @click="vorwahlnummerAuswahl('+49')">
                            <span class="fi fi-de dropdown-bild"></span> &nbsp; Deutschland +49
                          </li>
                          <li
                            class="dropdown-auswahl copy_12"
                            :class="{'vgh-blau-bg-hover dropdown-hover-text': mandant === 1,
                            'oesa-gruen-bg-hover dropdown-hover-text': mandant === 3,
                            'oevo-blau-bg-hover dropdown-hover-text': mandant === 5}"
                            @click="vorwahlnummerAuswahl('+31')">
                            <span class="fi fi-nl dropdown-bild"></span> &nbsp; Niederlande +31
                          </li>
                        </ul>
                      </div>
                      <label v-if="errors.telefonnummer"
                             class="aufschrift fehler-text copy_bold_12"
                             :class="{'fehler-text-oesa': mandant === 3,
                                'fehler-text-oevo': mandant === 5}">
                        Bitte geben Sie eine gültige
                        {{ vorwahlnummer === '+49' ? 'deutsche' : 'niederländische' }}
                        Handynummer ein.
                      </label>
                    </div>
                    <div class="col-12">
                      <div class="copy_16">
                        <label class="aufschrift copy_bold_12">Wann können wir Sie erreichen? *
                          <br>
                          Am besten können Sie mich Mo-Fr. zu folgenden Zeiten erreichen:
                        </label>
                        <div class="row">
                          <div class="col-12 erreichbarkeit">
                            <input type="checkbox"
                                   id="cMorgen"
                                   v-model="zeitraum.vormittag"
                                   :class="{'input-vgh': mandant === 1,
                                    'input-oesa': mandant === 3,
                                    'input-oevo': mandant === 5}"
                                   @change="validateZeitraum(false)"
                                   required>
                            <label for="cMorgen"></label>
                            09:00 - 12:00 Uhr
                          </div>
                          <div class="col-12 erreichbarkeit">
                            <input type="checkbox"
                                   id="cMittag"
                                   v-model="zeitraum.mittag"
                                   :class="{'input-vgh': mandant === 1,
                                    'input-oesa': mandant === 3,
                                    'input-oevo': mandant === 5}"
                                   @change="validateZeitraum(false)">
                            <label for="cMittag"></label>
                            12:00 - 15:00 Uhr
                          </div>
                          <div class="col-12 erreichbarkeit">
                            <input type="checkbox"
                                   id="cAbend"
                                   v-model="zeitraum.nachmittag"
                                   :class="{'input-vgh': mandant === 1,
                                    'input-oesa': mandant === 3,
                                    'input-oevo': mandant === 5}"
                                   @change="validateZeitraum(false)">
                            <label for="cAbend"></label>
                            15:00 - 18:00 Uhr
                          </div>
                        </div>
                      </div>
                      <label
                        v-if="errors.zeitraum"
                        class="fehler-text copy_bold_12"
                        :class="{'fehler-text-oesa': mandant === 3,
                    'fehler-text-oevo': mandant === 5}">
                        {{ errors.zeitraum }}
                      </label>
                    </div>
                  </div>
                  <div class="col-12">
                    <input type="checkbox"
                           id="email"
                           v-model="kontaktart.email"
                           :class="{'input-vgh': mandant === 1,
                              'input-oesa': mandant === 3,
                              'input-oevo': mandant === 5}"
                           @change="validateKontaktart(false)">
                    <label for="email"></label>per E-Mail
                  </div>
                  <div class="col-12">
                    <input type="checkbox"
                           id="ksp"
                           v-model="kontaktart.kundenserviceportal"
                           :class="{'input-vgh': mandant === 1,
                              'input-oesa': mandant === 3,
                              'input-oevo': mandant === 5}"
                           @change="validateKontaktart(false)">
                    <label for="ksp"></label>per Kundenportal
                  </div>
                </div>
                <label
                  v-if="errors.kontaktart"
                  class="fehler-text copy_bold_12"
                  :class="{'fehler-text-oesa': mandant === 3,
                    'fehler-text-oevo': mandant === 5}">
                  {{ errors.kontaktart }}
                </label>
              </div>
              <div class="col-12">
                <label id="pflicht-label" class="copy_12">Pflichtfeld*</label>
              </div>
            </div>
            <div class="row g-3">
              <div class="col-12">
                <input type="button"
                       id="senden-btn"
                       class="button-ivv white"
                       :class="{'button-vgh': mandant === 1,
                          'button-oesa': mandant === 3,
                          'button-oevo': mandant === 5}"
                       value="Senden"
                       :disabled="sendenButtonDisabled"
                       @click="sendeTerminanfrage"
                >
                <input type="button"
                       id="verwerfen-btn"
                       class="button-ivv"
                       value="Verwerfen"
                       @click="showAbfrage = true"
                >
              </div>
            </div>
            <backend-fehler :api-fehler="apiFehler" :backend-fehler="backendFehler"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Sicherheitsabfrage from '@/components/Sicherheitsabfrage.vue';
import api from '@/backend-api';
import isoUtil from '@/isoUtil';
import { mapState } from 'vuex';
import sicherheitsabfrage from '@/sicherheitsabfrage';
import bestaetigungUtil from '@/bestaetigungUtil';
import mandant from '@/mandant';
import BackendFehler from '@/components/BackendFehler.vue';
import Vue from 'vue';
// eslint-disable-next-line import/no-absolute-path,import/no-unresolved
import '/node_modules/flag-icons/css/flag-icons.min.css';

export default {
  name: 'Beratungstermin',
  mixins: [isoUtil, sicherheitsabfrage, bestaetigungUtil, mandant],
  components: {
    Sicherheitsabfrage,
    BackendFehler,
  },

  data() {
    return {
      // Mixin Attribute:
      // verlasseSeite: false,
      // verlasseSeiteAbfrage: false,
      sendenButtonDisabled: false,
      vertragVertreter: {},
      nachricht: '',
      kontaktart: {
        telefon: false,
        email: false,
        kundenserviceportal: false,
      },
      zeitraum: {
        vormittag: false,
        mittag: false,
        nachmittag: false,
      },
      telefonnummerEingabe: '',
      telefonnummer: '',
      nachrichtFehler: false,
      errors: {
        zeitraum: '',
        kontaktart: '',
        nachricht: '',
        telefonnummer: '',
      },
      showAbfrage: false,
      apiFehler: false,
      backendFehler: false,
      titelAbfrage: 'Möchten Sie Ihren Beratungstermin wirklich verwerfen?',
      textAbfrage: 'Ihr bisheriger Entwurf wird gelöscht.',
      zustimmungsBtnAbfrage: 'Bearbeitung fortsetzen',
      ablehnungsBtnAbfrage: 'Verwerfen',
      loading: true,
      reloadVSNR: '',
      reloadKonversationsId: '',
      handynummerFehler: false,
      handynummerEingabe: '',
      showVorwahlnummerDropdown: false,
      vorwahlnummer: '+49',
    };
  },
  props: {
    vsnr: {
      type: String,
      required: false,
    },
    laufendenr: {
      type: String,
      required: false,
    },
    produktkey: {
      type: String,
      required: false,
    },
    konversationsId: {
      type: String,
      required: false,
    },
    from: String,
  },
  computed: {
    ...mapState(['vertreter']),
  },
  methods: {
    // Mixin Methoden:
    // behandleSeiteVerlassen()
    // schliesseAbfrage ()
    // nurZahlen(eingabe)
    // checkISO(input)

    async sendeTerminanfrage() {
      try {
        this.backendFehler = false;
        this.validateMessage(true);
        this.validateZeitraum(true);
        this.validateKontaktart(true);
        this.validateTelefonnummer();
        if (this.isValid()) {
          this.sendenButtonDisabled = true;
          const formData = this.erzeugeFormDataTerminanfrage();
          await api.sendeTerminanfrage(
            formData,
            this.$store.state.emailAdresse,
            this.mandant,
            Vue.$keycloak.token,
          );
          this.verlasseSeite = true;
          if (this.vsnr) {
            await this.$router.push({
              name: this.$store.state.vorherigeURL,
              params: {
                vsnr: this.vsnr,
                bestaetigung: 'termin',
              },
            });
          } else if (this.reloadVSNR) {
            await this.$router.push({
              name: this.$store.state.vorherigeURL,
              params: {
                vsnr: this.reloadVSNR,
                bestaetigung: 'termin',
              },
            });
          } else if (this.konversationsId) {
            await this.$router.push({
              name: this.$store.state.vorherigeURL,
              params: {
                id: this.konversationsId,
                bestaetigung: 'termin',
              },
            });
          } else if (this.reloadKonversationsId) {
            await this.$router.push({
              name: this.$store.state.vorherigeURL,
              params: {
                id: this.reloadKonversationsId,
                bestaetigung: 'termin',
              },
            });
          } else {
            await this.$router.push({
              name: this.$store.state.vorherigeURL,
              params: {
                bestaetigung: 'termin',
              },
            });
          }
        }
      } catch (e) {
        this.sendenButtonDisabled = false;
        if (e.response.status === 429) {
          this.apiFehler = true;
        } else {
          this.backendFehler = true;
        }
        console.log(e);
      }
    },
    erzeugeFormDataTerminanfrage() {
      const formData = new FormData();
      formData.append('inhalt', this.nachricht);
      if (this.kontaktart.telefon) {
        formData.append('kontaktart', 'telefon');
        formData.append('telefonnummer', this.telefonnummer);
      }
      if (this.kontaktart.email) {
        formData.append('kontaktart', 'email');
      }
      if (this.kontaktart.kundenserviceportal) {
        formData.append('kontaktart', 'kundenserviceportal');
      }
      if (this.zeitraum.vormittag) {
        formData.append('zeitbereich', 'vormittag');
      }
      if (this.zeitraum.mittag) {
        formData.append('zeitbereich', 'mittag');
      }
      if (this.zeitraum.nachmittag) {
        formData.append('zeitbereich', 'nachmittag');
      }
      formData.append('vertreternummer', this.vertragVertreter.vertreternummer);
      formData.append('hauptvertreternummer', this.vertragVertreter.hauptvertreternummer);
      formData.append('mandantengruppenId', this.vertragVertreter.mandantengruppenId);
      return formData;
    },
    zurueck() {
      this.verlasseSeite = true;
      this.$router.back();
    },
    eingegeben() {
      return this.nachricht
        || this.kontaktart.telefon
        || this.telefonnummerEingabe
        || this.kontaktart.email
        || this.kontaktart.kundenserviceportal;
    },
    closeAbfrage() {
      this.showAbfrage = false;
    },
    isValid() {
      return !(this.errors.nachricht
        || this.errors.zeitraum
        || this.errors.kontaktart
        || this.errors.telefonnummer);
    },
    nurZahlen(eingabe) {
      const regEx = new RegExp(/^\d*$/);
      if (!regEx.test(eingabe.key)) {
        eingabe.preventDefault();
      }
    },
    ladeVertreter() {
      this.$store.dispatch('ladeVertreter');
    },
    setZurueckFeld() {
      this.$root.$refs.app.setZurueckFeld(true);
    },
    validateMessage(istLeerCheck) {
      if (this.nachricht !== '') {
        if (this.checkISO(this.nachricht)) {
          this.nachrichtFehler = false;
          this.errors.nachricht = '';
        } else {
          this.nachrichtFehler = true;
          this.errors.nachricht = 'Bitte achten Sie auf Sonderzeichen.';
        }
      } else if (istLeerCheck) {
        this.nachrichtFehler = true;
        this.errors.nachricht = 'Bitte geben Sie eine Nachricht ein.';
      } else {
        this.nachrichtFehler = false;
        this.errors.nachricht = '';
      }
    },
    validateKontaktart(istLeerCheck) {
      if (!(this.kontaktart.telefon
        || this.kontaktart.email
        || this.kontaktart.kundenserviceportal)
        && istLeerCheck) {
        this.errors.kontaktart = 'Bitte wählen Sie mindestens eine Kontaktart aus.';
      } else {
        this.errors.kontaktart = '';
      }
    },
    validateTelefonnummer() {
      if (this.kontaktart.telefon) {
        const regEx = new RegExp(/^[1-9][0-9]{0,14}$/);
        if (!this.telefonnummerEingabe || !regEx.test(this.telefonnummerEingabe)) {
          this.errors.telefonnummer = true;
        } else {
          this.errors.telefonnummer = false;
          this.telefonnummer = `${this.vorwahlnummer}${this.telefonnummerEingabe}`;
        }
      } else {
        this.errors.telefonnummer = false;
      }
    },
    validateZeitraum(istLeerCheck) {
      if (this.kontaktart.telefon) {
        if (!(this.zeitraum.vormittag || this.zeitraum.mittag || this.zeitraum.nachmittag)
          && istLeerCheck) {
          this.errors.zeitraum = 'Bitte wählen Sie mindestens einen Zeitraum aus.';
        } else {
          this.errors.zeitraum = '';
        }
      } else {
        this.errors.zeitraum = '';
      }
    },
    async getVertreterFromVertrag(vsnr, laufendenr, produktkey) {
      await api.getEinzelVertrag(
        vsnr,
        laufendenr,
        produktkey,
        Vue.$keycloak.token,
      ).then((response) => response.data)
        .then((vertrag) => {
          this.vertragVertreter = vertrag.bestandsvertreter;
        }).catch((error) => {
          console.log(`Vertreter: ${error}`);
        });
    },
    async getVertreterdatenByKonversationsId(konversationsId) {
      await api.getVertreterdatenByKonversationsId(konversationsId, Vue.$keycloak.token)
        .then((response) => response.data)
        .then((vertreter) => {
          this.vertragVertreter = vertreter;
        }).catch((error) => {
          this.$store.commit('SET_FEHLER', true);
          console.log(`KonverationsvertreterStatus: ${error}`);
        });
    },
    toggleVorwahlnummerDropdown() {
      this.showVorwahlnummerDropdown = !this.showVorwahlnummerDropdown;
    },
    behandleHandynummerEinfuegen(value) {
      value.preventDefault();
      let eingefuegtes = value.clipboardData.getData('text/plain');
      eingefuegtes = eingefuegtes.replace(/^[^1-9]+|\D/g, '');
      this.handynummerEingabe = eingefuegtes;
    },
    vorwahlnummerAuswahl(vorwahlnummer) {
      this.vorwahlnummer = vorwahlnummer;
      this.toggleVorwahlnummerDropdown();
    },
  },
  mounted() {
    this.$emit('view-geladen');
  },
  async beforeMount() {
    await this.ladeVertreter();
    this.setZurueckFeld();
    if (!this.vsnr && !this.konversationsId) {
      this.reloadVSNR = this.$store.state.vsnr;
      this.reloadLaufendenr = this.$store.state.laufendenr;
      this.reloadProduktkey = this.$store.state.produktkey;
      this.reloadKonversationsId = this.$store.state.konversationsId;
      if (this.reloadVSNR) {
        await this.getVertreterFromVertrag(
          this.reloadVSNR,
          this.reloadLaufendenr,
          this.reloadProduktkey,
        );
      } else if (this.reloadKonversationsId) {
        await this.getVertreterdatenByKonversationsId(this.reloadKonversationsId);
      } else {
        this.vertragVertreter = this.$store.state.vertreter;
      }
    } else if (this.vsnr) {
      this.$store.commit('SET_VSNR', this.vsnr);
      this.$store.commit('SET_LAUFENDENR', this.laufendenr);
      this.$store.commit('SET_PRODUKTKEY', this.produktkey);
      await this.getVertreterFromVertrag(this.vsnr, this.laufendenr, this.produktkey);
    } else {
      this.$store.commit('SET_KONVERSATION', this.konversationsId);
      await this.getVertreterdatenByKonversationsId(this.konversationsId);
    }
    this.loading = false;
  },
  watch: {
    nachricht() {
      this.validateMessage(false);
    },
  },
};
</script>

<style scoped>
#auswahl-telefon-zeiten {
  margin-left: 23px;
}

#nachricht {
  height: 160px;
  resize: none;
}

#senden-btn {
  margin-right: 15px;
}

#pflicht-label {
  color: #6B7171;
  margin: 19px 0 30px 0;
}

.eingabe-fake {
  background: transparent;
  border: none;
  box-sizing: border-box;
  display: inline-block;
  height: 40px;
  padding-left: 10px;
  padding-right: 2px;
  position: absolute;
  left: 15px;
  font-size: 16px;
  width: 42px;
}

.eingabe-fake:focus-visible {
  outline: none;
}

.eingabe-telefonnummer {
  background: transparent;
  border: 1px solid rgba(203, 207, 211, 0.5);
  border-radius: 2px;
  box-sizing: border-box;
  display: inline-block;
  height: 40px;
  width: 200px;
  padding-left: 40px;
  padding-top: 1px;
  padding-right: 8px;
  -webkit-box-shadow: 0 0 0 1000px white inset;
}

.zaehler-label {
  display: flex;
  justify-content: flex-end;
  color: #6B7171;
}

.aufschrift {
  margin-bottom: 6px;
}

.erreichbarkeit {
  margin-left:21px;
}

.fehler:focus {
  outline: none !important;
}

.vgh-eingabe-form:focus-within {
  border: 1px solid #0558f0;
}

.oevo-eingabe-form:focus-within {
  border: 1px solid #004494;
}

.oesa-eingabe-form:focus-within {
  border: 1px solid #78bfa4 !important;
}

.dropdown-button {
  text-align: center;
  padding-top: 8px;
}

.dropdown {
  background: #FFFFFF;
  height: 40px;
  width: 50px;
  cursor: pointer;
}

.eingabe {
  background: #FFFFFF;
  border: 1px solid rgba(203, 207, 211, 0.5);
  box-sizing: border-box;
  display: block;
  border-radius: 2px;
  height: 40px;
  width: 100%;
  padding-top: 1px;
  padding-left: 8px;
  padding-right: 8px;
  vertical-align: middle;
}

.eingabe:focus {
  outline: none !important;
  border: 1px solid #484a4f;
}

.eingabe-handynummer {
  background: transparent;
  display: inline-block;
  height: 40px;
  width: 200px;
  padding-top: 1px;
  padding-right: 8px;
  padding-left: 0;
}

.eingabe-form {
  background: #FFFFFF;
  border: 1px solid rgba(203, 207, 211, 0.5);
  border-radius: 2px;
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-direction: row;
  width: 260px;
  -webkit-box-shadow: 0 0 0 1000px white inset;
  vertical-align: middle;
}

.dropdown-inhalt {
  background-color: #f1f1f1;
  box-shadow: 2px 2px 8px rgba(53, 53, 53, 0.25);
  display: block;
  list-style-type: none;
  padding-left: 0;
  position: absolute;
  z-index: 1;
}

.dropdown-auswahl {
  cursor: pointer;
  height: 40px;
  width: 140px;
  text-align: left;
  padding-top: 10px;
  padding-left: 5px;
  border-radius: 2px;
  color: black;
}

.fa-chevron-down {
  font-size: 10px;
  padding-left: 3px;
  padding-bottom: 3px;
}

.dropdown-bild {
  height: 15px;
  width: 25px;
  margin-left: 3px;
}

.dropdown-hover-text:hover {
  color:white !important;
}

@media (max-width: 576px) {
  #pflicht-label {
    margin-bottom: 20px;
  }
}

</style>
