<template>
  <!-- eslint-disable max-len -->
    <div>
      <div>
        <app-header :textLine1="headerText"></app-header>
        <div class="container main-content">
          <slot name="benachrichtigung"></slot>
          <div class="row g-3">
            <div class="col-xl-8 mb-5">
              <h2>Unsere Leistungen für Sie:</h2>
              <table id="leistungs-tabelle" class="copy_16">
                <tr><td><em class="fa-solid fa-check icon"
                           :class="{'vgh-blau-color': mandant === 1,
                            'oesa-gruen-color': mandant === 3,
                            'oevo-blau-color': mandant === 5}"></em></td>
                  <td v-if="mandant === 1">Persönliche Beratung und Kompetenz vor Ort</td>
                  <td v-else-if="mandant === 3">Persönliche Beratung und Kompetenz vor Ort</td>
                  <td v-else-if="mandant === 5">Wir bieten Ihnen eine individuelle und qualifizierte Beratung</td>
                </tr>
                <tr><td><em class="fa-solid fa-check icon"
                           :class="{'vgh-blau-color': mandant === 1,
                            'oesa-gruen-color': mandant === 3,
                            'oevo-blau-color': mandant === 5}"></em></td>
                  <td v-if="mandant === 1">Maßgeschneiderte Lösungen und flexible Produkte</td>
                  <td v-else-if="mandant === 3">Maßgeschneiderte Lösungen und flexible Produkte</td>
                  <td v-else-if="mandant === 5">Wir leisten schnelle und unbürokratische Hilfe im Schadenfall</td>
                </tr>
                <tr><td><em class="fa-solid fa-check icon"
                           :class="{'vgh-blau-color': mandant === 1,
                            'oesa-gruen-color': mandant === 3,
                            'oevo-blau-color': mandant === 5}"></em></td>
                  <td v-if="mandant === 1">Schnelle Hilfe im Schadenfall</td>
                  <td v-else-if="mandant === 3">Schnelle Hilfe im Schadenfall</td>
                  <td v-else-if="mandant === 5">Wir sind immer in Ihrer Nähe und stehen Ihnen mit unserer langjährigen Erfahrung zur Seite</td>
                </tr>
                <tr v-if="mandant === 1 || mandant === 5"><td><em class="fa-solid fa-check icon"
                           :class="{'vgh-blau-color': mandant === 1, 'oevo-blau-color': mandant === 5}"></em></td>
                  <td v-if="mandant === 1">Vorsprung durch über 260 Jahre Erfahrungen der VGH</td>
                  <td v-else-if="mandant === 5">Wir bieten Ihnen moderne Produktlösungen und kundenfreundlichen Service</td>
                </tr>
              </table>
              <p class="copy_16">Wir freuen uns auf Ihre Kontaktaufnahme.</p>
            </div>
            <div class="col-xl-4">
              <vertreter-info
                :ueberschrift="vertreterUeberschrift"
                :mail-adresse="true"
                :nachricht="true"
                :vertrLink="false"
                :termin="true"
                :social-media="true"
                :vertreter="vertreter"
              >
              </vertreter-info>
              <oeffnungszeiten v-if="vertreter.oeffnungszeiten && vertreter.oeffnungszeiten.length"></oeffnungszeiten>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
// @ is an alias to /src
import AppHeader from '@/components/Header.vue';
import Oeffnungszeiten from '@/components/Oeffnungszeiten.vue';
import VertreterInfo from '@/components/VertreterInfo.vue';
import { mapState } from 'vuex';
import bestaetigungUtil from '@/bestaetigungUtil';
import mandant from '@/mandant';

export default {
  name: 'Vertreter',
  mixins: [bestaetigungUtil, mandant],
  data() {
    return {
      headerText: 'Meine Vertretung',
      vertreterUeberschrift: 'Ihre Vertretung vor Ort',
      globalesZurueck: true,
    };
  },
  props: ['bestaetigung'],
  components: {
    VertreterInfo,
    AppHeader,
    Oeffnungszeiten,
  },
  computed: {
    ...mapState(['vertreter']),
  },
  methods: {
    zumBeratungstermin() {
      this.$router.push({ name: 'Beratungstermin', params: { from: this.$router.currentRoute.name } });
    },
  },
  mounted() {
    this.$emit('view-geladen');
  },
  async beforeMount() {
    this.$root.$refs.app.setZurueckFeld(this.globalesZurueck);
  },
};
</script>

<style scoped>
#leistungs-tabelle {
  margin: 20px 0;
}

.icon{
  width: 16px;
  height: 16px;
  margin-bottom: -2px;
  margin-right: 10px;
  vertical-align: baseline;
  white-space: nowrap;
  -webkit-text-stroke: 2px red;
}
</style>
