<template>
  <div>
    <app-header :textLine1="headerText"></app-header>
    <div class="container">
      <slot name="benachrichtigung"></slot>
      <h2 class="text-links" id="aktive">Welchen Service möchten Sie nutzen?</h2>
      <div class="row">
        <div id="karten"
             v-for="service in services"
             :key="service.id"
             class="copy_16 col-xl-4"
             :class="{ 'col-auto': (windowWidth > 576) }">
          <karte-service
            :title="service.title"
            :description="service.desc"
            :button-text="service.buttonText"
            :path="service.path"
            :disabled="service.disabled">
          </karte-service>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AppHeader from '@/components/Header.vue';
import KarteService from '@/components/KarteService.vue';
import bestaetigungUtil from '@/bestaetigungUtil';
import mandant from '@/mandant';
import zugriffsstatus from '@/zugriffsstatus';

export default {
  name: 'Services',
  mixins: [bestaetigungUtil, mandant, zugriffsstatus],
  data() {
    return {
      headerText: 'Meine Services',
      titel: 'Persönliche Daten Ändern',
      globalesZurueck: true,
      vertreterAnzahl: 0,
      services: [
        {
          id: 1,
          title: 'Persönliche Daten ändern',
          desc: 'Sie möchten Ihren Namen ändern?',
          buttonText: 'Zu persönliche Daten ändern',
          path: 'name-aendern',
          disabled: false,
        },
        {
          id: 2,
          title: 'Adresse ändern',
          desc: 'Sie sind umgezogen?',
          buttonText: 'Zu Adresse ändern',
          path: 'adresse-aendern',
          disabled: false,
        },
        {
          id: 3,
          title: 'Bankverbindung ändern',
          desc: 'Sie wollen uns eine Kontoänderung mitteilen?',
          buttonText: 'Zu Bankverbindung ändern',
          path: 'bankverbindung-aendern',
          disabled: false,
        },
        {
          id: 4,
          title: 'Portalvertretung ändern',
          desc: 'Sie möchten Ihre Begleitung im Portal ändern?',
          buttonText: 'Zu Portalvertretung ändern',
          path: 'vertreter-aendern',
          disabled: false,
        },
        {
          id: 5,
          title: 'Meine Feldstücke',
          desc: 'Sie möchten Ihren aktuellen Anbau melden?',
          buttonText: 'Zur Anwendung wechseln',
          path: 'https://meinefeldstuecke.vgh.de',
          disabled: false,
        },
      ],
    };
  },
  components: {
    AppHeader,
    KarteService,
  },
  methods: {
    filtereServices() {
      // Präfix für die landwirtschaftlichen Pflanzenverträge
      const vsnrPre = '0107';

      if (this.mandant === 5) {
        this.services = this.services.filter((obj) => obj.id !== 4);
      } else if (this.$store.state.vertreterAnzahl === 0
        || this.$store.state.vertreterAnzahl === 1) {
        // "Portalvertretung ändern" raus
        this.disableService(4);
      }
      if (this.istBenutzerPartnergemeinschaft()) {
        // Alles außer "Portalvertreung ändern" raus
        this.disableService(1);
        this.disableService(2);
        this.disableService(3);
        this.disableService(5);
      } else if (this.istBenutzerFirma()) {
        // "Persönliche Daten ändern" raus
        this.disableService(1);
      }
      // "Meine Feldstücke" raus
      if (this.$store.state.vertraege.filter((obj) => obj.vsnr.startsWith(vsnrPre)).length === 0) {
        this.services = this.services.filter((obj) => obj.id !== 5);
      }

      this.$store.dispatch('ladeVertreteranzahl').then(() => {
        if (this.$store.state.vertreterAnzahl !== 1 && this.mandant !== 5) {
          this.enableService(4);
        }
      });
    },
    disableService(id) {
      this.services.find((obj) => obj.id === id).disabled = true;
    },
    enableService(id) {
      this.services.find((obj) => obj.id === id).disabled = false;
    },
  },
  props: {},
  mounted() {
    if (this.$root.$refs.app.istServiceDisabled()) {
      this.$router.push('/');
    }
    this.filtereServices();
    this.$emit('view-geladen');
  },
  beforeMount() {
    this.$root.$refs.app.setZurueckFeld(this.globalesZurueck);
  },
  updated() {
    if (this.bestaetigung && this.bestaetigung !== 'portalzugang-geloescht') {
      this.$bvToast.show(this.bestaetigung);
    }
  },
};
</script>

<style scoped>

#aktive {
  margin-bottom: 30px;
}

#karten{
  padding: 0 15px 30px;
}

.container {
  margin-top: 60px;
}

.text-links {
  text-align: left;
}

@media (max-width: 576px) {
  #aktive {
    margin-bottom: 20px;
  }

  #karten {
    padding-bottom: 20px;
  }

  .container {
    margin-top: 30px;
  }
}

</style>
