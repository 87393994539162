var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_vm._m(0),_c('div',{staticClass:"body-nect row"},[(_vm.success === true)?_c('div',[_c('em',{staticClass:"fa-regular fa-3x fa-circle-check",class:{'vgh-blau-color': _vm.mandant === 1,
          'oesa-gruen-color': _vm.mandant === 3,
          'oevo-blau-color': _vm.mandant === 5},attrs:{"id":"icon-success"}}),_c('h3',[_vm._v(_vm._s(_vm.messageHeader))]),_c('p',{staticClass:"copy_16 abstand-unten-40"},[_vm._v(" Ihre Authentifizierung mit Nect war erfolgreich und kann auch in Zukunft für weitere Vorgänge genutzt werden. Ihre Daten sind verschlüsselt und nur innerhalb von Nect gespeichert. ")]),_c('div',{staticClass:"nect-validierung-btn"},[_c('div',[_c('input',{staticClass:"button-ivv abstand-unten-17 white",class:{'button-vgh': _vm.mandant === 1,
                    'button-oesa': _vm.mandant === 3,
                    'button-oevo': _vm.mandant === 5},attrs:{"type":"button","id":"nect-weiter-btn","value":"Weiter"},on:{"click":_vm.weiter}})])])]):_vm._e(),(_vm.success === false)?_c('div',[_c('em',{staticClass:"fa-regular fa-3x fa-xmark-circle",attrs:{"id":"icon-failure"}}),_c('h3',[_vm._v(_vm._s(_vm.messageHeader))]),_c('p',{staticClass:"copy_16 abstand-unten-40"},[_vm._v("Ihre Online-Authentifizierung ist leider fehlgeschlagen. Bitte versuchen Sie es erneut oder wenden Sie sich an unser "),_c('a',{attrs:{"href":_vm.$store.state.weiterleitungen.faq,"target":"_blank","rel":"noopener"}},[_vm._v(" Supportteam ")]),_vm._v(" . ")]),_c('div',{staticClass:"nect-validierung-btn"},[_c('div',[_c('input',{staticClass:"button-ivv abstand-unten-17 white",class:{'button-vgh': _vm.mandant === 1,
                    'button-oesa': _vm.mandant === 3,
                    'button-oevo': _vm.mandant === 5,
                    'button-disabled': _vm.nectNeustartButtonDisabled},attrs:{"type":"button","id":"nect-neustart-btn","disabled":_vm.nectNeustartButtonDisabled,"value":"Erneut versuchen"},on:{"click":_vm.neustart}})])])]):_vm._e()])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"header-nect"},[_c('h2',{staticClass:"header-text"},[_vm._v("Nect Authentifizierung")])])}]

export { render, staticRenderFns }