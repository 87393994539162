var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('sicherheitsabfrage',{directives:[{name:"show",rawName:"v-show",value:(_vm.showAbfrage),expression:"showAbfrage"}],attrs:{"typ":"verwerfen","titel":_vm.titelAbfrage,"text":_vm.textAbfrage},on:{"zustimmung":_vm.verwerfenZurueck,"abbruch":_vm.closeAbfrage,"ablehnung":_vm.closeAbfrage}}),(_vm.verlasseSeiteAbfrage)?_c('sicherheitsabfrage',{attrs:{"typ":"verlassen"},on:{"zustimmung":_vm.behandleSeiteVerlassen,"ablehnung":_vm.schliesseAbfrage,"abbruch":_vm.schliesseAbfrage}}):_vm._e(),(_vm.zusammenfassung)?_c('div',{staticClass:"card card-grau",attrs:{"id":"rechteck"}},[_c('div',{staticClass:"container"},[_c('a',{staticClass:"copy_16 pfeil-btn text-link abstand-unten-0",class:{'text-link-vgh': _vm.mandant === 1,
            'text-link-oesa': _vm.mandant === 3,
            'text-link-oevo': _vm.mandant === 5},on:{"click":_vm.zurueck}},[_c('em',{staticClass:"fa-solid fa-chevron-left"}),_vm._v("Zurück ")])])]):_vm._e(),_c('div',{staticClass:"container main-content"},[_vm._t("benachrichtigung"),_c('div',{staticClass:"row g-3 abstand-unten-17"},[_c('div',{staticClass:"col-xl-8 mb-5"},[_c('h1',{staticClass:"abstand-unten-30"},[_vm._v("Adresse ändern")]),(!_vm.zusammenfassung)?_c('div',[_c('div',{staticClass:"card card-grau abstand-unten-60 col-md-9"},[_c('div',{staticClass:"card-body"},[_c('p',{staticClass:"copy_bold_16 abstand-unten-5"},[_vm._v("Meine aktuelle Adresse")]),_c('p',{staticClass:"copy_16 abstand-unten-0"},[_vm._v(_vm._s(_vm.partner.anschrift.strasse)+" "+_vm._s(_vm.partner.anschrift.hausnummer))]),_c('p',{staticClass:"copy_16 abstand-unten-0"},[_vm._v(_vm._s(_vm.partner.anschrift.postleitzahl)+" "+_vm._s(_vm.partner.anschrift.ort))]),_c('p',{staticClass:"copy_16 abstand-unten-0"},[_vm._v(_vm._s(_vm.partner.anschrift.land))]),_c('p',{staticClass:"copy_16 abstand-unten-0"},[_vm._v(_vm._s(_vm.partner.anschrift.ortsteil))]),_c('p',{staticClass:"copy_16 abstand-unten-0"},[_vm._v(_vm._s(_vm.partner.anschrift.zustellvermerk))])])]),_c('h2',{staticClass:"abstand-unten-32"},[_vm._v("Bitte geben Sie Ihre neuen Daten ein")]),_c('div',{staticClass:"row abstand-unten-17"},[_c('div',{staticClass:"col-md-6 col-9 col-kalibrieren"},[_c('label',{staticClass:"aufschrift copy_bold_12",attrs:{"for":"str"}},[_vm._v("Straße *")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.strEingabe),expression:"strEingabe"}],staticClass:"eingabe copy_16",class:{'fehler': _vm.strFehler && _vm.mandant === 1,
                      'fehler-oesa': _vm.strFehler && _vm.mandant === 3,
                      'vgh-eingabe': _vm.mandant === 1,
                      'oesa-eingabe': _vm.mandant === 3,
                      'oevo-eingabe': _vm.mandant === 5,
                      'fehler-oevo': _vm.strFehler && _vm.mandant === 5},attrs:{"maxlength":"60","id":"str"},domProps:{"value":(_vm.strEingabe)},on:{"input":function($event){if($event.target.composing){ return; }_vm.strEingabe=$event.target.value}}}),(_vm.strFehler)?_c('label',{staticClass:"fehler-text copy_bold_12",class:{'fehler-text-oesa': _vm.mandant === 3,
                     'fehler-text-oevo': _vm.mandant === 5}},[_vm._v(_vm._s(_vm.strFehlerText))]):_vm._e()]),_c('div',{staticClass:"col-3 col-kalibrieren"},[_c('label',{staticClass:"aufschrift copy_bold_12",attrs:{"for":"nr"}},[_vm._v("Hausnr. *")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.nrEingabe),expression:"nrEingabe"}],staticClass:"eingabe copy_16",class:{'fehler': _vm.nrFehler && _vm.mandant === 1,
                      'fehler-oesa': _vm.nrFehler && _vm.mandant === 3,
                      'vgh-eingabe': _vm.mandant === 1,
                      'oesa-eingabe': _vm.mandant === 3,
                      'oevo-eingabe': _vm.mandant === 5,
                      'fehler-oevo': _vm.nrFehler && _vm.mandant === 5},attrs:{"maxlength":"10","id":"nr"},domProps:{"value":(_vm.nrEingabe)},on:{"input":function($event){if($event.target.composing){ return; }_vm.nrEingabe=$event.target.value}}}),(_vm.nrFehler)?_c('label',{staticClass:"fehler-text copy_bold_12",class:{'fehler-text-oesa': _vm.mandant === 3,
                     'fehler-text-oevo': _vm.mandant === 5}},[_vm._v(_vm._s(_vm.nrFehlerText))]):_vm._e()])]),_c('div',{staticClass:"row abstand-unten-17"},[_c('div',{staticClass:"col-md-9 col-12 col-kalibrieren"},[_c('hinweis',{attrs:{"label":"Zustellvermerk","right":"16px"}},[_vm._v(" Sie können uns optional einen Zustellvermerk nennen, z.B. Hinterhof rechts kleine Tür ")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.vermerkEingabe),expression:"vermerkEingabe"}],staticClass:"eingabe copy_16",class:{'fehler': _vm.vermerkFehler && _vm.mandant === 1,
                      'fehler-oesa': _vm.vermerkFehler && _vm.mandant === 3,
                      'vgh-eingabe': _vm.mandant === 1,
                      'oesa-eingabe': _vm.mandant === 3,
                      'oevo-eingabe': _vm.mandant === 5,
                      'fehler-oevo': _vm.vermerkFehler && _vm.mandant === 5},attrs:{"maxlength":"120","id":"vermerk"},domProps:{"value":(_vm.vermerkEingabe)},on:{"input":function($event){if($event.target.composing){ return; }_vm.vermerkEingabe=$event.target.value}}}),(_vm.vermerkFehler)?_c('label',{staticClass:"fehler-text copy_bold_12",class:{'fehler-text-oevo': _vm.mandant === 5}},[_vm._v(_vm._s(_vm.vermerkFehlerText))]):_vm._e()],1)]),_c('div',{staticClass:"row abstand-unten-17"},[_c('div',{staticClass:"col-md-3 col-4 col-kalibrieren"},[_c('label',{staticClass:"aufschrift copy_bold_12",attrs:{"for":"plz"}},[_vm._v("Postleitzahl *")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.plzEingabe),expression:"plzEingabe"}],staticClass:"eingabe copy_16",class:{'fehler': _vm.plzFehler && _vm.mandant === 1,
                      'fehler-oesa': _vm.plzFehler && _vm.mandant === 3,
                      'vgh-eingabe': _vm.mandant === 1,
                      'oesa-eingabe': _vm.mandant === 3,
                      'oevo-eingabe': _vm.mandant === 5,
                      'fehler-oevo': _vm.plzFehler && _vm.mandant === 5},attrs:{"maxlength":"5","id":"plz"},domProps:{"value":(_vm.plzEingabe)},on:{"keypress":_vm.nurZahlen,"paste":_vm.behandlePlzEinfuegen,"input":[function($event){if($event.target.composing){ return; }_vm.plzEingabe=$event.target.value},function($event){_vm.plzFehler = false}],"blur":function($event){return _vm.validatePlz(false)}}}),(_vm.plzFehler)?_c('label',{staticClass:"fehler-text copy_bold_12",class:{'fehler-text-oevo': _vm.mandant === 5}},[_vm._v(_vm._s(_vm.plzFehlerText))]):_vm._e()]),_c('div',{staticClass:"col-md-6 col-8 col-kalibrieren"},[_c('label',{staticClass:"aufschrift copy_bold_12",attrs:{"for":"ort"}},[_vm._v("Ort *")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.ortEingabe),expression:"ortEingabe"}],staticClass:"eingabe copy_16",class:{'fehler': _vm.ortFehler && _vm.mandant === 1,
                      'fehler-oesa': _vm.ortFehler && _vm.mandant === 3,
                      'vgh-eingabe': _vm.mandant === 1,
                      'oesa-eingabe': _vm.mandant === 3,
                      'oevo-eingabe': _vm.mandant === 5,
                      'fehler-oevo': _vm.ortFehler && _vm.mandant === 5},attrs:{"maxlength":"60","id":"ort"},domProps:{"value":(_vm.ortEingabe)},on:{"input":function($event){if($event.target.composing){ return; }_vm.ortEingabe=$event.target.value}}}),(_vm.ortFehler)?_c('label',{staticClass:"fehler-text copy_bold_12",class:{'fehler-text-oevo': _vm.mandant === 5}},[_vm._v(_vm._s(_vm.ortFehlerText))]):_vm._e()])]),_c('div',{staticClass:"row abstand-unten-17"},[_c('div',{staticClass:"col-md-9 col-12"},[_c('div',{staticClass:"row abstand-unten-17"},[_c('div',{staticClass:"col-md-6  col-12 col-kalibrieren"},[_c('label',{staticClass:"aufschrift copy_bold_12",attrs:{"for":"teil"}},[_vm._v("Ortsteil")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.teilEingabe),expression:"teilEingabe"}],staticClass:"eingabe copy_16",class:{'fehler': _vm.teilFehler && _vm.mandant === 1,
                          'fehler-oesa': _vm.teilFehler && _vm.mandant === 3,
                          'vgh-eingabe': _vm.mandant === 1,
                          'oesa-eingabe': _vm.mandant === 3,
                          'oevo-eingabe': _vm.mandant === 5,
                          'fehler-oevo': _vm.teilFehler && _vm.mandant === 5},attrs:{"id":"teil","maxlength":"60"},domProps:{"value":(_vm.teilEingabe)},on:{"input":function($event){if($event.target.composing){ return; }_vm.teilEingabe=$event.target.value}}}),(_vm.teilFehler)?_c('label',{staticClass:"fehler-text copy_bold_12",class:{'fehler-text-oevo': _vm.mandant === 5}},[_vm._v(_vm._s(_vm.teilFehlerText))]):_vm._e()])])])]),_c('label',{staticClass:"copy_12",attrs:{"id":"pflicht-label"}},[_vm._v("Pflichtfeld*")]),_c('div',[_c('input',{staticClass:"button-ivv white",class:{'button-vgh': _vm.mandant === 1,
                   'button-oesa': _vm.mandant === 3,
                   'button-oevo': _vm.mandant === 5},attrs:{"type":"button","id":"zusammenfassung-btn","value":"Weiter"},on:{"click":_vm.zurZusammenfassung}}),_c('input',{staticClass:"button-ivv",attrs:{"type":"button","id":"verwerfen-btn","value":"Verwerfen"},on:{"click":function($event){_vm.showAbfrage = true}}})])]):_vm._e(),(_vm.zusammenfassung)?_c('adresse-zusammenfassung',{attrs:{"str":_vm.strEingabe,"nr":_vm.nrEingabe,"plz":_vm.plzEingabe,"ort":_vm.ortEingabe,"land":_vm.partner.anschrift.land,"vermerk":_vm.vermerkEingabe,"teil":_vm.teilEingabe},on:{"fertig":_vm.abgesendet}}):_vm._e()],1),_c('div',{staticClass:"col-xl-4"},[_c('vertreter-info',{attrs:{"ueberschrift":_vm.vertreterUeberschrift,"mail-adresse":true,"nachricht":true,"vertrLink":true,"termin":true,"social-media":true,"vertreter":_vm.vertreter}})],1)])],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }