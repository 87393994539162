var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"container container-kalibrierung"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 bankverbindung-titel"},[_c('div',{staticClass:"text-links ueberschrift-mit-hinweis copy_bold_18",attrs:{"id":"aktive"}},[_vm._v("Für welche Verträge soll die neue Bankverbindung gelten? ("+_vm._s(_vm.gefilterteVertraege.length)+") "),_c('div',{staticClass:"hilfe-icon",class:{'vgh-blau-bg': _vm.mandant === 1,
                    'oesa-gruen-bg': _vm.mandant === 3,
                    'oevo-blau-bg': _vm.mandant === 5},on:{"click":function($event){if($event.target !== $event.currentTarget){ return null; }return _vm.toggleHinweis.apply(null, arguments)}}},[_vm._v("? "),(_vm.showHinweis)?_c('div',{staticClass:"hilfe-text"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{attrs:{"id":"close-btn"},on:{"click":_vm.schliesseHinweis}},[_c('em',{staticClass:"fa-solid fa-xmark"})])])]),_vm._m(0)]):_vm._e()])])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-10"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.alleAuswaehlen),expression:"alleAuswaehlen"}],class:{'input-vgh': _vm.mandant === 1,
                  'input-oesa': _vm.mandant === 3,
                  'input-oevo': _vm.mandant === 5},attrs:{"id":"alle-auswaehlen-checkbox","type":"checkbox"},domProps:{"checked":Array.isArray(_vm.alleAuswaehlen)?_vm._i(_vm.alleAuswaehlen,null)>-1:(_vm.alleAuswaehlen)},on:{"change":[function($event){var $$a=_vm.alleAuswaehlen,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.alleAuswaehlen=$$a.concat([$$v]))}else{$$i>-1&&(_vm.alleAuswaehlen=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.alleAuswaehlen=$$c}},_vm.alleEinchecken]}}),_c('label',{staticClass:"copy_bold_16 alle-auswaehlen",attrs:{"for":"alle-auswaehlen-checkbox"}},[_vm._v(" Alle Verträge auswählen ")])])]),_c('div',{staticClass:"row"},_vm._l((_vm.gefilterteVertraege),function(vertrag,index){return _c('div',{key:index,staticClass:"copy_16 col-xl-4 col-kalibrierung",class:{ 'col-auto': (_vm.windowWidth > 576) },attrs:{"id":"karten"}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.eingecheckteVertraege),expression:"eingecheckteVertraege"}],staticClass:"vertragInput",class:{'input-vgh': _vm.mandant === 1,
                    'input-oesa': _vm.mandant === 3,
                    'input-oevo': _vm.mandant === 5},attrs:{"id":index,"type":"checkbox","required":""},domProps:{"value":vertrag,"checked":Array.isArray(_vm.eingecheckteVertraege)?_vm._i(_vm.eingecheckteVertraege,vertrag)>-1:(_vm.eingecheckteVertraege)},on:{"change":function($event){var $$a=_vm.eingecheckteVertraege,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=vertrag,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.eingecheckteVertraege=$$a.concat([$$v]))}else{$$i>-1&&(_vm.eingecheckteVertraege=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.eingecheckteVertraege=$$c}}}}),_c('label',{staticClass:"bankverbindung-vertrag-checkbox",attrs:{"for":index}},[_c('div',{staticClass:"cursor-pointer h-100 w-100"},[_c('karte-vertrag-bank',{attrs:{"versicherungsscheinnummer":vertrag.vsnr,"produktname":vertrag.produktname,"spezifisches-merkmal":vertrag.spezifischesMerkmal,"iban":vertrag.iban,"checked":_vm.checkboxChecked(index)}})],1)])])}),0),_c('div',{staticClass:"row"},[_vm._m(1),_c('div',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.bankverbindungLoeschen),expression:"bankverbindungLoeschen"}],class:{'input-vgh': _vm.mandant === 1,
                  'input-oesa': _vm.mandant === 3,
                  'input-oevo': _vm.mandant === 5},attrs:{"id":"bankverbindung-loeschen","type":"radio"},domProps:{"value":true,"checked":_vm._q(_vm.bankverbindungLoeschen,true)},on:{"change":function($event){_vm.bankverbindungLoeschen=true}}}),_c('label',{staticClass:"copy_bold_16 alle-auswaehlen",attrs:{"for":"bankverbindung-loeschen"}},[_vm._v(" Ja ")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.bankverbindungLoeschen),expression:"bankverbindungLoeschen"}],class:{'input-vgh': _vm.mandant === 1,
                  'input-oesa': _vm.mandant === 3,
                  'input-oevo': _vm.mandant === 5},attrs:{"id":"bankverbindung-nicht-loeschen","type":"radio"},domProps:{"value":false,"checked":_vm._q(_vm.bankverbindungLoeschen,false)},on:{"change":function($event){_vm.bankverbindungLoeschen=false}}}),_c('label',{staticClass:"copy_bold_16 alle-auswaehlen",attrs:{"for":"bankverbindung-nicht-loeschen"}},[_vm._v(" Nein ")])])]),_c('div',{staticClass:"row"},[_c('button',{staticClass:"weiter button-ivv white",class:{'button-vgh': _vm.mandant === 1,
        'button-oesa': _vm.mandant === 3,
        'button-oevo': _vm.mandant === 5},attrs:{"disabled":_vm.weiterButtonDeaktiviert},on:{"click":_vm.zusammenfassung}},[_vm._v(" Weiter ")])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('span',{staticClass:"hinweis-text-span"},[_vm._v(" Änderungen sind ausschließlich bei Verträgen möglich, bei denen Sie Versicherungsnehmer sind. Sollte der ausgewählte Vertrag die Zahlweise \"Rechnung\" aufweisen, wird dieser auf Lastschrift umgestellt. ")])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-12"},[_c('p',{staticClass:"copy_bold_18 alle-auswaehlen"},[_vm._v("Soll die alte Bankverbindung gelöscht werden?")])])}]

export { render, staticRenderFns }