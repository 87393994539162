var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"d-flex justify-content-start suche",style:(_vm.windowWidth <= 576 ? 'margin-top: ' + _vm.mobileMargin + 'em' :
       _vm.windowWidth <= 992 ? 'margin-top: ' + _vm.tabletMargin + 'em' : '')},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.suchtextPreview),expression:"suchtextPreview"}],ref:"suche",staticClass:"copy_16",class:{'vgh-search': _vm.mandant === 1,
                      'oesa-search': _vm.mandant === 3,
                      'oevo-search': _vm.mandant === 5},style:({width: _vm.windowWidth > 576 ? _vm.inputWidth : 'auto'}),attrs:{"type":"search","id":"suche","placeholder":"Suchen","maxlength":"40"},domProps:{"value":(_vm.suchtextPreview)},on:{"keyup":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.sucheAnwenden.apply(null, arguments)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"delete",[8,46],$event.key,["Backspace","Delete","Del"])){ return null; }return _vm.behandleDelete.apply(null, arguments)}],"input":function($event){if($event.target.composing){ return; }_vm.suchtextPreview=$event.target.value}}}),(this.suchtextPreview.length)?_c('button',{staticClass:"cancelSearchBtn d-block d-md-none",on:{"click":_vm.suchtextVerwerfen}},[_c('em',{staticClass:"fa-regular fa-xmark-circle",attrs:{"id":"cancelSearchIcon"}})]):_vm._e(),_c('button',{staticClass:"buttons grayText-Background",attrs:{"id":"sucheButton","disabled":!this.suchtextPreview.length},on:{"click":_vm.sucheAnwenden}},[_c('em',{staticClass:"fa-solid fa-magnifying-glass buttons-icon",attrs:{"id":"sucheIcon"}})]),(_vm.showFilter)?_c('button',{staticClass:"buttons",class:{'button-vgh': _vm.mandant === 1 && _vm.filterPreview.length,
                           'button-oesa': _vm.mandant === 3 && _vm.filterPreview.length,
                           'button-oevo': _vm.mandant === 5 && _vm.filterPreview.length,
                           'grayText-Background': !_vm.filterPreview.length},attrs:{"id":"filterButton"},on:{"click":_vm.filterMenuToggle}},[_c('em',{staticClass:"fa-solid fa-filter buttons-icon",attrs:{"id":"filterIcon"}})]):_vm._e()]),(_vm.showFilter && _vm.filterMenuActive)?_c('div',{directives:[{name:"on-clickaway",rawName:"v-on-clickaway",value:(_vm.onClickAway),expression:"onClickAway"}],ref:"filterMenu",staticClass:"col-12 filterMenu",attrs:{"id":"filterMenu"}},[_vm._l((_vm.filterItems),function(filterItem,index){return _c('b-form-checkbox',{key:index,staticClass:"filterMenu-Checkbox",attrs:{"value":filterItem},model:{value:(_vm.filterPreview),callback:function ($$v) {_vm.filterPreview=$$v},expression:"filterPreview"}},[_vm._v(_vm._s(filterItem)+" ")])}),_c('div',{staticClass:"filterMenu-ButtonDiv"},[_c('button',{staticClass:"button-ivv white filterMenu-Button",class:{'button-vgh': _vm.mandant === 1,
                           'button-oesa': _vm.mandant === 3,
                           'button-oevo': _vm.mandant === 5},on:{"click":_vm.filterAnwenden}},[_vm._v("Anwenden ")]),_c('button',{staticClass:"button-ivv filterMenu-Button",on:{"click":_vm.resetFilter}},[_vm._v("Abbrechen ")])])],2):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }