<template>
  <div>
    <div class="header-nect">
      <h2 class="header-text">Nect Authentifizierung</h2>
    </div>
    <div class="body-nect row">
      <div v-if="success === true">
        <em id="icon-success"
           class="fa-regular fa-3x fa-circle-check"
           :class="{'vgh-blau-color': mandant === 1,
            'oesa-gruen-color': mandant === 3,
            'oevo-blau-color': mandant === 5}"></em>
        <h3>{{ messageHeader }}</h3>
        <p class="copy_16 abstand-unten-40">
          Ihre Authentifizierung mit Nect war erfolgreich und kann auch
          in Zukunft für weitere Vorgänge genutzt werden.
          Ihre Daten sind verschlüsselt und nur innerhalb von Nect gespeichert.
        </p>
        <div class="nect-validierung-btn">
          <div>
            <input type="button"
                   id="nect-weiter-btn"
                   class="button-ivv abstand-unten-17 white"
                   :class="{'button-vgh': mandant === 1,
                      'button-oesa': mandant === 3,
                      'button-oevo': mandant === 5}"
                   value="Weiter"
                   @click="weiter">
          </div>
        </div>
      </div>
      <div v-if="success === false">
        <em id="icon-failure"
           class="fa-regular fa-3x fa-xmark-circle"></em>
        <h3>{{ messageHeader }}</h3>
        <p class="copy_16 abstand-unten-40">Ihre Online-Authentifizierung ist leider fehlgeschlagen.
          Bitte versuchen Sie es erneut oder wenden Sie sich an unser
          <a :href=$store.state.weiterleitungen.faq target="_blank" rel=noopener>
            Supportteam
          </a>
          .
        </p>
        <div class="nect-validierung-btn">
          <div>
            <input type="button"
                   id="nect-neustart-btn"
                   class="button-ivv abstand-unten-17 white"
                   :class="{'button-vgh': mandant === 1,
                      'button-oesa': mandant === 3,
                      'button-oevo': mandant === 5,
                      'button-disabled': nectNeustartButtonDisabled}"
                   :disabled="nectNeustartButtonDisabled"
                   value="Erneut versuchen"
                   @click="neustart">
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import api from '@/backend-api';
import mandant from '@/mandant';
import Vue from 'vue';

export default {
  name: 'NectValidierung',
  mixins: [mandant],
  props: {
    cUUID: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      success: '',
      messageHeader: '',
      nectNeustartButtonDisabled: false,
    };
  },
  methods: {
    async neustart() {
      this.nectNeustartButtonDisabled = true;
      await api.legeNectCaseAn(Vue.$keycloak.token)
        .then((responce) => {
          this.cUUID = responce.data;
        }).catch((error) => {
          console.log('Nect Case not created!', error);
        });
      this.nectNeustartButtonDisabled = false;
      this.$router.push({ name: 'NectStart', params: { cUUID: this.cUUID } });
    },
    weiter() {
      window.parent.postMessage('Nect erfolgreich abgeschlossen', window.location.origin);
    },
  },
  mounted() {
    this.$emit('view-geladen');
  },
  beforeMount() {
    api.validiereNectCase(Vue.$keycloak.token, this.cUUID)
      .then((responce) => {
        if (responce.data === true) {
          this.success = true;
          this.messageHeader = 'Authentifizierung erfolgreich';
        } else {
          this.success = false;
          this.messageHeader = 'Authentifizierung fehlgeschlagen!';
        }
      });
  },
};
</script>

<style scoped>
.nect-validierung-btn {
  margin-top: 60px;
}

#icon-failure, #icon-success {
  font-size: 48px;
  font-weight: 200;
  margin-bottom: 10px;
}

#icon-failure {
  color: #e30613;
}

.header-nect {
  height: 75px;
  border-bottom-color: #EDF0F3;
  border-bottom-style: groove;
  border-bottom-width: thin;
}

.header-text{
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.button-disabled, .button-disabled:hover {
  opacity: 0.3;
  box-shadow: none;
  cursor: default;
}

.body-nect {
  height: 275px;
  padding: 20px 30px;
  width: 75%;
  margin: auto;
}

/* Tablet View */
@media (max-width: 768px) {
  .body-nect {
    width: 80%;
  }
}

/* Mobile View */
@media (max-width: 576px) {
  .body-nect {
    width: 100%;
  }
}

/* Mobile View */
@media (max-width: 576px) {
  #nect-validierung-btn {
    margin-top: 30px;
  }
}
</style>
