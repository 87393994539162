<!-- In dieser Vorlage werden die Öffnungszeiten für jeden Wochentag
     in einer Tabelle dargestellt -->
<template>
  <div>
    <h2 class="copy_16"><strong>Öffnungszeiten</strong></h2>
    <table>
      <tr v-for="oeffnungszeit in getOeffnungszeiten"
          :key="oeffnungszeit.wochentag"
          class="copy_16">
        <td id="wochentag">
          {{oeffnungszeit.wochentag}}:
        </td>
        <!-- Falls es Öffnungszeiten gibt, iteriere durch die Öffnungsintervalle,
             ansonsten Geschlossen-->
        <td v-if="oeffnungszeit.oeffnungsintervallDTO.length !== 0" >
          <!-- Iteration der Öffnungsintervalle -->

          <span v-for="(oeffnungsint, index) in oeffnungszeit.oeffnungsintervallDTO" :key="index">
            <!-- Falls letztes Element, kein zusätzliches Komma -->
            <span v-if="oeffnungszeit.oeffnungsintervallDTO.length === index+1">
              {{oeffnungsint.start + ' - ' + oeffnungsint.ende}}
            </span>
            <!-- Sonst mit Komma und Abstand zum nächsten Intervall-->
            <span v-else id="abstand">
              {{oeffnungsint.start + ' - ' + oeffnungsint.ende}} und
            </span>
          </span>
        </td>
        <td v-else>
          Geschlossen
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';

export default {
  name: 'Oeffnungszeiten',
  computed: {
    ...mapState(['vertreter']),
    ...mapGetters(['getOeffnungszeiten']),
  },
};
</script>

<style scoped>
#wochentag{
  padding-right: 2mm;
}
</style>
