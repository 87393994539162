export default {
  props: ['bestaetigung'],
  async beforeMount() {
    if (this.bestaetigung) {
      this.$root.$refs.app.setBestaetigung(this.bestaetigung);
    }
  },
  beforeRouteLeave(to, from, next) {
    this.$root.$refs.app.setBestaetigung('');
    next();
  },
};
