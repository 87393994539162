<template>
  <div>
    <div id="overlay" :class="{'vgh-blau-bg': mandant === 1,
    'oesa-gruen-bg': mandant === 3,
    'oevo-blau-bg': mandant === 5}"></div>
    <div id="info-banner-container" class="container row">
      <div class="row">
        <div class="col-12">
          <div>
            <h3 class="info-titel">Hinweis</h3>
            <p class="copy_16 info-text">
              Wir möchten Sie darauf hinweisen,
              dass all Ihre
              <span v-if="mandant === 1">VGH Verträge</span>
              <span v-else-if="mandant === 3">ÖSA Verträge</span>
              <span v-else-if="mandant === 5">Verträge bei der Öffentlichen</span>
              entweder von einem Makler oder einer Sparkasse betreut werden.
              <br>
              Ihnen stehen daher ab sofort nicht mehr alle
              Funktionen im Kundenportal zur Verfügung.
            </p>
          </div>
        </div>
      </div>
      <div class="row" id="btn-row">
        <div class="col-12">
          <div>
            <input type="button"
                   class="button-ivv white zustimmung-btn"
                   :class="{'button-vgh': mandant === 1,
                      'button-oesa': mandant === 3,
                      'button-oevo': mandant === 5}"
                   value="Verstanden"
                   @click="emitZustimmung"
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import mandant from '@/mandant';

export default {
  name: 'InfoBanner',
  mixins: [mandant],
  methods: {
    emitZustimmung() {
      this.$emit('zustimmung');
    },
    emitAbbruch() {
      this.schliesseAbfrage();
      this.$emit('abbruch');
    },
  },
};
</script>

<style scoped>
#overlay {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 10;
  opacity: 0.3;
}

#btn-row{
  padding-left: 15px;
  margin-bottom: 58px;
}

#close-btn {
  position: absolute;
  top: 35px;
  right: 35px;
  z-index: 1;
}

#info-banner-container {
  display: inline-block;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  max-width: 730px;
  height: fit-content;
  border-radius: 2px;
  text-align: left;
  filter: drop-shadow(0px 10px 20px rgba(0, 0, 0, 0.15));
  margin: auto;
  background-color: #ffffff;
  z-index: 11; /* 1px higher than the overlay layer */
}

.info-text {
  padding: 0 15px;
  margin-bottom: 30px;
}

.info-titel {
  padding: 0 15px;
  margin-top: 62px;
  margin-bottom: 20px;
}

.postfach-link {
  color: #6b7171;
}

.zustimmung-btn {
  margin-right: 15px;
}

/* Mobile View */
@media (max-width: 750px) {
  #info-banner-container {
    margin: 40px 15px;
    width: auto;
  }
  #btn-row{
    margin-bottom: 25px;
  }
  #close-btn {
    top: 20px;
    right: 20px;
  }
  .zustimmung-btn {
    margin-bottom: 15px;
  }
  .info-titel {
    margin-top: 40px;
    margin-bottom: 10px;
  }
  .info-text {
    margin-bottom: 20px;
  }
}
</style>
