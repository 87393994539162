import Vue from 'vue';
import axios from 'axios';
import VueAxios from 'vue-axios';

Vue.use(VueAxios, axios);

const API_BASE_PATH = 'api/draft';
const AXIOS = axios.create({
  baseURL: `${process.env.VUE_APP_API_URL}${API_BASE_PATH}`,
});

export default {
  aktiviereBenutzerIvas(accessToken) {
    return AXIOS.post('/benutzer/registrierung/aktivierung-ivas',
      '',
      {
        headers: {
          authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
      });
  },
  hasMapping(accessToken) {
    return AXIOS.get('/vertretung/mapping', {
      headers: {
        authorization: `Bearer ${accessToken}`,
      },
    });
  },
  getEinzelVertrag(vsnr, laufendenr, produktkey, accessToken) {
    return AXIOS.get('/vertrag/einzel', {
      headers: {
        authorization: `Bearer ${accessToken}`,
        vsnr,
        laufendenr,
        produktkey,
      },
    });
  },
  getVertreter(accessToken) {
    return AXIOS.get('/vertretung/daten', {
      headers: {
        authorization: `Bearer ${accessToken}`,
      },
    });
  },
  getVertreterdatenByKonversationsId(konversationsId, accessToken) {
    return AXIOS.get('/vertretung/konversationsId', {
      headers: {
        authorization: `Bearer ${accessToken}`,
        konversationsId,
      },
    });
  },
  getPartner(accessToken) {
    return AXIOS.get('/partner/daten', {
      headers: {
        authorization: `Bearer ${accessToken}`,
      },
    });
  },
  getNachrichtPageGrouped(seite, anzahl, suchtext, vertragfilter, status, accessToken) {
    const params = {
      status,
      seite,
      anzahl,
    };

    if (suchtext.length > 0) params.suchtext = suchtext;
    if (vertragfilter.length) params.vertragfilter = vertragfilter;

    return AXIOS.get('/nachricht/gruppiert', {
      headers: {
        authorization: `Bearer ${accessToken}`,
      },
      params,
    });
  },
  getNachrichtenByKonversationsId(konversationsId, accessToken) {
    return AXIOS.get('/nachricht/konversation', {
      headers: {
        authorization: `Bearer ${accessToken}`,
      },
      params: {
        konversationsId,
      },
    });
  },
  setNachrichtenGeloeschtByKonversationsId(konversationsId, accessToken) {
    return AXIOS.delete('/nachricht/konversation',
      {
        headers: {
          authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
        params: {
          konversationsId,
        },
      });
  },
  getDokument(uploadName, accessToken) {
    return AXIOS.get('/upload', {
      headers: {
        authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
      params: {
        uploadName,
      },
    });
  },
  getAlleDokumenteAlsZip(accessToken) {
    return AXIOS.get('/nachricht/upload/zip', {
      headers: {
        authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
    });
  },
  setNachrichtenZuKonversationsIdGelesen(konversationsId, accessToken) {
    return AXIOS.put('nachricht/gelesen/konversation',
      '',
      {
        headers: {
          authorization: `Bearer ${accessToken}`,
          konversationsId,
          'Content-Type': 'application/json',
        },
      });
  },
  getPostfachStatus(accessToken) {
    return AXIOS.get('/nachricht/postfachstatus', {
      headers: {
        authorization: `Bearer ${accessToken}`,
      },
    });
  },
  getVertraege(accessToken) {
    return AXIOS.get('/vertrag/alle', {
      headers: {
        authorization: `Bearer ${accessToken}`,
      },
    });
  },
  getVertraegeGruppiert(accessToken) {
    return AXIOS.get('/vertrag/gruppiert', {
      headers: {
        authorization: `Bearer ${accessToken}`,
      },
    });
  },
  getGruppenVertraege(vsnr, accessToken) {
    return AXIOS.get('/vertrag/gruppe', {
      headers: {
        authorization: `Bearer ${accessToken}`,
        vsnr,
      },
    });
  },
  getVertreterVertraege(accessToken) {
    return AXIOS.get('/vertrag/vertreter', {
      headers: {
        authorization: `Bearer ${accessToken}`,
      },
    });
  },
  getGruppenVertreterVetraege(vsnr, accessToken) {
    return AXIOS.get('/vertrag/vertreter/gruppe', {
      headers: {
        authorization: `Bearer ${accessToken}`,
        vsnr,
      },
    });
  },
  getVertreterliste(accessToken) {
    return AXIOS.get('/vertretung/auswahl', {
      headers: {
        authorization: `Bearer ${accessToken}`,
      },
    });
  },
  getZugriffsStatus(accessToken) {
    return AXIOS.get('/benutzer/zugriffsstatus', {
      headers: {
        authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
    });
  },
  getWeiterleitungen(accessToken) {
    return AXIOS.get('/frontend-config', {
      headers: {
        authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
    });
  },
  getVertreterAnzahl(accessToken) {
    return AXIOS.get('/vertretung/vertreteranzahl', {
      headers: {
        authorization: `Bearer ${accessToken}`,
      },
    });
  },
  sendeAktualisiereKundendaten(accessToken) {
    return AXIOS.get('/kundendaten/aktualisieren', {
      headers: {
        authorization: `Bearer ${accessToken}`,
      },
    });
  },
  setVertreter(vertreternummer, accessToken) {
    return AXIOS.put(
      '/vertretung/auswahl',
      '',
      {
        headers: {
          authorization: `Bearer ${accessToken}`,
          vertreternummer,
          'Content-Type': 'application/json',
        },
      },
    );
  },
  setPartnerAnschrift(anschrift, accessToken) {
    return AXIOS.put(
      '/partner/anschrift',
      {
        strasse: anschrift.strasse,
        hausnummer: anschrift.hausnummer,
        postleitzahl: anschrift.postleitzahl,
        ort: anschrift.ort,
        land: anschrift.land,
        ortsteil: anschrift.ortsteil,
        zustellvermerk: anschrift.zustellvermerk,
      },
      {
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
      },
    );
  },
  sendeNeueIban(iban, accessToken) {
    return AXIOS.put('/vertrag/iban',
      iban,
      {
        headers: {
          authorization: `Bearer ${accessToken}`,
          'Content-Type': 'multipart/form-data',
        },
      });
  },
  sendeNachricht(nachricht, accessToken) {
    return AXIOS.put('/vertretung/nachricht',
      nachricht,
      {
        headers: {
          authorization: `Bearer ${accessToken}`,
          'Content-Type': 'multipart/form-data',
        },
      });
  },
  sendeNachrichtzuKonversation(nachricht, accessToken) {
    return AXIOS.put('/nachricht/antwort',
      nachricht,
      {
        headers: {
          authorization: `Bearer ${accessToken}`,
          'Content-Type': 'multipart/form-data',
        },
      });
  },
  sendeVertragsvertreterNachricht(nachricht, accessToken) {
    return AXIOS.post('/vertrag/nachricht',
      nachricht,
      {
        headers: {
          authorization: `Bearer ${accessToken}`,
          'Content-Type': 'multipart/form-data',
        },
      });
  },
  sendePersoenlicheDaten(dateien, accessToken) {
    return AXIOS.put('/partner/profil',
      dateien,
      {
        headers: {
          authorization: `Bearer ${accessToken}`,
          'Content-Type': 'multipart/form-data',
        },
      });
  },
  sendeDokument(dokument, accessToken) {
    return AXIOS.post('/vertrag/dokument',
      dokument,
      {
        headers: {
          authorization: `Bearer ${accessToken}`,
          'Content-Type': 'multipart/form-data',
        },
      });
  },
  sendeTerminanfrage(anfrage, emailAdresse, mandantengruppenId, accessToken) {
    return AXIOS.put('/vertretung/termin',
      anfrage,
      {
        headers: {
          authorization: `Bearer ${accessToken}`,
          emailAdresse,
          mandantengruppenId,
          'Content-Type': 'multipart/form-data',
        },
      });
  },
  sendeSchadenmeldung(schadenmeldung, accessToken) {
    return AXIOS.post('/vertrag/schadenmeldung',
      schadenmeldung,
      {
        headers: {
          authorization: `Bearer ${accessToken}`,
          'Content-Type': 'multipart/form-data',
        },
      });
  },
  // eslint-disable-next-line max-len
  aktualisiereEmailAdresse(accessToken) {
    return AXIOS.put('/benutzer',
      '',
      {
        headers: {
          authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
      });
  },
  sendeWerbeeinwilligung(werbeeinwilligung, accessToken) {
    return AXIOS.put('/benutzer/werbeeinwilligung',
      '',
      {
        headers: {
          authorization: `Bearer ${accessToken}`,
          werbeeinwilligung,
          'Content-Type': 'application/json',
        },
      });
  },
  sendePortalzugangDeaktiviert(accessToken) {
    return AXIOS.delete('/benutzer',
      {
        headers: {
          authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
      });
  },
  sendePortalzugangReaktiviert(accessToken) {
    return AXIOS.post('/benutzer/reaktivierung',
      '',
      {
        headers: {
          authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
      });
  },
  istNectErreichbar(accessToken) {
    return AXIOS.get('/benutzer/registrierung/nect/health',
      {
        headers: {
          authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
      });
  },
  legeNectCaseAn(accessToken) {
    return AXIOS.post('/benutzer/registrierung/nect/case',
      '',
      {
        headers: {
          authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
      });
  },
  validiereNectCase(accessToken, cUUID) {
    return AXIOS.post('benutzer/registrierung/nect/validierung',
      '',
      {
        headers: {
          authorization: `Bearer ${accessToken}`,
          cUUID,
          'Content-Type': 'application/json',
        },
      });
  },
};
