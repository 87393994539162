<template>
  <div>
    <div v-if="loading && (keycloak.benutzerstatus !== 'angelegt')">
      <div class="spinner-border"
           :class="{'vgh-blau-color': mandant === 1,
              'oesa-gruen-color': mandant === 3,
              'oevo-blau-color': mandant === 5}" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
    <div v-else>
      <div class="hintergrund-fueller" v-if="windowWidth < 760">
      </div>
      <div id="overlay"></div>
      <div id="vertreterauswahl-container" class="container row">
        <div class="row">
          <div id="vertreterauswahl">
            <div id="titel" class="row">
              <div class="col">
              <span class="copy_16">
                Bitte suchen Sie sich nun eine Vertretung aus, welche Sie im Kundenportal begleitet.
              </span>
              </div>
            </div>
            <div class="row">
              <div v-for="(vertreter, index) in vertreterliste"
                   :key="vertreter.vertreternummer"
                   class="col"
                   :class="{ 'col-auto': (windowWidth > 769) }">
                <vertreterauswahlkarte
                  :index="index"
                  :vertreter="vertreter"
                  :selected-vertreter="selectedVertreter"
                  @selectVertreter="updateSelectedVertreter">
                </vertreterauswahlkarte>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <input
                  type="button"
                  @click="setVertreter"
                  :disabled="weiterDeaktiviert"
                  value="Weiter"
                  class="button-ivv white"
                  :class="{'button-vgh': mandant === 1,
                      'button-oesa': mandant === 3,
                      'button-oevo': mandant === 5}"
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/backend-api';
import Vertreterauswahlkarte from '@/components/Vertreterauswahlkarte.vue';
import mandant from '@/mandant';
import Vue from 'vue';

export default {
  name: 'Vertreterauswahl',
  mixins: [mandant],
  components: {
    Vertreterauswahlkarte,
  },

  data() {
    return {
      keycloak: Vue.$keycloak,
      loading: true,
      selectedVertreter: {},
      weiterDeaktiviert: true,
      vertreterliste: [],
    };
  },
  async beforeMount() {
    if (!this.$store.state.vertreter.length) {
      this.vertreterliste = await api.getVertreterliste(Vue.$keycloak.token)
        .then((response) => response.data)
        .catch((error) => {
          console.log(error);
        });
      if (this.vertreterliste.length === 1) {
        [this.selectedVertreter] = this.vertreterliste;
        await this.setVertreter();
      } else if (this.vertreterliste.length === 0) {
        await this.setDefaultVertreter();
      } else {
        this.loading = false;
      }
    }
  },
  methods: {
    updateSelectedVertreter(selectedVertreter) {
      this.selectedVertreter = selectedVertreter;
    },
    async setDefaultVertreter() {
      await this.$store.dispatch('ladeVertreter');
      this.$emit('ausgewaehlt');
    },
    async setVertreter() {
      this.weiterDeaktiviert = true;
      await api.setVertreter(this.selectedVertreter.vertreternummer,
        Vue.$keycloak.token);
      await this.$store.dispatch('ladeVertreter');
      this.$emit('ausgewaehlt', this.vertreterliste.length);
    },
  },
  watch: {
    selectedVertreter() {
      if (this.selectedVertreter.length !== 0) {
        this.weiterDeaktiviert = false;
      } else {
        this.weiterDeaktiviert = true;
      }
    },
  },
};
</script>

<style scoped>
.auswahl-button {
  margin-top: 3px;
  margin-right: 9px;
  -webkit-transform: scale(1.7);
}

.auswahl-button:checked {
  background-color: white;
  box-shadow: inset 0 0 0 4px black;
}

.hintergrund-fueller {
  width: 100%;
  bottom: 0;
  z-index: -1;
  height: 100vh;
}

#overlay {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.5);
}

#vertreterauswahl-container {
  display: inline-block;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  max-width: 1110px;
  /*max-height: 594px;*/
  height: fit-content;
  border-radius: 2px;
  text-align: left;
  filter: drop-shadow(0px 10px 20px rgba(0, 0, 0, 0.15));
  margin: auto;
  padding-bottom: 62px;
  background-color: #ffffff;
  z-index: 11; /* 1px higher than the overlay layer */
}

#vertreterauswahl {
  margin-left: 30px;
}

#titel {
  margin-top: 60px;
  margin-bottom: 60px;
}

@media (max-width: 769px) {
  .col {
    flex-basis: auto;
  }

  #titel {
    margin-top: 30px;
    margin-bottom: 20px;
  }

  #vertreterauswahl-container {
    width: auto;
    display: inline-table;
    padding-bottom: 30px;
    position:absolute;
    left: 4%;
    right: 4%;
    top: 0;
  }

  #vertreterauswahl {
    margin-left: 15px;
    margin-right: 15px;
    height: auto;
  }
}
</style>
