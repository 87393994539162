var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"row"},[(_vm.windowWidth > 767)?_c('div',{staticClass:"abstand-unten-15",class:_vm.columnSize,on:{"dragover":function($event){$event.preventDefault();},"drop":function($event){$event.preventDefault();}}},[_c('div',{staticClass:"drop-feld",class:{'drop-feld-vgh': _vm.mandant === 1,
           'drop-feld-oesa': _vm.mandant === 3,
           'drop-feld-oevo': _vm.mandant === 5},on:{"drop":_vm.dropDatei}},[_c('h3',{staticClass:"copy_16 drop-text"},[_vm._v(_vm._s(_vm.uploadBezeichnung)+" hierhin ziehen oder")]),_c('input',{attrs:{"type":"file","id":"datei-auswahl","multiple":"","accept":".jpeg, .jpg, .png, .pdf"},on:{"change":_vm.dateiAuswaehlen}}),_c('input',{staticClass:"button-ivv upload-button",attrs:{"type":"button","value":"Dateien für Upload auswählen"},on:{"click":_vm.clickAuswahl}})])]):_vm._e(),(_vm.fehlerUpload)?_c('label',{staticClass:"copy_12 col-12 fehler-text",class:{'fehler-text-oesa': _vm.mandant === 3,
           'fehler-text-oevo': _vm.mandant === 5}},[_vm._v(_vm._s(_vm.fehlerText))]):_vm._e(),_c('div',{staticClass:"col-12"},[_vm._t("default")],2),(_vm.dateien.length)?_c('div',{staticClass:"col-12"},_vm._l((_vm.dateien),function(datei,index){return _c('ul',{key:index,staticClass:"liste row"},[_c('li',{staticClass:"copy_16 listen-element",class:{'vgh-blau-color': _vm.mandant === 1,
            'oesa-gruen-color': _vm.mandant === 3,
            'oevo-blau-color': _vm.mandant === 5}},[_c('em',{staticClass:"fa-solid fa-paperclip",class:{'vgh-blau-color': _vm.mandant === 1,
              'oesa-gruen-color': _vm.mandant === 3,
              'oevo-blau-color': _vm.mandant === 5}}),_c('label',{attrs:{"id":"datei-name copy_16"}},[_vm._v(_vm._s(datei.name.normalize("NFC"))+" ")]),(datei.size < 1048576)?_c('label',[_vm._v("("+_vm._s(_vm.bZuKb(datei.size))+"KB)")]):_c('label',[_vm._v("("+_vm._s(_vm.bZuMb(datei.size))+"MB)")]),_c('button',{staticClass:"x-button",on:{"click":function($event){$event.preventDefault();return _vm.dateiLoeschen(_vm.dateien, index)}}},[_c('em',{staticClass:"fa-solid fa-xmark"})])])])}),0):_vm._e(),(_vm.windowWidth < 768)?_c('div',{staticClass:"col-12"},[_c('input',{attrs:{"type":"file","id":"anhang","multiple":"","accept":".jpeg, .jpg, .png, .pdf"},on:{"change":_vm.dateiAuswaehlen}}),_c('button',{staticClass:"anhang-hinzu",class:{'vgh-blau-color': _vm.mandant === 1,
          'oesa-gruen-color': _vm.mandant === 3,
          'oevo-blau-color': _vm.mandant === 5},on:{"click":_vm.clickAnhang}},[_c('em',{staticClass:"fa-solid fa-plus",class:{'vgh-blau-color': _vm.mandant === 1,
            'oesa-gruen-color': _vm.mandant === 3,
            'oevo-blau-color': _vm.mandant === 5}}),_c('a',{staticClass:"copy_16 anhang-text",class:{'vgh-blau-color': _vm.mandant === 1,
            'oesa-gruen-color': _vm.mandant === 3,
            'oevo-blau-color': _vm.mandant === 5}},[_vm._v(_vm._s(_vm.uploadBezeichnung)+" hinzufügen")])])]):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }