<template>
  <div>
    <sicherheitsabfrage
      v-if="verlasseSeiteAbfrage"
      typ="verlassen"
      @zustimmung="behandleSeiteVerlassen"
      @ablehnung="schliesseAbfrage"
      @abbruch="schliesseAbfrage">
    </sicherheitsabfrage>
    <div class="container mobile-container">
      <div class="row">
        <div id="vertreterauswahl">
          <div class="row">
            <span class="copy_16" id="titel">
              Bitte suchen Sie sich nun eine Vertretung aus, welche Sie im Kundenportal begleitet.
            </span>
          </div>
          <div class="row">
            <div v-for="(vertreter, index) in vertreterliste"
                 :key="vertreter.vertreternummer"
                 class="col"
                 :class="{ 'col-auto': (windowWidth > 769) }"
                 >
              <div>
                <label class="cursor-pointer kartenlabel">
                <span class="vertreter-karte row"
                     :class="{'ausgewaehlt-vgh': ausgewaehlterVertreter(vertreter) && mandant === 1,
                        'ausgewaehlt-oevo': ausgewaehlterVertreter(vertreter) && mandant === 5,
                        'ausgewaehlt-oesa': ausgewaehlterVertreter(vertreter) && mandant === 3}">
                  <span
                    v-if="ausgewaehlterVertreter(vertreter)"
                    class="copy_16 ausgewaehlt-text">
                    <span v-if="windowWidth > 576">Ihre aktuelle Portalvertretung</span>
                    <span v-else>Ihre aktuelle Vertretung</span>
                  </span>
                  <br v-else>
                  <span class="row radio-button-zeile"
                        :class="{'radio-button-zeile-vgh': mandant === 1}">
                    <input v-model="selectedVertreter"
                           :class="{'aktueller-radio': ausgewaehlterVertreter(vertreter),
                           'input-vgh': mandant === 1 && !ausgewaehlterVertreter(vertreter),
                           'aktueller-radio-vgh': mandant === 1
                              && ausgewaehlterVertreter(vertreter),
                            'input-oesa': mandant === 3 && !ausgewaehlterVertreter(vertreter),
                            'aktueller-radio-oesa': mandant === 3
                              && ausgewaehlterVertreter(vertreter),
                            'input-oevo': mandant === 5 && !ausgewaehlterVertreter(vertreter),
                            'aktueller-radio-oevo': mandant === 5
                              && ausgewaehlterVertreter(vertreter)}"
                           :id="index"
                           type="radio"
                           :name="vertreter.name"
                           :value="vertreter">
                    <label
                      :for="index"
                      class="copy_18 row"
                      :class="{ 'weiss': ausgewaehlterVertreter(vertreter) }">
                      <p class="vertreter-name">
                        {{ vertreter.name }}
                      </p>
                      </label>
                  </span>
                  <span class="vertreter-bild-container">
                    <img v-if="vertreterbild(vertreter.vertreterBildUrl)"
                         class="vertreter-bild"
                         :class="{'vertreter-bild-vgh': mandant === 1,
                            'vertreter-bild-oevo': mandant === 5}"
                         :src="vertreterbild(vertreter.vertreterBildUrl)"
                         alt=""
                         @error="vertreter.vertreterBildUrl = ''">
                    <img v-else
                         class="vertreter-bild"
                         :class="{'mandanten-bild': mandant === 1}"
                         :src="mandantenBild()"
                          alt="">
                  </span>
                </span>
                </label>
              </div>
            </div>
          </div>
          <label
            class="copy_16 fehler-text row"
            :class="{'fehler-text-oesa': mandant === 3,
            'fehler-text-oevo': mandant === 5}"
            v-if="fehler"> {{ fehlerText }}
          </label>
          <div class="row">
            <input
              type="button"
              @click="setVertreter"
              value="Weiter"
              :disabled="weiterButtonDisabled"
              class="button-ivv white"
              :class="{'button-vgh': mandant === 1,
                'button-oesa': mandant === 3,
                'button-oevo': mandant === 5}">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/backend-api';
import Sicherheitsabfrage from '@/components/Sicherheitsabfrage.vue';
import { mapState } from 'vuex';
import Vue from 'vue';
import sicherheitsabfrage from '@/sicherheitsabfrage';
import mandant from '@/mandant';
import vertreterbildUtil from '@/vertreterbildUtil';

export default {
  name: 'VertreterAendern',
  mixins: [sicherheitsabfrage, mandant, vertreterbildUtil],

  computed: {
    ...mapState(['partner', 'vertreter']),
  },

  components: {
    Sicherheitsabfrage,
  },

  data() {
    return {
      selectedVertreter: {
        vertreternummer: '',
        mandantengruppenId: '',
        name: '',
        anschrift: null,
        telefonnummer: null,
        email: null,
        oeffnungszeiten: null,
      },
      weiterButtonDisabled: true,
      vertreterliste: [],
      fehler: false,
      fehlerText: '',
    };
  },

  methods: {
    vertreterbild(bildUrl) {
      if (bildUrl) {
        return bildUrl;
      }
      return '';
    },
    mandantenBild() {
      return this.getQuadratischesMandantenBild();
    },
    ausgewaehlterVertreter(value) {
      if (value.mandantengruppenId === this.vertreter.mandantengruppenId) {
        if (value.vertreternummer === this.vertreter.vertreternummer) {
          return true;
        }
      }
      return false;
    },

    eingegeben() {
      if (!this.selectedVertreter.mandantengruppenId || !this.selectedVertreter.vertreternummer) {
        return false;
      }
      return this.selectedVertreter.mandantengruppenId !== this.vertreter.mandantengruppenId
        || this.selectedVertreter.vertreternummer !== this.vertreter.vertreternummer;
    },

    async setVertreter() {
      if (this.eingegeben()) {
        this.weiterButtonDisabled = true;
        await api.setVertreter(this.selectedVertreter.vertreternummer,
          Vue.$keycloak.token);
        await this.$store.dispatch('ladeVertreter');
        this.$router.push({
          name: 'Services',
          params: {
            bestaetigung: 'vertreter',
          },
        });
      } else {
        this.fehler = true;
        this.fehlerText = 'Bitte wählen Sie einen neuen Vertreter aus';
      }
      document.getElementById('app').scrollTo(0, 0);
    },
  },

  async beforeMount() {
    if (this.$store.state.vertreterAnzahl === 1) {
      this.$router.push('/');
    }
    this.$root.$refs.app.setZurueckFeld(true);
    this.vertreterliste = await api.getVertreterliste(Vue.$keycloak.token)
      .then((response) => {
        this.weiterButtonDisabled = false;
        return response.data;
      })
      .catch((error) => {
        console.log(error);
      });
    this.selectedVertreter.mandantengruppenId = this.vertreter.mandantengruppenId;
    this.selectedVertreter.vertreternummer = this.vertreter.vertreternummer;
    this.selectedVertreter.name = this.vertreter.name;
  },
  mounted() {
    this.$emit('view-geladen');
  },

  watch: {
    selectedVertreter() {
      this.fehler = false;
    },
  },
};
</script>

<style scoped>
#titel {
  text-align: left;
  margin: 40px 0;
}

#vertreterauswahl {
  margin-left: 30px;
}

.ausgewaehlt-vgh, .ausgewaehlt-oevo {
  background-color: #2b78e4!important;
}

.ausgewaehlt-oesa {
  background-color: #00935d!important;
}

.ausgewaehlt-text {
  text-align: left;
  margin-top: 5px;
  margin-left: 9px;
  color: #FFFFFF;
}

.fehler-text {
  text-align: left;
  margin-bottom: 30px;
}

.aktueller-radio:checked + label::before {
  background: white;
  outline: 2px;
  border: white;
  border-style: solid;
  border-width: 2px;
  box-shadow: inset 0 0 0 2px black;
}

.aktueller-radio-vgh:checked + label::before, aktueller-radio-oevo:checked + label::before {
  box-shadow: inset 0 0 0 2px #2b78e4;
}

.aktueller-radio-oesa:checked + label::before {
  box-shadow: inset 0 0 0 2px #00935d;
}

.aktueller-radio-oesa:not(:checked):hover + label::before {
  background-color: #78bfa4 !important;
}

.radio-button-zeile {
  position: absolute;
  word-wrap: break-word;
  width: 245px;
  top: 40%;
  left: 20px;
  margin-right: 10px;
}

.radio-button-zeile-vgh {
  width: 220px;
}

.vertreter-karte {
  height: 140px;
  width: 360px;
  background-color: #EDF0F3;
  margin-right: 5px;
  margin-bottom: 30px;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-clip: border-box;
}

.kartenlabel {
  height: 140px;
  width: 360px;
  margin-right: 5px;
  margin-bottom: 30px;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-clip: border-box;
}

.vertreter-name {
  text-align: left;
  padding-left: 25px;
  position: absolute;
  top: 0%;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.vertreter-bild {
  border-left: 2px solid #EDF0F3;
  height: 140px;
  width: 102px;
  position: absolute;
  right: 0px;
  object-fit: cover;
}

.vertreter-bild.mandanten-bild {
  width: 118px;
}

.vertreter-bild-vgh {
  width: 120px;
  object-position: top 0 right -35px;
}

.vertreter-bild-oevo {
  width: 120px;
  object-position: top 0 right -88px;
}

.vertreter-bild-container {
  width: 100px;
  height: 140px;
}

.weiss {
  color: #FFFFFF;
}

.cursor-pointer{
  cursor: pointer;
}

@media (max-width: 576px) {
  #titel {
    margin: 20px 40px 20px 0;
  }

  .mobile-container {
    margin: 0 15px;
  }

  .radio-button-zeile {
    font-size: 1vw;
    width: 60vw;
  }

  .radio-button-zeile-vgh {
    width: 53vw;
  }

  #vertreterauswahl {
    margin-left: 15px;
    margin-right: 15px;
  }

  .vertreter-karte {
    flex-basis: auto;
    width: 90vw;
    margin-bottom: 20px;
  }
}

</style>
