/* eslint-disable global-require */
import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';
import createPersistedState from 'vuex-persistedstate';
import VueAxios from 'vue-axios';
import api from '../backend-api';

Vue.use(Vuex);
Vue.use(VueAxios, axios);

const getDefaultState = () => ({
  vertreter: [],
  vertreterAusgewaehlt: false,
  emailAdresse: '',
  fehler: false,
  partner: [],
  vertraege: [],
  vertraegeGruppiert: [],
  vertreterVertraege: [],
  kontoinhaberRadioButton: {},
  mehrAktiv: false,
  vertreterAnzahl: 0,
  zugriffsStatus: '',
  user: {
    isAuthenticated: false,
    name: '',
    idToken: '',
    accessToken: '',
    tokenParsed: '',
    mandantengruppenId: '',
  },
  weiterleitungen: {
    impressum: '',
    nutzungsbedingungen: '',
    datenschutz: '',
    faq: '',
    mandantBasisUrl: '',
    bankdatenAendern: '',
  },
  postfachStatus: {},
});

export default new Vuex.Store({
  plugins: [createPersistedState({
    storage: window.sessionStorage,
    vorherigeURL: '',
    aktualisierteURL: '',
    vsnr: '',
    produktkey: '',
    laufendenr: '',
    konversationsId: '',
  })],
  state: getDefaultState,
  mutations: {
    resetState(state) {
      Object.assign(state, getDefaultState());
    },
    login(state, payload) {
      state.user.isAuthenticated = true;
      state.user.idToken = payload.idToken;
      state.user.accessToken = payload.accessToken;
      state.user.tokenParsed = payload.tokenParsed;
    },
    SET_NAME(state, payload) {
      state.emailAdresse = payload.name;
    },
    SET_MANDANT(state, payload) {
      state.user.mandantengruppenId = parseInt(payload.mandantengruppenId, 10);
    },
    SET_VERTRETER(state, vertreter) {
      state.vertreter = vertreter;
    },
    SET_PARTNER(state, partner) {
      state.partner = partner;
    },
    SET_VERTRAEGE(state, vertraege) {
      state.vertraege = vertraege;
    },
    SET_VERTRAEGE_GRUPPIERT(state, vertraege) {
      state.vertraegeGruppiert = vertraege;
    },
    SET_VERTRETER_VERTRAEGE(state, vertraege) {
      state.vertreterVertraege = vertraege;
    },
    SET_FEHLER(state, fehler) {
      state.fehler = fehler;
    },
    SET_VORHERIGEURL(state, url) {
      state.vorherigeURL = url;
    },
    SET_AKTUALISIERTEURL(state, url) {
      state.aktualisierteURL = url;
    },
    SET_VSNR(state, vsnr) {
      state.vsnr = vsnr;
    },
    SET_LAUFENDENR(state, laufendenr) {
      state.laufendenr = laufendenr;
    },
    SET_PRODUKTKEY(state, produktkey) {
      state.produktkey = produktkey;
    },
    SET_KONVERSATION(state, id) {
      state.konversationsId = id;
    },
    SET_VERTRETERANZAHL(state, anzahl) {
      state.vertreterAnzahl = anzahl;
    },
    SET_ZUGRIFFSSTATUS(state, zugriffsstatus) {
      state.zugriffsStatus = zugriffsstatus;
    },
    SET_WEITERLEITUNGEN(state, weiterleitungen) {
      state.weiterleitungen = weiterleitungen;
    },
    SET_POSTFACHSTATUS(state, postfachstatus) {
      state.postfachStatus = postfachstatus;
    },
  },
  actions: {
    async ladeVertreter({ commit }) {
      await api.getVertreter(Vue.$keycloak.token)
        .then((response) => response.data)
        .then((vertreter) => {
          commit('SET_VERTRETER', vertreter);
        }).catch((error) => {
          console.log(`Vertreter: ${error}`);
        });
    },
    async ladePartner({ commit }) {
      await api.getPartner(Vue.$keycloak.token)
        .then((response) => response.data)
        .then((partner) => {
          commit('SET_PARTNER', partner);
        }).catch((error) => {
          commit('SET_FEHLER', true);
          console.log(`Partner: ${error}`);
        });
    },
    async ladeVertraege({ commit }) {
      await api.getVertraege(Vue.$keycloak.token)
        .then((response) => response.data)
        .then((vertraege) => {
          commit('SET_VERTRAEGE', vertraege);
        })
        .catch((error) => {
          commit('SET_FEHLER', true);
          console.log(`Vertraege: ${error}`);
        });
    },
    async ladeVertraegeGruppiert({ commit }) {
      await api.getVertraegeGruppiert(Vue.$keycloak.token)
        .then((response) => response.data)
        .then((vertraege) => {
          commit('SET_VERTRAEGE_GRUPPIERT', vertraege);
        })
        .catch((error) => {
          commit('SET_FEHLER', true);
          console.log(`Vertraege: ${error}`);
        });
    },
    async ladeVertreterVertraege({ commit }) {
      await api.getVertreterVertraege(Vue.$keycloak.token)
        .then((response) => response.data)
        .then((vertraege) => {
          commit('SET_VERTRETER_VERTRAEGE', vertraege);
        })
        .catch((error) => {
          console.log(`Vertraege: ${error}`);
        });
    },
    async aktualisiereKundendaten(context, payload) {
      if (payload.isSync === true) {
        await api.sendeAktualisiereKundendaten(Vue.$keycloak.token)
          .catch((error) => {
            console.log(`AktualisiereKundendaten: ${error}`);
          });
      } else {
        api.sendeAktualisiereKundendaten(Vue.$keycloak.token)
          .catch((error) => {
            console.log(`AktualisiereKundendatenSync: ${error}`);
          });
      }
    },
    async ladeVertreteranzahl({ commit }) {
      await api.getVertreterAnzahl(Vue.$keycloak.token)
        .then((response) => response.data)
        .then((anzahl) => {
          commit('SET_VERTRETERANZAHL', anzahl);
        })
        .catch((error) => {
          console.log(`GetVertreterAnzahl: ${error}`);
        });
    },
    async ladeZugriffsstatus({ commit }) {
      await api.getZugriffsStatus(Vue.$keycloak.token)
        .then((response) => response.data)
        .then((status) => {
          commit('SET_ZUGRIFFSSTATUS', status);
        })
        .catch((error) => {
          console.log(`GetZugriffsstatus: ${error}`);
          commit('SET_ZUGRIFFSSTATUS', 'KEIN_VERTRAG');
        });
    },
    async ladeWeiterleitungen({ commit }) {
      api.getWeiterleitungen(Vue.$keycloak.token)
        .then((response) => response.data)
        .then((weiterleitungen) => {
          commit('SET_WEITERLEITUNGEN', weiterleitungen);
        })
        .catch((error) => {
          console.log(`GetWeiterleitungen: ${error}`);
        });
    },
    async ladePostfachstatus({ commit }) {
      await api.getPostfachStatus(Vue.$keycloak.token)
        .then((response) => response.data)
        .then((status) => {
          commit('SET_POSTFACHSTATUS', status);
        }).catch((error) => {
          this.$store.commit('SET_FEHLER', true);
          console.log(`PostfachStatus: ${error}`);
        });
    },
  },

  getters: {
    erzeugePartnerName(state) {
      let anrede = '';

      if (state.partner.anrede !== 'Firma' && state.partner.anrede !== 'Frau' && state.partner.anrede !== 'Herr') {
        return anrede;
      }
      if (state.partner.anrede === 'Firma') {
        anrede += `${state.partner.anrede} `;
      }
      if (state.partner.titel) {
        anrede += `${state.partner.titel} `;
      }
      if (state.partner.vorname) {
        anrede += `${state.partner.vorname} `;
      }
      if (state.partner.namensvorsatz) {
        anrede += `${state.partner.namensvorsatz} `;
      }
      if (state.partner.name) {
        anrede += state.partner.name;
      }

      return anrede;
    },
    getVertraege(state) {
      return state.vertraege;
    },
    getVertraegeGruppiert(state) {
      return state.vertraegeGruppiert;
    },
    getVertragBasis: (state) => (versicherungsscheinnummer) => state.vertraege.find(
      (v) => v.versicherungsscheinnummer === versicherungsscheinnummer,
    ),
    getOeffnungszeiten(state) {
      // Gibt zusammengefasste Öffnungszeiten zurück
      const oeZ = state.vertreter.oeffnungszeiten;
      let identisch = false;
      let groesse = 7;
      let combo = 0;

      function pruefeNachIdentischenTagen(i) {
        for (let j = 0; j < oeZ[i].oeffnungsintervallDTO.length; j += 1) {
          // Falls alle Öffnungszeiten der beiden Tage gleich sind, sind sie identisch
          // eslint-disable-next-line max-len
          identisch = oeZ[i].oeffnungsintervallDTO[j].start === oeZ[i - 1].oeffnungsintervallDTO[j].start
            // eslint-disable-next-line max-len
            && oeZ[i].oeffnungsintervallDTO[j].ende === oeZ[i - 1].oeffnungsintervallDTO[j].ende;
        }
      }

      function combineDays(i) {
        // Falls mindestens 3 aufeinander folgende Tage identische Öffnungszeiten haben,
        // werden sie zusammengefasst.
        // Dabei wird der erste Tag beibehalten und die restlichen Zeichen gestutzt.
        // Anschließend wird der aktuelle Tag mit einem "-" am ersten Tag verbunden.
        // Beispiel: Di, Mi, Do -> Di - Do
        if (combo >= 2) {
          oeZ[i - 1].wochentag = oeZ[i - 1].wochentag.slice(0, 2);
          oeZ[i - 1].wochentag += `-${oeZ[i].wochentag}`;
        } else {
          // Bei zwei aufeinander folgenden Tagen, werden die Tage mit einem Komma getrennt.
          oeZ[i - 1].wochentag += `, ${oeZ[i].wochentag}`;
        }
        // Nachdem die Wochentage zusammengefasst wurden, wird die identische Spalte gelöscht.
        // Die Größe und der Iterator werden um 1 verringert.
        // Beispiel:
        // 1. Mo: 08:00-12:00
        // 2. Di: 08:00-12:00
        // 3. Mi: 09:00-13:00
        // -> 1. Mo, Di: 08:00-12:00
        //    2. Mi    : 09:00-13:00
        oeZ.splice(i, 1);
      }

      function formatOez() {
        function handleIdentityOfDays(index) {
          let i = index;
          if (oeZ[i].oeffnungsintervallDTO.length === 0) {
            identisch = true;
          } else {
            pruefeNachIdentischenTagen(i);
          }
          // combo wird erhöht, falls mehrere aufeinander folgende Tage identisch sind
          if (identisch) {
            combo += 1;
            combineDays(i);
            i -= 1;
            groesse -= 1;
          } else {
            combo = 0;
          }
          return i;
        }

        if (oeZ.length > 1) {
          for (let i = 1; i < groesse; i += 1) {
            // Vergleicht zunächst ob zwei aufeinander liegende Tage,
            // gleich viele Öffnungszeiten haben
            if (oeZ[i].oeffnungsintervallDTO.length === oeZ[i - 1].oeffnungsintervallDTO.length) {
              i = handleIdentityOfDays(i);
            }
          }
        }
      }

      formatOez();
      return oeZ;
    },
  },
});
