<template>
  <div>
    <div class="copy_bold_16 unter-ueberschrift"><slot name="unter-ueberschrift"></slot></div>
    <div class="copy_16 text"><slot name="inhalt"></slot></div>
  </div>
</template>

<script>
export default {
  name: 'Vertragsdetail',
};
</script>

<style scoped>
.unter-ueberschrift {
  margin-bottom: 5px;
}

.text {
  margin-bottom: 15px;
}
</style>
