<template>
  <div>
    <div class="header-nect">
      <h2 class="header-text">Nect Authentifizierung</h2>
    </div>
    <div class="body-nect row">
      <h3 :class="{
        'vgh-blau-color': mandant === 1,
        'oesa-gruen-color': mandant === 3,
        'oevo-blau-color': mandant === 5
      }">Wichtiger Hinweis!</h3>
      <p class="mb20">Aufgrund von Wartungsarbeiten steht die Online-Identifikation Ihrer
        Daten momentan leider nicht zur Verfügung.</p>
      <p class="mb20">Bitte loggen Sie sich zu einem späteren Zeitpunkt nochmal ein,
        um Ihre Identifikation abzuschließen. Danke für Ihr Verständnis.</p>
      <span>Viele Grüße</span>
      <span class="font-weight-bold">Ihr Kundenportal-Team</span>
    </div>
  </div>
</template>

<script>
import mandant from '@/mandant';

export default {
  name: 'NectWartungsfenster',
  mixins: [mandant],
  components: {
  },
  beforeMount() {
    this.$root.$refs.app.setLoading(false);
    this.$root.$refs.app.onViewGeladen();
  },
};

</script>

<style scoped>

body h3 {
  width: 100%;
  font-size: 23px;
  font-weight: bold;
  margin: 20px 0 30px 0;
}

body p{
  width: 100%;
  font-size: medium;
  padding: 0 20%;
  margin: 0;
  text-align: justify;
}

p.mb20 {
  margin-bottom: 20px;
}

body span{
  font-size: small;
  width: 100%;
}

.header-nect {
  height: 75px;
  border-bottom-color: #EDF0F3;
  border-bottom-style: groove;
  border-bottom-width: thin;
}

.header-text{
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.body-nect {
  height: 275px;
  padding: 20px 30px;
  width: 75%;
  margin: auto;
}

/* Tablet View */
@media (max-width: 768px) {
  .body-nect {
    width: 80%;
  }
}

/* Mobile View */
@media (max-width: 576px) {
  .body-nect {
    width: 100%;
  }
}

/* Tablet View */
@media (max-width: 768px) {
  body h3 {
    font-size: 20px;
    line-height: 30px;
  }
}

/* Mobile View */
@media (max-width: 576px) {
  body h3 {
    font-size: 15px;
    line-height: 25px;
  }
}
</style>
