<template>
  <div>
    <div class="header-nect">
      <h2 class="header-text">Nect Authentifizierung</h2>
    </div>
    <div class="body-nect">
      <form :action="$store.state.weiterleitungen.nectapp" method="get" target="_self">
        <p class="copy_16 left">
          Jetzt nur noch online mit unserem Partner Nect authentifizieren.
          Halten Sie Ihren Personalausweis bereit.
          Um mit der Nect Authentifizierung fortzufahren, nehmen Sie bitte Ihr Smartphone zur Hand.
        </p>
        <p class="copy_16 left">
          Die Online-Authentifizierung erfolgt ganz unkompliziert über die Nect App.
          Sie haben Nect noch nicht installiert?
          Um die App downzuloaden, scannen Sie den QR Code oder fordern Sie eine SMS an.
        </p>
        <p class="copy_16 left">
          Über den folgenden Button können Sie die Nect-Authentifizierung starten:
        </p>
        <input type="hidden" name="case_uuid" :value="cUUID"/>
        <input
          id="nect-btn"
          type="image"
          src="https://cms.nect.com/assets/nect_button_alt_1.png"
          name="submit"
          alt="Einfach und schnell identifiziert mit Nect."
        />
      </form>
    </div>
  </div>
</template>

<script>

export default {
  name: 'NectStart',
  props: {
    cUUID: {
      type: String,
      required: true,
    },
  },
  mounted() {
    this.$emit('view-geladen');
  },
};
</script>

<style scoped>
#nect-btn {
  height: 90px;
  margin-bottom: 30px;
}

.left {
  text-align: left;
}

.header-nect {
  height: 75px;
  border-bottom-color: #EDF0F3;
  border-bottom-style: groove;
  border-bottom-width: thin;
}

.header-text{
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.body-nect {
  height: 275px;
  padding: 20px 30px;
  width: 75%;
  margin: auto;
}

/* Tablet View */
@media (max-width: 768px) {
  #nect-btn {
    height: 60px;
  }

  .body-nect {
    width: 80%;
  }
}

/* Mobile View */
@media (max-width: 576px) {
  .body-nect {
    width: 100%;
  }
}
</style>
