<template>
  <div>
    <div class="container main-content">
      <div class="row g-3">
        <div class="col-xl-8 mb-5">
          <h1 class="abstand-unten-30">Handynummer ändern </h1>
            <div class="row">
              <div class="col-md-9 col-12">
                <label class="copy_18 abstand-unten-30">
                  Klicken Sie auf weiter, um Ihre Handynummer zu ändern.
                </label>
              </div>
            </div>
                  <button class="button-ivv white"
                          :class="{'button-vgh': mandant === 1,
                            'button-oesa': mandant === 3,
                            'button-oevo': mandant === 5}"
                          @click="absenden">
                    Weiter
                  </button>
        </div>
        <div v-if="windowWidth>767" class="col-xl-4">
          <vertreter-info
            ueberschrift="Ich helfe Ihnen gerne bei der Änderung Ihrer Daten weiter."
            :social-media="true"
            :nachricht="true"
            :vertrLink="true"
            :termin="true"
            :vertreter="vertreter">
          </vertreter-info>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VertreterInfo from '@/components/VertreterInfo.vue';
import Vue from 'vue';
import { mapState } from 'vuex';
import mandant from '@/mandant';

export default {
  name: 'NummerAendern',
  mixins: [mandant],
  beforeMount() {
    this.$root.$refs.app.setZurueckFeld(true);
  },
  mounted() {
    this.$emit('view-geladen');
  },
  components: {
    VertreterInfo,
  },
  computed: {
    ...mapState(['partner', 'vertreter']),
  },
  methods: {
    async absenden() {
      window.location.href = `${Vue.$keycloak.authServerUrl}/realms/${Vue.$keycloak.realm}/2fa-forms/change-number?client_id=${Vue.$keycloak.clientId}`;
    },
  },
};
</script>
